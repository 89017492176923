import React from "react";
// Customizable Area Start
import {
  Row,
  Col,
  Image,
  Typography,
  Button,
  Spin
} from "antd";
import "antd/dist/antd.css";
import {
  style,
  AppImage,
  AndroidImage,
  iosImage,
} from "./assets";
import ForgotController from "./forgotPasswordController.web";
import { CheckCircleFilled } from "@ant-design/icons";
const { Title, Text, Link } = Typography;
// Customizable Area End

export default class ResetPasswordSuccess extends ForgotController {
  // Customizable Area Start
  renderPasswordResetSuccessBannerImage = () => (
    !this.state.forgotPasswordBannerImage ? <Spin /> :
      <Image
        style={{ height: "100%", width: "100%" }}
        preview={false}
        wrapperStyle={{ height: "100%", width: "100%" }}
        src={this.state.forgotPasswordBannerImage}
      />
  )
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        <Row>
          <Col span={14} xs={0} sm={12} md={12} lg={14} xl={14} xxl={14}>
            {this.renderPasswordResetSuccessBannerImage()};
          </Col>
          <Col
            span={3}
            xs={24}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            xxl={0}
            className={style.top_padding}
          />
          <Col
            span={10}
            xs={24}
            sm={12}
            md={12}
            lg={10}
            xl={10}
            xxl={10}
            // className={style.top_padding}
            style={{ alignSelf: "center" }}
          >
            <Row style={{ alignSelf: "center" }}>
              <Col span={4} />
              <Col span={16}>
                <Row className={style.loginText}>
                  <br />
                  <br />
                  <CheckCircleFilled translate={{}}
                    style={{
                      fontSize: "50px",
                      paddingBottom: "16px",
                      color: "#52c41a",
                    }}
                    rev={{}}
                  />
                  <br />
                  <br />
                  <Title className={style.w_100_center} level={3}>
                    Password Reset Successfully
                  </Title>
                  <br />
                </Row>
                <Row className={style.loginText}>
                  <Text type="secondary">
                    Your password has been reset successfully
                  </Text>
                  <br />
                  <Text type="secondary">
                    Please login with new password to continue
                  </Text>
                  <br />
                  <br />
                  <Button
                    className={style.btn_colors}
                    type="primary"
                    shape="round"
                    size={"large"}
                    onClick={() =>
                      this.props.history.push("/")
                    }
                    style={{ width: "100%" }}
                  >
                    Log In
                  </Button>
                </Row>
              </Col>
              <Col span={4} />
            </Row>
          </Col>
        </Row>

        <Row style={{ backgroundColor: "#f5f5f5" }}>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={14} xxl={14}>
            <Row
              className={style.app_alignCenter}
              style={{ justifyContent: "flex-end", width: "100%" }}
            >
              <Image
                preview={false}
                src={AppImage}
                style={{ objectFit: "fill" }}
              // wrapperStyle={{ width: "100%" }}
              />
            </Row>
          </Col>
          <Col
            span={12}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={10}
            xxl={10}
          // className={style.app_store}
          >
            <Row
              // className={style.app_alignCenter}
              style={{
                height: "100%",
                alignItems: "center",
                paddingRight: "40px",
                paddingLeft: "40px",
              }}
            >
              <Col span={6} xs={0} sm={6} md={3} lg={3} xl={3} xxl={6} />
              <Col span={10} xs={24} sm={24} md={18} lg={18} xl={18} xxl={10}>
                <Row>
                  <Title level={3}>Available for Android and IOS</Title>
                  <Text
                    type="secondary"
                    className={style.w_100}
                    style={{
                      fontSize: "1.1rem",
                      lineHeight: "1.3rem",
                      paddingBottom: "20px",
                    }}
                  >
                    Download applied app from your Play Store or App Store
                  </Text>
                  <Col span={11}>
                    <Image preview={false} src={AndroidImage} />
                  </Col>
                  <Col span={2} />
                  <Col span={11}>
                    <Image preview={false} src={iosImage} />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={8} sm={8} md={3} lg={3} xl={3} xxl={8} />
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            justifyContent: "center",
            paddingTop: "10px",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          <p>©2021 applied. All rights reserved.</p>
          <a href="/privacy-policy" target="_blank">&nbsp;&nbsp;Privacy Policy&nbsp;&nbsp;</a>
          <a href="/terms-and-conditions" target="_blank">&nbsp;&nbsp;Terms of use</a>
        </Row>
      </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End