import React from "react";
import PostRatingController, { Props } from "./PostRatingController.web";
import { uparrow, free_ac, bluepoint, bgblue, orengepoint, pre_ac } from "./../assets";
import CommonListItem from "./../commonListItem.web";
import "./../userFeed.css";
import moment from "moment";
import {
  Card,
  Row,
  Col,
  List,
  Input,
  Typography,
  Avatar,
  Button,
  Upload,
  Image,
  Badge,
  Radio,
  Tabs,
  Space,
  Modal,
} from "antd";
// import CustomHeader from "../CustomHeader.web";
const { Title, Text } = Typography;

export default class PremiunAccount extends PostRatingController {
  render() {
    const { navigation } = this.props;
    return (
      <div className="backgroun" style={{ backgroundColor: "#f6f6f6" }}>
        {this.redirectUser()}
        {/* <CustomHeader {...this.props} dotVisible={false} /> */}
        <div>
          <Row style={{ paddingTop: "15px", marginBottom: 7, marginLeft: 20 }}>
            <Text
              className="textlabel_subheadingText"
            >
              My Profile /
            </Text>
            <Text
              className="textlabel_subheadingText2"
            >
              {" "}
              Premium Account
            </Text>
          </Row>
          <Col style={{ marginLeft: "20px", marginTop: "10px" }}>
            <Text
              className="textlabel_headingText"
            >
              Premium Account
            </Text>
            <br />
            <Text
              className="pre_cap"
            >
              Select a plan that works for you
            </Text>
          </Col>
          <div
            className="pre_main"
          >
            <div
              className="pre_consept"
            >
              <Col
                className="Pre_top"
              >
                <Avatar
                  size={60}
                  src={free_ac}
                />
                <Text className="textlabel_UpgradetoPremiumText3">Basic Personal Plan</Text>
                <Text
                  className="textlabel_UpgradetoPremiumText4"
                >
                  Free
                </Text>
              </Col>
              <Col style={{ padding: 18 }}>
                <div className="pre_row">
                  <Avatar
                    size={14}
                    src={bluepoint}
                    style={{ marginTop: 2 }}
                  />
                  <Text
                    className="pre_point"
                  >
                    Posted picture and videos will appear on the <br /> user page for a period of 30 days.
                  </Text>
                </div>
                <div className="pre_row">
                  <Avatar
                    size={14}
                    src={bluepoint}
                    style={{ marginTop: 2 }}
                  />
                  <Text
                    className="pre_point"
                  >
                    Basic analytics on the ratings
                  </Text>
                </div>
                <div className="pre_row">
                  <Avatar
                    size={14}
                    src={bluepoint}
                    style={{ marginTop: 2 }}
                  />
                  <Text
                    className="pre_point"
                  >
                    Share your posts and other users post on <br /> external social media
                  </Text>
                </div>
                <div className="pre_row">
                  <Avatar
                    size={14}
                    src={bluepoint}
                    style={{ marginTop: 2 }}
                  />
                  <Text
                    className="pre_point"
                  >
                    Connect with another user and rate them
                  </Text>
                </div>
                <div className="pre_row">
                  <Avatar
                    size={14}
                    src={bluepoint}
                    style={{ marginTop: 2 }}
                  />
                  <Text
                    className="pre_point"
                  >
                    Connect with business professional and <br /> manage them privately
                  </Text>
                </div>
                <div className="pre_row">
                  <Avatar
                    size={14}
                    src={bluepoint}
                    style={{ marginTop: 2 }}
                  />
                  <Text
                    className="pre_point"
                  >
                    Create communities and manage them privately
                  </Text>
                </div>
              </Col>
              {/* <Col style={{ marginTop: 'auto', marginBottom: 15 }}>
                <Button
                  className="pre_plan_btn login_btn_colors__kpBfq"
                  onClick={() =>
                    this.props.history.push({
                      //@ts-ignore
                      pathname: '/checkout'
                    })
                  }
                >
                  Select Plan
                </Button>
              </Col> */}
            </div>
            {/*  */}
            <div
              className="pre_consept"
              style={{
                marginLeft: "20px",
                backgroundColor: "#fff",
              }}
            >
              <Col
                className="Pre_top"
              >
                <Avatar
                  size={60}
                  src={pre_ac}
                />
                <Text className="textlabel_UpgradetoPremiumText3">Premium Personal Plan</Text>
                <Text
                  className="attrbuted_textlabel_UpgradetoPremiumText3"
                >
                  ${this.state.premiumPlan?.attributes?.yearly_plan} <span className="attrbuted_textlabel_UpgradetoPremiumText4">/ Year</span>
                </Text>
                {/* <Text
                  className="attrbuted_textlabel_UpgradetoPremiumText4"
                >
                  /Year
                </Text> */}
              </Col>
              <Col style={{ padding: 18 }}>
                <div className="pre_row">
                  <Avatar
                    size={14}
                    src={orengepoint}
                    style={{ marginTop: 2 }}
                  />
                  <Text
                    className="pre_point"
                  >
                    Includes all features of basic plan
                  </Text>
                </div>
                <div className="pre_row">
                  <Avatar
                    size={14}
                    src={orengepoint}
                    style={{ marginTop: 2 }}
                  />
                  <Text
                    className="pre_point"
                  >
                    Posted picture and videos will appear on the <br /> user page for a period of 365 days.
                  </Text>
                </div>
                <div className="pre_row">
                  <Avatar
                    size={14}
                    src={orengepoint}
                    style={{ marginTop: 2 }}
                  />
                  <Text
                    className="pre_point"
                  >
                    Create personalized questionnaire for each <br /> category
                  </Text>
                </div>
                <div className="pre_row">
                  <Avatar
                    size={14}
                    src={orengepoint}
                    style={{ marginTop: 2 }}
                  />
                  <Text
                    className="pre_point"
                  >
                    Advanced analytics on the ratings
                  </Text>
                </div>
              </Col>
              <Col style={{ marginTop: 'auto', marginBottom: 15 }}>
                <Button
                  data-testid="select-plan-btn"
                  className="pre_plan_btn login_btn_colors__kpBfq"
                  onClick={() =>
                    this.props.history.push({
                      //@ts-ignore
                      pathname: '/checkout'
                    })
                  }
                >
                  Select Plan
                </Button>
              </Col>
            </div>
          </div>
        </div>
      </div>

    );
  }
}