import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  Typography,
  Image,
  Avatar,
  Button,
  Input,
  Card,
  Dropdown,
  Menu,
  List,
  Row,
  Col,
  Modal,
  Spin,
  Space,
  Radio,
  message
} from "antd";
const { Text } = Typography;
import { comment, share, MenuImage, sendIcon, faceIcon } from "./assets";
import { getStorageData, isEmpty } from "../../../framework/src/Utilities";
import "./userFeed.css";
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, EmailShareButton } from 'react-share'
import { EmailIcon, FacebookIcon, WhatsappIcon, TwitterIcon } from 'react-share'
// @ts-ignore
import { baseURL } from '../../../framework/src/config.js';
import {
  HeartTwoTone,
  HeartFilled,
} from "@ant-design/icons";
import ReactPlayer from 'react-player'
import Picker from 'emoji-picker-react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import RenderComment from "../../ConversationThreading/src/RenderComment.web";
import { ProfileAttributesDataType } from "../../ConversationThreading/src/Interface";

interface Props {
  data?: any;
  loginUserId: string;
  menu?: any;
  handleMenuClick: Function;
  isFollowed?: boolean;
  toggleFollow: Function;
  handlePostList: any;
  renderCreatePost?: Function
  AllMedia?: any | []
  isCreatePostVisible?: boolean;
  handleEditPostOpen: Function;
}

interface S {
  data: any;
  keyExtractor: any;
  render: any;
  itemSeparatorComponent: any;
  listHeaderComponent: any;
  stickyHeaderIndices: any;

}

export const CustomPost: FunctionComponent<Props> = (props) => {

  let history = useHistory();
  const [loginId, setLoginId] = useState('0');
  const [userComments, setUserComments] = useState<Array<any>>([]);
  const [isShowReportModal, setIsShowReportModal] = useState(false);
  const [reportValue, setReportValue] = useState<number | null>(null);
  const [isSavePost, setIsSavePost] = useState(props?.data?.saved);
  const [onSendComment, setOnSendComment] = useState(false);
  // const [isDropdownVisible, setDropDownVisible] = useState(false);
  const [isEmojiPickerEnable, setIsEmojiPickerEnable] = useState(false);
  const [postComment, setPostComment] = useState('')
  const [profile, setProfile] = useState<any>({})
  const [mediaVisible, setMediaVisible] = useState(false)
  const [postMedia, setPostMedia] = useState([]);
  const [pageCount, setPageCount] = useState(1)
  // const [isFollow, setFollow] = useState(props.isFollowed)
  const [commentCount, setcommentCount] = useState(props?.data?.comment_count);
  const [likedCount, setLikedCount] = useState(props?.data?.like_count)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userData, setUserData] = useState('');
  // const [tagbody, setTagBody] = useState('');
  const [showTag, setShowTag] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const customClose = useRef<any>();
  getStorageData("loginUser")
    .then((res) => { setLoginId(res.id), setUserData(res.first_name) })
    .catch((err) => console.log(err));

  const url = window.location.origin

  const handleSubmitComment = async (data: string, id: number) => {
    setOnSendComment(true);
    await addComment(id, postComment);
    setPostComment("");
  }

  const handleUserComments = () => {
    getCommentsOfPost(props?.data?.id)
  }

  const handleMenuClick = (status: string) => {
    if (status === "save") {
      savePost(props?.data?.id, "save");
    }
    if (status === "hide") {
      savePost(props?.data?.id, "hide");
    }
    if (status === "report") {
      setIsShowReportModal(true);
      console.log(props?.data);
    }
  }

  const deletePost = async (id: number) => {
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_posts/posts/${id}`,
        method: "DELETE",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      props.handlePostList(props.data, "delete")
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  }

  const savePost = async (id: any, action: string) => {
    console.log(id, action);
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_posts/posts/${id}/${action}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (action === "save") {
        if (response?.data?.data) {
          props.handlePostList(response?.data?.data, "update");
          setIsSavePost(true)
        } else {
          setIsSavePost(false)
        }
      } else {
        props.handlePostList(response?.data?.data, "delete");
      }
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  }

  const handleReportOk = async () => {
    let httpBody = {
      data: {
        attributes: {
          context: reportValue
        }
      }
    }
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_posts/posts/${props?.data?.id}/report`,
        method: 'post',
        headers: {
          'token': await localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(httpBody)
      })
      console.log(response)
      message.success("Post has been reported successfully")
      setIsShowReportModal(false);
    } catch (error) {
      console.error(error);
    }
  }


  const updatelikePost = async (id: number, updateLikes: any) => {
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_posts/posts/${id}/${updateLikes}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        }
      });
      if (updateLikes == 'like') {
        setLikedCount(likedCount + 1);
      } else {
        setLikedCount(likedCount - 1);
      }
      props.handlePostList(response?.data?.data, "update")
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };

  const toggleLike = (id: number, liked: boolean) => {
    if (liked) {
      updatelikePost(id, "dislike");
    } else {
      updatelikePost(id, "like");
    }
  };

  const addComment = async (id: number, comment: any) => {
    let httpBody = {
      data: {
        attributes: {
          post_id: id,
          comment: comment,
        },
      },
    };
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_conversationthreading/comments`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        },
        data: JSON.stringify(httpBody)
      });
      setUserComments([]);
      setOnSendComment(false);
      setcommentCount(commentCount + 1);
      setPageCount(1);
    } catch (error) {
      console.error(error, 'error while calling api follow REQUEST');
    }
  };

  const toggleCommentLike = async (id: number | string, liked: boolean) => {
    if (liked) {
      commentLikeStatus(id, 'dislike')
    } else {
      commentLikeStatus(id, 'like')
    }
  };

  const commentLikeStatus = async (id: number | string, status: string) => {
    let httpBody = {
      data: {
        attributes: {
          post_id: id,
        },
      },
    };
    try {
      const response = await axios({
        url:
          `${baseURL}/bx_block_conversationthreading/comments/${id}/${status}`,
        method: "POST",
        headers: {
          token: await localStorage.getItem('token'),
          "Content-Type": "application/json"
        },
        data: JSON.stringify(httpBody)
      });
      const commentData1 = userComments;
      const updateCommentData1 = commentData1?.map((item: any) => {
        if (item.id === response?.data?.data?.id) {
          return response?.data?.data
        } else {
          return item
        }
      })
      setUserComments(updateCommentData1);
    } catch (error) {
      console.error(error, 'error while calling', status, 'REQUEST');
    }
  }

  const getCommentsOfPost = async (id: number) => {
    setCommentLoading(true)
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_conversationthreading/comments/?post_id=${id}&page=${pageCount}&per_page=4`,
        method: 'get',
        headers: {
          'token': localStorage.getItem("token"),
          'Content-Type': 'application/json'
        }
      })
      if (response.data?.data) {
        setUserComments([...userComments, ...response?.data?.data]);
        setCommentLoading(false);
        setPageCount((prev) => prev + 1)
        if (response.data?.data?.length < 4) {
          setShowButton(false)
        } else {
          setShowButton(true)
          setCommentLoading(false)
        }
      } else {
        setShowButton(false)
        setCommentLoading(false)
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleUserRequest = (value: ProfileAttributesDataType) => {
    if (value?.account_id === loginId) {
      return null
    } else {
      if (value?.role === "personal") {
        return history.push({ pathname: "/other/personal/profile", state: value?.account_id });
      } else if (value?.role === "business") {
        return history.push({ pathname: "/other/business/profile", state: value?.account_id });
      }
    }
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleReportCancel = () => {
    setIsShowReportModal(false)
  }

  const onChangeValue = (e: any) => {
    setReportValue(e.target.value);
  }

  useEffect(() => {
    setcommentCount(props?.data?.comment_count)
    setLikedCount(props?.data?.like_count)
    setProfile(props.data?.account?.data?.attributes?.profile?.data?.attributes)
    return () => {

    }
  }, [props])

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (isEmojiPickerEnable && customClose.current && !customClose.current.contains(e.target)) {
        setIsEmojiPickerEnable(false);
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isEmojiPickerEnable])

  const menu = () => {
    return (
      <Menu
        style={{ marginRight: "16px", marginTop: "-12px", paddingRight: "16px" }}
      >
        {+props?.loginUserId === +props?.data?.account?.data?.id ? (
          <>
            <Menu.Item onClick={() => props.handleEditPostOpen(props?.data?.id)} key={1}>
              <Text>Edit Post</Text>
            </Menu.Item>
            <Menu.Item onClick={() => deletePost(props?.data?.id)} key={2}>
              <Text>Delete Post</Text>
            </Menu.Item>
          </>
        ) :
          props?.menu?.isCommunity ? (
            <>
              <Menu.Item onClick={() => handleMenuClick('hide')} key={3}>
                <Text>Hide Post</Text>
              </Menu.Item>
              <Menu.Item onClick={() => handleMenuClick('report')} key={4}>
                <Text>Report this Post</Text>
              </Menu.Item>
              <Menu.Item onClick={() => props.handleMenuClick('block')} key={5}>
                <Text>Block Account</Text>
              </Menu.Item>
            </>
          ) : (
            <>
              <Menu.Item onClick={async () => handleMenuClick('save')} key={6}>
                <Text>{isSavePost ? "Unsaved Post" : "Save Post"}</Text>
              </Menu.Item>
              <Menu.Item onClick={async () => handleMenuClick('hide')} key={7}>
                <Text>{props?.data?.hidden ? "Unhide Post" : "Hide Post"}</Text>
              </Menu.Item>
              {props?.isFollowed && <Menu.Item onClick={() => props.toggleFollow()} key={8}>
                <Text>Unfollow</Text>
              </Menu.Item>}
              <Menu.Item onClick={async () => handleMenuClick('report')} key={9}>
                <Text>Report this Post</Text>
              </Menu.Item>
            </>
          )
        }
      </Menu>
    )
  };

  const dropDownUI = () => (
    <Dropdown
      overlay={menu()}
    >
      <Button
        type="default"
        shape="circle"
        icon={<img src={MenuImage} style={{ width: "4px", height: "15px" }} />}
        size={"small"}
        style={{ border: "none", outline: "none" }}
      />
    </Dropdown>
  )

  const checkDropDown = () => {
    if (+props?.loginUserId === +props?.data?.account?.data?.id) {
      return dropDownUI();
    } else if (props?.menu?.isCommunity === false) {
      return dropDownUI();
    } else if (props?.menu?.isCommunityAdmin) {
      return dropDownUI();
    } else {
      return;
    }
  }

  const renderImage = (id: any, isFullHeight: boolean) => {
    return (
      <Image
        src={props.data?.media[id]?.url}
        alt="post_img"
        width={"100%"}
        height={"100%"}
        style={{
          objectFit: "fill",
          borderRadius: "3px"
        }}
      />
    )
  }
  const renderVideo = (id: any, isFullHeight: boolean) => {
    return (
      <ReactPlayer
        url={props.data?.media[id]?.url}
        controls
        config={{ file: { attributes: { controlsList: 'nodownload', disablePictureInPicture: true, playbackRate: false } } }}
        onContextMenu={(e: any) => e.preventDefault()}
        width={"100%"}
        height={"100%"}
        style={{
          objectFit: "fill",
          borderRadius: "3px"
        }}
      />)
  }
  const checkMedia = (id: any, isFullHeight: boolean) => {
    return props.data?.media[id]?.content_type?.slice(0, 5) == "video" ?
      renderVideo(id, isFullHeight) :
      renderImage(id, isFullHeight)
  }

  const renderMedia = () => {

    switch (props.data?.media?.length) {
      case 0:
        return null;
      case 1:
        return (
          <Row gutter={[2, 2]}>
            <Col span={24}>
              {checkMedia(0, true)}
            </Col>
          </Row>
        )
      case 2:
        return (
          <Row gutter={[2, 2]}>
            <Col span={12} >{checkMedia(0, true)}</Col>
            <Col span={12} >{checkMedia(1, true)}</Col>
          </Row>
        )
      case 3:
        return (
          <Row gutter={[2, 2]}>
            <Col span={12}>
              {checkMedia(0, true)}
            </Col>
            <Col span={12}>
              <Row gutter={[2, 2]}>
                <Col span={24}>
                  {checkMedia(1, false)}
                </Col>
                <Col span={24}>
                  {checkMedia(2, false)}
                </Col>
              </Row>
            </Col>
          </Row>
        )
      case 4:
        return (
          <Row gutter={[2, 2]}>
            <Col span={12} >
              {checkMedia(0, false)}
            </Col>
            <Col span={12} >
              {checkMedia(1, false)}
            </Col>
            <Col span={12} >
              {checkMedia(2, false)}
            </Col>
            <Col span={12} >
              {checkMedia(3, false)}
            </Col>
          </Row>
        )
      default:
        return (
          <Row gutter={[2, 2]}>
            <Col span={12} >
              {checkMedia(0, false)}
            </Col>
            <Col span={12} >
              {checkMedia(1, false)}
            </Col>
            <Col span={12} >
              {checkMedia(2, false)}
            </Col>
            <Col span={12}  >
              <Button
                size='large'
                type='text'
                onClick={() => {
                  setPostMedia(props.data.media)
                  setMediaVisible(true)
                }}
                style={{
                  backgroundImage: "linear-gradient(#005574, #7aacd6)",
                  height: 200,
                  width: '100%',
                  fontWeight: 'bold',
                  fontSize: '24px'
                }} >
                {`+${props.data?.media?.length - 3} More`} </Button>
            </Col>
          </Row>
        )
    }
  };
  const renderMediaModal = () => {
    return (
      <Modal
        width={550}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
              fontWeight: "bold",
              borderWidth: "0px",
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          >
            All Post Media
          </div>
        }
        visible={mediaVisible}
        onCancel={() => setMediaVisible(false)}
        footer={null}
      >
        {postMedia.map((item: any) => (
          item.content_type.slice(0, 5) == "video" ?
            <ReactPlayer url={item?.url} controls height={234} width={400} style={{ borderRadius: '4px' }} /> : <Image src={item?.url} />
        ))}
      </Modal>
    )
  }

  const showReportModal = () => {
    return (
      <Modal
        width={350}
        style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
        centered
        className="Logout_Popup"
        title={
          <div style={{ width: "100%", cursor: "move", fontWeight: "bold", borderWidth: "0px" }}
            onFocus={() => { }}
            onBlur={() => { }}
          >
            Report Post
          </div>
        }
        visible={isShowReportModal}
        onCancel={handleReportCancel}
        footer={[
          <Button size="large" className="report-button" style={{ width: "100%" }} key="report"
            onClick={() => handleReportOk()}
          >
            Report
          </Button>,
        ]}
      >
        <Radio.Group onChange={onChangeValue} value={reportValue} buttonStyle="outline">
          <Space direction="vertical">
            <Radio style={{ color: "grey" }} value='Fraude or pyramid Marketing'>
              Fraud or Pyramid Marketing
            </Radio>
            <Radio style={{ color: "grey" }} value='Promoting/Selling illegal or regulated goods'>
              Promoting/Selling illegal or regulated goods
            </Radio>
            <Radio style={{ color: "grey" }} value='Promotes Terrorism'>
              Promotes Terrorism
            </Radio>
            <Radio style={{ color: "grey" }} value='Discrimination, Harrassment or Bullying'>
              Discrimination, Harrassment or Bullying
            </Radio>
            <Radio style={{ color: "grey" }} value='Child Abuse'>
              Child Abuse
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    )
  };

  const onChange = (value: any) => {
    setPostComment(value)
  };

  //@ts-ignore
  const onEmojiClick = (event: any, emojiObject: { emoji: string; }) => {
    setPostComment(postComment.concat(emojiObject.emoji))
  };

  const renderComments = (item?: any) => {

    return (
      <RenderComment
        item={item}
        handleUserRequest={handleUserRequest}
        onCommentLike={toggleCommentLike}
      />
    );
  }

  const getDomainSkills = (domain_skills: any) => {
    if (
      isEmpty(domain_skills)
    )
      return "";
    const domainSkillsPresent = domain_skills;
    const domainSkillsStr = domainSkillsPresent.join(", ");
    return domainSkillsStr;
  }

  const suffix = (
    [
      <Button
        key="emoji"
        style={{ border: "none", outline: "none" }}
        type="ghost"
        shape="circle"
        icon={<img src={faceIcon} alt="" width={20} />}
        onClick={() => {
          setIsEmojiPickerEnable(!isEmojiPickerEnable)
        }}
      />,
      postComment?.length > 0 &&
      <Button
        shape="circle"
        type="ghost"
        style={{ border: "none", outline: "none" }}
        icon={
          <img
            src={sendIcon}
            alt=""
            width={30}
          />}
        disabled={postComment?.length > 0 ? false : true}
        onClick={async () => {
          handleSubmitComment(postComment, props?.data?.id);
        }}
      />
    ]
  )


  const renderCommentUI = () => {
    return (
      <Col span={24} style={{ borderTop: userComments.length !== 0 || commentLoading ? '1px solid #f0f0f0' : "none" }}>
        {
          commentLoading && userComments.length === 0 ? <Spin /> :
            userComments.length > 0 ?
              <Card style={{ borderWidth: "0px" }}>
                <List
                  dataSource={userComments}
                  renderItem={renderComments}
                />
                {
                  commentLoading ? <Spin /> :
                    showButton && <Button
                      type="text"
                      style={{
                        cursor: "pointer",
                        marginTop: "20px",
                        fontWeight: 500
                      }}
                      onClick={() => handleUserComments()}
                    >
                      View more comments...
                    </Button>}
              </Card> : null}
      </Col>
    )
  }

  const renderUserProfileUI = () => {
    return (
      <Col span={24}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "20px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "5px"
            }}
          >
            <div
              onClick={() => handleUserRequest(props?.data?.account?.data?.attributes)}
              style={{
                cursor: "pointer"
              }}
            >
              <Avatar
                size={40}
                icon={<img src={props?.data?.account?.data?.attributes?.photo} width="100%" />}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "2px"
              }}
            >
              <div
                onClick={() => handleUserRequest(props?.data?.account?.data?.attributes)}
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: 500
                }}
              >
                {props?.menu?.isCommunity && props?.data?.community_forum?.data?.attributes?.admin ?
                  props?.data?.account?.data?.attributes?.full_name : props.data?.account?.data?.attributes?.role === 'business' ?
                    props.data?.account?.data?.attributes?.profile?.data?.attributes?.company_name
                    : props.data?.account?.data?.attributes?.full_name}
              </div>
              <div>
                <p>
                  {props.data?.account?.data?.attributes?.role === "personal" ?
                    <Text style={{ fontSize: 14, fontWeight: 500, color: "#141414" }}>
                      {
                        props?.data?.account?.data?.attributes?.profile?.data?.attributes?.designation?.title
                      }
                      {
                        props?.data?.account?.data?.attributes?.profile?.data?.attributes?.designation?.company ?
                          <span><span style={{ color: "rgb(201, 200, 205)" }}> at </span> {props.data.account.data.attributes.profile.data.attributes.designation.company}</span>
                          : null
                      }
                      {
                        props?.data?.account?.data?.attributes?.profile?.data?.attributes?.designation?.title ?
                          <Text style={{ color: "rgb(201, 200, 205)" }}> | </Text> : null
                      }
                      {
                        props?.data?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills?.length ?
                          <Text style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: 400 }}>
                            {" "}
                            {getDomainSkills(props?.data?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills)}
                          </Text> : null
                      }

                    </Text> : null
                  }
                  {
                    props.data?.account?.data?.attributes?.role === "business" ?
                      <Text>
                        {
                          props.data?.account?.data?.attributes?.profile?.data?.attributes?.business ?
                            props.data?.account?.data?.attributes?.profile?.data?.attributes?.business : null
                        }
                        {
                          props.data?.account?.data?.attributes?.profile?.data?.attributes?.business && props.data?.account?.data?.attributes?.profile?.data?.attributes?.business_industry ? ", " : null
                        }
                        {
                          props.data?.account?.data?.attributes?.profile?.data?.attributes?.business_industry ?
                            props.data?.account?.data?.attributes?.profile?.data?.attributes?.business_industry : null
                        }

                      </Text>
                      : null
                  }
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "5px"
            }}
          >
            <div>
              {!props?.isFollowed &&
                props?.menu?.isCommunity === false &&
                profile != undefined &&
                (+props?.loginUserId !== +props?.data?.account?.data?.id) ?
                <Button
                  type="default"
                  shape="round"
                  style={{ color: "#4080c0", borderColor: "#4080c0" }}
                  onClick={() => {
                    props.toggleFollow()
                  }}
                >
                  Follow
                </Button> : null}
            </div>
            <div>
              {checkDropDown()}
            </div>
          </div>
        </div>
      </Col>
    )
  }
  return (
    <Card
      style={{
        borderWidth: "0px",
        borderRadius: "5px"
      }}
    >
      <Row gutter={[20, 3]}>
        {renderUserProfileUI()}
        <Col span={24}>
          <Text
            style={{
              color: "#8c8c8c",
              whiteSpace: "pre-wrap"
            }}
          >
            {props.data?.body}
          </Text>
        </Col>
        <Col span={24}>
          {
            props.data?.location ?
              <Text
                style={{
                  color: "rgb(0,0,0,0.25)"
                }}
              >
                Location : {props.data?.location}
              </Text> : null
          }
        </Col>
        <Col span={24}>
          {
            props.data?.taggings?.data.length > 0 ?
              <>
                {
                  showTag ?
                    <Text
                      style={{
                        color: "rgb(0,0,0,0.25)"
                      }}
                    >
                      Tagged : {" "}
                      {
                        props.data?.taggings?.data.map((i: any, index: number) => {
                          return <Text
                            style={{
                              color: '#5C85B2',
                              fontSize: 14,
                              fontWeight: 500,
                              cursor: 'pointer'
                            }}
                            onClick={() => handleUserRequest(i?.attributes)}
                          >
                            <span className="Tagging_data_style">{i?.attributes?.role === "business" ? i?.attributes?.profile?.data?.attributes?.company_name : i?.attributes?.full_name}</span>
                            {props.data?.taggings?.data?.length !== index + 1 ? ", " : null}
                          </Text>
                        })
                      }
                    </Text>
                    :
                    <Text style={{ color: "rgb(0,0,0,0.25)" }}>
                      Tagged : {" "}
                      {
                        props.data?.taggings?.data?.slice(0, 2)?.map((i: any, index: number) => {
                          return (
                            <Text
                              style={{
                                color: '#5C85B2',
                                fontSize: 14,
                                fontWeight: 500,
                                cursor: 'pointer'
                              }}

                              onClick={() => handleUserRequest(i?.attributes)}
                            >
                              <span className="Tagging_data_style">{i?.attributes?.role === "business" ? i?.attributes?.profile?.data?.attributes?.company_name : i?.attributes?.full_name}</span>
                              {props.data?.taggings?.data?.slice(0, 2)?.length !== index + 1 ? ", " : " "}
                            </Text>
                          )
                        })
                      }
                      {
                        props.data?.taggings?.data.length > 2 ?
                          <Text
                            onClick={() => setShowTag(true)}
                            style={{
                              cursor: 'pointer',
                              color: '#5C85B2'
                            }}
                          >
                            {`and ${props.data?.taggings?.data.length - 2} others`}
                          </Text> : null
                      }
                    </Text>
                }
              </>
              : null
          }
        </Col>
        {
          props.data?.media?.length ?
            <Col span={24} style={{ marginTop: "5px" }}>
              {renderMedia()}
            </Col>
            : null
        }
        <Col span={24} style={{ borderTop: '1px solid #f0f0f0', borderBottom: '1px solid #f0f0f0', marginTop: "10px" }}>
          <div
            style={{
              padding: "7px 0px"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Button
                type="text"
                style={{ color: "red" }}
                icon={props?.data?.liked ? <HeartFilled translate={{}} rev={{}} /> : <HeartTwoTone translate={{}} rev={{}} twoToneColor="red" />}
                onClick={() => toggleLike(props?.data?.id, props?.data?.liked)}
              >
                {likedCount} {props?.data?.like_count < 2 ? " Like" : " Likes"}
              </Button>
              <Button
                type="text"
                icon={<img src={comment} style={{ marginRight: "10px", height: "16px", width: "16px" }} />}
                onClick={() => {
                  getCommentsOfPost(props?.data?.id);
                }}
                disabled={commentCount === 0}
              >
                {commentCount} {commentCount < 2 ? " Comment" : " Comments"}
              </Button>
              <Button
                type="text"
                style={{ color: '#c4c4c4' }}
                icon={<img src={share} style={{ marginRight: "10px", height: "16px", width: "16px" }}
                />}
                onClick={showModal}
              >
                Share
              </Button>
            </div>
          </div>
          <div>
            <Modal title="Social-Share" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
              <ul className="social-share">
                <li>
                  {/* @ts-ignore */}
                  <EmailShareButton
                    url={`${url}/viewPost/${props?.data?.id}`}
                    subject={`${userData} has posted on appliedview`}
                  >
                    <EmailIcon round={true}></EmailIcon>
                  </EmailShareButton>
                </li>
                <li>
                  <FacebookShareButton
                    url={`${url}/viewPost/${props?.data?.id}`}
                    // @ts-ignore
                    quote={`${userData} has posted on appliedview`}
                    hashtag="#hello"
                  >
                    <FacebookIcon round={true} ></FacebookIcon>
                  </FacebookShareButton>
                  {/* @ts-ignore */}
                </li>
                <li>
                  {/* @ts-ignore */}
                  <WhatsappShareButton
                    url={`${url}/viewPost/${props?.data?.id}`}
                    title={`${userData} has posted on appliedview`}                  >
                    <WhatsappIcon round={true}></WhatsappIcon>
                  </WhatsappShareButton>
                </li>
                <li>
                  {/* @ts-ignore */}
                  <TwitterShareButton
                    url={`${url}/viewPost/${props?.data?.id}`}
                    title={`${userData} has posted on appliedview`}
                  >
                    {/* @ts-ignore  */}
                    <TwitterIcon round={true}></TwitterIcon>
                  </TwitterShareButton>
                </li>
              </ul>
            </Modal>
          </div>
        </Col>
        <Col span={24}>
          <div style={{ padding: "10px 0px" }}>
            {isEmojiPickerEnable && (
              <span ref={customClose}>
                <Picker disableAutoFocus onEmojiClick={onEmojiClick} />
              </span>
            )}
            <Input
              disabled={onSendComment}
              style={{
                fontSize: "16px",
                backgroundColor: "#FAFAFC",
                borderRadius: 40,
                width: '100%',
                border: '1px solid #F3F2F6'
              }}
              type="text"
              value={postComment}
              placeholder="Write your comment..."
              onKeyDown={(e) => e.key === "Enter" && handleSubmitComment(postComment, props?.data?.id)}
              onChange={({ target: { value } }) => onChange(value)}
              suffix={suffix}
            />


          </div>
        </Col>
        {renderCommentUI()}
      </Row>
      {renderMediaModal()}
      {showReportModal()}
    </Card>
    // }
  );
};
export default CustomPost;