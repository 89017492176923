import React from 'react';
// Customizable Area Start
import { Row, Col, Image, Form, Input, Typography, Button, Spin } from 'antd';
import 'antd/dist/antd.css';
import {
  iconImage,
  style,
  AppImage,
  AndroidImage,
  iosImage,
} from './assets';
import VerifyCodeController from './verifyCodeController.web';
import OtpInput from 'react-otp-input';
import { AlertMessage } from '../../alert/src/AlertMessage.web';
const { Title, Text, Link } = Typography;
// Customizable Area End

export default class VerifyCode extends VerifyCodeController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        <Row>
          <Col span={14} xs={0} sm={12} md={12} lg={14} xl={14} xxl={14}>
          {!this.state.verifyCodeBannerImage ? <Spin /> :
            <Image
              preview={false}
              src={this.state.verifyCodeBannerImage}
              wrapperStyle={{ height: '100%', width: '100%' }}
              style={{ height: '100%', width: '100%' }}
            />}
          </Col>
          <Col
            span={10}
            xs={24}
            sm={12}
            md={12}
            lg={10}
            xl={10}
            xxl={10}
            className={style.top_padding}
          >
            <Row>
              <Col xl={6} md={4} xs={3} />
              <Col xl={12} md={16} xs={18}>
                <Row>
                  <Col span={6} />
                  <Col span={12}>
                    <Image
                      preview={false}
                      className={style.logopadding}
                      src={iconImage}
                    />
                  </Col>
                  <Col span={6} />
                </Row>

                <Row className={style.loginText}>
                  <Title className={style.w_100_center} level={3} style={{ fontWeight: 500 }}>
                    Verify Code
                  </Title>
                  <Text type='secondary' style={{ textAlign: 'center', fontWeight: 500 }}>
                    A 4 digit code is sent to your email address and phone
                    number. Please input it here
                  </Text>
                </Row>
                <br />
                <br />
                <Form
                  initialValues={{ otp_code: '' }}
                  onFinish={this.handleOtp}
                >
                  <Form.Item
                    name='otp_code'
                    data-testid="otp_code"
                    rules={[
                      { required: false, message: 'enetr otp' },
                      () => ({
                        validator(_, value) {
                          const data = /^[0-9\b]+$/;
                          if (data.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('Please Enter Valid OTP!')
                          );
                        },
                      }),
                    ]}
                  >
                    <OtpInput
                      data-testid="otp_input"
                      className={style.otpInput}
                      numInputs={4}
                      separator={<span style={{ width: '3%' }} />}
                      inputStyle={{
                        fontWeight: 500,
                        color: "#a9bac8",
                        opacity: 0.5,
                        fontSize: 18,
                        borderColor: "#a9bac8 !important"
                      }}
                      isInputNum={true}
                    />
                  </Form.Item>
                  <Spin spinning={this.state.loading}>
                    <Button
                      className={style.btn_colors}
                      type='primary'
                      htmlType='submit'
                      shape='round'
                      size={'large'}
                      style={{ width: "100%" }}
                    >
                      Submit
                    </Button>
                  </Spin>
                </Form>
                <br />
                <Row className={style.loginText}>
                  <Button
                    className={style.w_100_center}
                    style={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color:
                        this.state.isResendOtp || this.state.seconds > 0
                          ? '#ccc'
                          : '#0e76a8',
                    }}
                    type='link'
                    onClick={() => this.onResendOTP()}
                    disabled={this.state.isResendOtp || this.state.seconds > 0}
                  >
                    Resend Code
                  </Button>
                  <Text type='secondary' style={{ fontWeight: 500, color: "#0f4762", opacity: 0.3 }}>
                    {` 0${this.state.time.m}:${this.state.time.s > 9
                      ? this.state.time.s
                      : '0' + this.state.time.s
                      }${' '}
                     minute left to enter OTP`}
                  </Text>
                  <br />
                  <Link
                    className={style.w_100_center}
                    style={{
                      fontSize: '16px',
                      color: '#0e76a8',
                      marginTop: '10px',
                      fontWeight: 500,
                    }}
                    href='/'
                  >
                    Back to Log In
                  </Link>
                </Row>
              </Col>
              <Col xl={6} md={4} xs={3} />
            </Row>
          </Col>
        </Row>
        <Row style={{ backgroundColor: '#f5f5f5' }}>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={14} xxl={14}>
            <Row
              className={style.app_alignCenter}
              style={{ justifyContent: 'flex-end', width: '100%' }}
            >
              <Image
                preview={false}
                src={AppImage}
                style={{ objectFit: 'fill' }}
              // wrapperStyle={{ width: "100%" }}
              />
            </Row>
          </Col>
          <Col
            span={12}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={10}
            xxl={10}
          // className={style.app_store}
          >
            <Row
              // className={style.app_alignCenter}
              style={{
                height: '100%',
                alignItems: 'center',
                paddingRight: '40px',
                paddingLeft: '40px',
              }}
            >
              <Col span={6} xs={0} sm={6} md={3} lg={3} xl={3} xxl={6} />
              <Col span={10} xs={24} sm={24} md={18} lg={18} xl={18} xxl={10}>
                <Row>
                  <Title level={3}>Available for Android and IOS</Title>
                  <Text
                    type='secondary'
                    className={style.w_100}
                    style={{
                      fontSize: '1.1rem',
                      lineHeight: '1.3rem',
                      paddingBottom: '20px',
                    }}
                  >
                    Download applied app from your Play Store or App Store
                  </Text>
                  <Col span={11}>
                    <Image preview={false} src={AndroidImage} />
                  </Col>
                  <Col span={2} />
                  <Col span={11}>
                    <Image preview={false} src={iosImage} />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={8} sm={8} md={3} lg={3} xl={3} xxl={8} />
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            justifyContent: 'center',
            paddingTop: '10px',
            paddingLeft: '40px',
            paddingRight: '40px',
          }}
        >
          <p>©2021 applied. All rights reserved.</p>
          <a href='/privacy-policy'>&nbsp;&nbsp;Privacy Policy&nbsp;&nbsp;</a>
          <a href='/terms-and-conditions'>&nbsp;&nbsp;Terms of use</a>
        </Row>
        <AlertMessage
          isModalVisible={this.state.isErrorModalShow}
          title={this.state.errorContent.title}
          body={this.state.errorContent.body}
          isError={this.state.errorContent.isError}
          onOk={() =>
            this.setState({ isErrorModalShow: false, errorContent: {} })
          }
        />
      </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End