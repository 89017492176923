import React, { Component } from "react";
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Spin,
  DatePicker,
  Steps,
  Popover,
  Space,
  Select,
  Avatar,
  Upload,
  message,
  Divider,
} from "antd";
// import { logo, style, NETWORK_PROFILE_ING } from "../../";
const { Text, Link, Title } = Typography;
import {
  UserOutlined,
  EnvironmentOutlined,
  CloseCircleOutlined,
  CheckCircleTwoTone,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  logo,
  NETWORK_PROFILE_ING,
  style,
} from "../../invitefriends/src/assets";

const CommunityBlockForum = (props: any) => {
  console.log("get all props", props);

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title={
          <span style={{ color: "white" }}>
            <img style={{ height: "32px", marginRight: "8px" }} src={logo} />
            applied
          </span>
        }
        style={{
          backgroundImage: "linear-gradient(#005574, #7aacd6)",
          color: "white",
        }}
      />
      <Row
        justify="center"
        style={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "10px",
          backgroundColor: "#eff8fe",
        }}
      >
        <Title level={4} style={{ fontSize: 16, fontWeight: "bold" }}>
          Community Forum
        </Title>
      </Row>
      <Row
        style={{
          minHeight: "18vh",
          padding: "0 15px 15px 15px",
          // backgroundColor: "#eff8fe",
        }}
      >
        <Col xs={0} sm={0} md={3} lg={4} xl={4}>
          <Card hoverable className="padding-card-network">
            <Row justify="center">
              {/* <Avatar size={64} src={item.attributes.photo} /> */}
              <Avatar size={64} src={NETWORK_PROFILE_ING} />
            </Row>
            <Row justify="center" style={{ paddingTop: "12px" }}>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  alignItems: "center",
                }}
              >
                {/* {item.attributes.full_name}{" "} */}
                Anje Keizer
              </Text>
              <br />
              <p style={{ width: "100%", marginBottom: "0", fontSize: 12 }}>
                Sr. Python Developer <Text type="secondary"> at </Text> Google
              </p>
              <br />
              <Text
                style={{ width: "100%", marginBottom: "0", fontSize: 12 }}
                type="secondary"
              >
                <EnvironmentOutlined translate={{}} rev={{}} />
                San Diego, Callifornia, US
              </Text>
            </Row>
            <Divider />
            <Row gutter={5} style={{ alignItems: "center" }}>
              <Col span={12}>
                <Button
                  className={style.skip_add_btn}
                  type="primary"
                  shape="round"
                  size={"small"}
                >
                  View Profile
                </Button>
              </Col>
              <Col span={4} />
              <Col span={4} className="network-button-cancel">
                <Button
                  type="primary"
                  shape="circle"
                  size={"small"}
                  icon={
                    <CloseCircleOutlined
                      translate={{}}
                      rev={{}}
                      style={{ fontSize: "20px" }}
                      twoToneColor="#52c41a"
                    />
                  }
                />
              </Col>
              {/* <Col span={1}></Col> */}
              <Col span={4} className="network-button-success">
                <Button
                  type="primary"
                  shape="circle"
                  size={"small"}
                  icon={
                    <CheckCircleTwoTone
                      translate={{}}
                      rev={{}}
                      style={{ fontSize: "20px" }}
                      twoToneColor="#52c41a"
                    />
                  }
                />
              </Col>
            </Row>
          </Card>
        </Col>
        {/* ); */}
        {/* })} */}
      </Row>
    </div>
  );
};

export default CommunityBlockForum;
