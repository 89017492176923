import React from 'react';
// Customizable Area Start
import { Avatar, Button, Card, Input, Typography, List } from "antd";
import {
    HeartTwoTone,
    HeartFilled,
    RollbackOutlined
} from "@ant-design/icons";
import EmojiPicker from 'emoji-picker-react';
import { sendIcon, faceIcon } from "./assets";
import RenderReplyComment from "./RenderReplyComment.web"
import RenderCommentController from './RenderCommentController.web';
import { ReplieDataType } from './Interface';
// Customizable Area End
class RenderComment extends RenderCommentController {
    // Customizable Area Start
    renderReplyComments = (data: ReplieDataType, index?: number) => {

        const { item, handleUserRequest } = this.props
        return (
            <div key={data?.id}
                data-testid={`RenderReplyComment${index}`}
                style={{ position: 'relative', left: '8%', marginTop: 6 }}>
                <RenderReplyComment
                    item={data}
                    onCommentLike={this.toggleCommentLike}
                    handleUserRequest={handleUserRequest}
                    handleSubmitReplyComment={this.handleSubmitReplyComment}
                    commentId={item.attributes.id}
                />
            </div>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { item, handleUserRequest, onCommentLike } = this.props;
        const { displayComment,
            emoji,
            onSendReplyComment,
            userReplyCommentData,
            rplyPostComment, } = this.state
        return (
            <div key={item.attributes.id}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: "row",
                        alignItems: "baseline",
                        justifyContent: "flex-start",
                        gap: "10px"
                    }}
                >
                    <div
                        onClick={() =>
                            handleUserRequest(item.attributes.account.data.attributes.profile.data.attributes)
                        }
                        data-testid="userNavigation"
                        style={{ cursor: "pointer" }}
                    >
                        <Avatar
                            src={item.attributes.account.data.attributes?.photo}
                            data-testid="Avatar"
                            size={30} />

                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                        }}
                        data-testid="text"
                    >
                        <Typography.Text
                            onClick={() => handleUserRequest(item.attributes.account.data.attributes.profile.data.attributes)}
                            style={{
                                cursor: "pointer",
                                color: "rgba(0, 0, 0, 0.85)",
                                fontSize: "13px",
                                fontWeight: 500,
                                marginBottom: "-13px"
                            }}
                            data-testid="textBody"
                        >
                            {item && item.attributes.account.data.attributes?.role === "business" ?
                                item.attributes.account.data.attributes.profile.data.attributes?.company_name :
                                item.attributes.account.data.attributes?.full_name}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                color: '#bbbbbb',
                                fontSize: 13,
                                fontWeight: 400,
                                marginBottom: "-13px"
                            }}
                        >
                            {item && item.attributes.comment}
                        </Typography.Text>
                        <div
                            data-testid="likeDislike"
                            style={{
                                display: 'flex',
                                flexDirection: "row",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                gap: "10px"
                            }}
                        >
                            <Button
                                type="text"
                                onClick={() =>
                                    onCommentLike(item.id, item.attributes?.liked)
                                }
                                data-testid="LikeorDislike"
                                style={{ color: "red", padding: "0px", fontSize: "12px" }}
                                icon={item.attributes.liked ? <HeartFilled translate={{}} rev={{}} /> : <HeartTwoTone translate={{}} rev={{}} twoToneColor="red" />}
                            >
                                {item && item.attributes.like_count} {item && item.attributes.like_count < 2 ? " Like" : " Likes"}
                            </Button>
                            <Button
                                type="text"
                                style={{ color: "#bbbbbb", padding: "0px", fontSize: "12px" }}
                                icon={<RollbackOutlined translate={{}} rev={{}} />}
                                data-testid="showOnReplycomment"
                                onClick={() => this.onReply()}
                            >
                                {userReplyCommentData.length ? userReplyCommentData.length : null} Reply
                            </Button>
                        </div>
                        {displayComment &&
                            <div>
                                <Card
                                    data-testid="Inputcard"
                                    bodyStyle={{
                                        flexDirection: "row",
                                        display: "flex",
                                        padding: "5px",
                                    }}
                                    style={{ borderWidth: "0px" }}
                                >
                                    <Input
                                        disabled={onSendReplyComment}
                                        onKeyDown={(event) => event.key === "Enter" && this.handleSubmitReplyComment(item.attributes.id, rplyPostComment)}
                                        onChange={(event) => this.onRplyChange(event.target.value)}
                                        style={{
                                            fontSize: "13px",
                                            backgroundColor: "#FAFAFC",
                                            borderRadius: 40,
                                            width: '100%',
                                            border: '1px solid #F3F2F6'
                                        }}
                                        type="text"
                                        data-testid="replyonComment"
                                        value={rplyPostComment}
                                        placeholder="Write your comment..."
                                        suffix={[
                                            <Button key="emoji"
                                                style={{ border: "none", outline: "none" }}
                                                type="ghost"
                                                onClick={this.handleEmoji}
                                                shape="circle"
                                                data-testid="emoji"
                                                icon={<img src={faceIcon} alt="" width={20} />}

                                            />,
                                            <div key="post-comment">
                                                {rplyPostComment.length ?
                                                    <Button
                                                        onClick={async () => {
                                                            this.handleSubmitReplyComment(item.attributes.id, rplyPostComment)
                                                        }}
                                                        shape="circle"
                                                        type="ghost"
                                                        style={{ border: "none", outline: "none" }}
                                                        data-testid="sendComment"
                                                        icon={
                                                            <img
                                                                src={sendIcon}
                                                                alt=""
                                                                width={30}
                                                            />}
                                                        disabled={rplyPostComment.length === 0}
                                                    /> : null}
                                            </div>
                                        ]}
                                    />
                                </Card>
                                {emoji && <EmojiPicker disableAutoFocus onEmojiClick={this.onReplyEmojiClick} />}
                            </div>
                        }
                    </div>
                </div>
                {
                    userReplyCommentData.length > 0 ?
                        <List
                            data-testid="renderReplyComments"
                            renderItem={this.renderReplyComments}
                            dataSource={userReplyCommentData.slice(0, this.state.handleReplyComments)}
                        /> : null
                }
                {
                    userReplyCommentData.length >= 2 && (this.state.handleReplyComments !== userReplyCommentData.length) ? <Button
                        type="text"
                        onClick={this.handleViewMore}
                        style={{
                            position: 'relative',
                            left: '8%',
                            cursor: "pointer",
                            marginTop: "20px",
                            fontWeight: 500
                        }}
                        data-testid="viewMore"
                    >
                        {userReplyCommentData.length - 1} View more reply...
                    </Button> : null
                }
            </div>
        )
        // Customizable Area End
    }
}
export default RenderComment;

// Customizable Area Start
// Customizable Area End