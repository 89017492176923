import React from 'react';
import { Modal, Typography } from 'antd';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';

const CommunityAlert = (props: any) => {
    const { handleCancel, modal, desc, heading, modalStatus } = props;
    return (
        <Modal
            width={375}
            style={{
                borderRadius: "4px",
            }}

            onCancel={handleCancel}
            footer={null}
            // placement="bottomRight"
            visible={modal}

            bodyStyle={{
                marginTop: '12px',
                textAlign: 'center',
                paddingBottom: '16px',
            }}
        >
            <>
                {!modalStatus && <CloseCircleFilled
                    translate={{}}
                    rev={{}}
                    style={{
                        fontSize: '44px',
                        paddingBottom: '16px',
                        color: '#ea0c0c',
                    }}
                />}
                {modalStatus && <CheckCircleFilled
                    translate={{}}
                    rev={{}}
                    style={{
                        fontSize: '44px',
                        paddingBottom: '16px',
                        color: '#36a906',
                    }}
                />}
                <br />
                <Typography style={{ fontSize: 18, fontWeight: 'bold', textTransform: "capitalize" }}>
                    {heading}
                </Typography>

                <Typography style={{ color: 'grey', opacity: 0.6, fontSize: 14, padding: '0px 10px' }}>
                    {desc}
                </Typography>
            </>
        </Modal>
    )
}

export default CommunityAlert;