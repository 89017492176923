import React, { Component } from 'react';
import { runEngine } from '../../../../framework/src/RunEngine';
import { Message } from '../../../../framework/src/Message';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import moment, { localeData } from 'moment';
import { getStorageData, removeStorageData, setStorageData } from '../../../../framework/src/Utilities';
import { RouterProps } from 'react-router';
import { Redirect } from "react-router-dom";
export const configJSON = require('../config');
import { Select, message, Checkbox, Modal, Row, Col, Button } from 'antd';
import { profile } from 'console';
const { Option } = Select;
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  token: string | null;
  category_data: [];
  domain_skills: object[];
  children: any[];
  categories_id: string | null;
  personal_skills: object[];
  skills_2: any[];
  employe_type_obj: object[];
  employment_type: any[];
  profileUrl: string;
  profilePictureFile: any;
  personalSkillVisible: boolean;
  domainSkillVisible: boolean;
  pre_data: any;
  isErrorModalShow: boolean;
  errorContent: any;
  sessionData: any;
  isSuccess: boolean;
  file: any;
  redirect: boolean;
  updateProfile: any;
  profileLoading: boolean;
  postGra: any;
  srGra: any;
  normalGradutation: any;
  other_personal_skill: any;
  other_domain_skill: any;
  copyProfileUrl: string;
  openCropModal: boolean
}
interface SS {
  id: any;
}

export default class EditPersonalProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: any;
  postUploadProfileImageApiCallId: any;
  getBusinessPrfileCallId: any;

  formref = React.createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      data: {},
      token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
      category_data: [],
      domain_skills: [],
      personal_skills: [],
      children: [],
      skills_2: [],
      employe_type_obj: [],
      employment_type: [],
      categories_id: '',
      profileUrl: '',
      profilePictureFile: {},
      personalSkillVisible: false,
      domainSkillVisible: false,
      pre_data: {},
      isErrorModalShow: false,
      errorContent: {},
      sessionData: {},
      isSuccess: false,
      file: {},
      redirect: false,
      updateProfile: {},
      profileLoading: true,
      postGra: [],
      srGra: [],
      normalGradutation: [],
      other_personal_skill: '',
      other_domain_skill: '',
      copyProfileUrl: '',
      openCropModal: false
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    let token = localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }
    this.getCategories();
    // this.getProfileData();
    // console.log(getStorageData("loginUserProfileData", true))
    let details: any = this.props.history?.location?.state;
    console.log(details);
    if (details !== null) {
      let newData = Object.assign(
        {},
        { experience: details?.experiences },
        { certificate: details?.certifications },
        { graduation: details?.graduations },
        { language: details?.languages },
        { contact: details?.contact_references }
      );
      console.log(newData);
      this.setState({
        data: newData,
        pre_data: details,
        profileLoading: false,
        profileUrl: details?.profile ? details?.profile?.profileUrl : details?.photo,
      });
      details?.graduations?.map((c: any) => {
        if (c.type === "post_graduations") {
          this.setState((prevState) => ({
            postGra: [...prevState.postGra, c],
          }));
        } else if (c.type === "senior_secondary") {
          this.setState((prevState) => ({
            srGra: [...prevState.srGra, c],
          }));
        } else if (c.type === "graduations") {
          this.setState((prevState) => ({
            normalGradutation: [...prevState.normalGradutation, c],
          }));
        }
      });

    } else {
      this.setState({ loading: true });
      let loginDetails = await getStorageData('loginUser');
      if (loginDetails) {
        this.setState({
          sessionData: loginDetails,
          // loading: false,
        });
        console.log('personal loginDetails true login1', loginDetails);
      } else {
        let loginDetails = await getStorageData('signUpUser');
        if (loginDetails) {
          this.setState({
            sessionData: loginDetails,
            // loading: false,
          });
          console.log('personal signUpUser true signup1', loginDetails);
        } else {
          let loginDetails = await getStorageData('verificationData');
          if (loginDetails) {
            this.setState({
              sessionData: loginDetails,
              // loading: false,
            });
            console.log('personal signUpUser true signup3', loginDetails);
          }
        }
      }
      console.log('personal signUpUser true 2', loginDetails);
      this.setState({
        loading: false,
      });
    }
    this.setState({ loading: false });
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  getBase64(img: any, callback: any) {
    console.log(img, "adsfjakjf aksdakd adkf")
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  async beforeUpload(file: any) {
    console.log("file==>", file);
    const isJpgOrPng = (file.type.slice(0, 5)) == "image";
    if (!isJpgOrPng) {
      //  message.error("You can only upload Image file!");
    } else {
      console.log("isJpgOrPng===>", isJpgOrPng);
      this.getBase64(file, (imageUrl: string) => {
        console.log("imageUrl===>", imageUrl);
        this.setState(
          {
            profileUrl: imageUrl,
            copyProfileUrl: imageUrl,
            openCropModal: true,
            loading: false,
          }
        );
      });
    }
    return isJpgOrPng;
  }

  handleImageChange = (info: any) => {
    // console.log("info", info);
    this.setState({
      file: info.file.originFileObj,
    });
    // console.log("@@@uploade Image test", this.state.file);
    // this.uploadProfileImage();
  };



  handleData = async (update_data: any) => {
    console.log('=====>', update_data);
    delete update_data['profile_pic'];
    if (this.state.personalSkillVisible) {
      let index = update_data.personal_skills.indexOf('Other');
      console.log('skill=====>', index, update_data.other_personal_skills);
      await update_data.personal_skills.splice(
        index,
        1,
        this.state.other_personal_skill
      );
      console.log('skill=====>', update_data.personal_skills);
    }
    if (this.state.domainSkillVisible) {
      let index = update_data.domain_skills.indexOf('Other');
      console.log('skill=====>', index);
      await update_data.domain_skills.splice(
        index,
        1,
        this.state.other_domain_skill
      );
      console.log('skill=====>', update_data.personal_skills);
    }
    let profile = await {
      ...update_data,
      picFile: this.state.file,
      date_of_birth: moment(update_data.date_of_birth._d).format('YYYY-MM-DD'),
      // date_of_birth: moment(update_data.date_of_birth._d).format("DD-MM-YYYY"),
    };
    let data = Object.assign({}, profile, {
      profileUrl: this.state.profileUrl,
    });

    const certificationData = {
      certificate: this.state.pre_data?.certifications?.length > 0 && Array.isArray(this.state.pre_data?.certifications) ? this.state.pre_data?.certifications : [
        {
          name: null,
          description: null,
        },
      ],
      award: this.state.pre_data?.awards?.length > 0 && Array.isArray(this.state.pre_data?.awards) ? this.state.pre_data?.awards : [
        {
          title: null,
          description: null,
        },
      ],
      acheivement: this.state.pre_data?.achievements?.length > 0 && Array.isArray(this.state.pre_data?.achievements) ? this.state.pre_data?.achievements : [
        {
          description: null,
        },
      ],
    };
    const graduationData = {
      post: this.state.postGra?.length > 0 && Array.isArray(this.state.postGra) ? this.state.postGra : [
        {
          type: "post_graduations",
          degree: null,
          university: null,
          year: null,
          score: null,
        },

      ], graduation: this.state.normalGradutation.length > 0 && Array.isArray(this.state.normalGradutation) ? this.state.normalGradutation : [
        {
          type: "graduations",
          degree: null,
          university: null,
          year: null,
          score: null,
        },
      ], srSecondary: this.state.srGra?.length > 0 && Array.isArray(this.state.srGra) ? this.state.srGra : [
        {
          type: "senior_secondary",
          stream: null,
          board: null,
          year: null,
          score: null,
        },
      ],
    };
    const languageData = {
      patents: this.state.pre_data?.patents?.length > 0 && Array.isArray(this.state.pre_data?.patents) ?
        this.state.pre_data?.patents : [
          {
            name: null,
            description: null,
          },
        ],
      other: this.state.pre_data?.interests?.length > 0 && Array.isArray(this.state.pre_data?.interests) ? this.state.pre_data?.interests : [
        {
          name: null,
        },
      ],
      language: this.state.pre_data?.languages?.length > 0 && Array.isArray(this.state.pre_data?.languages) ?
        this.state.pre_data?.languages : [],
    };
    const contactData = {
      contact: this.state.pre_data?.contact_references?.length > 0 && Array.isArray(this.state.pre_data?.contact_references) ? this.state.pre_data?.contact_references : [
        {
          name: null,
          contact: null

        },
      ],
      information: this.state.pre_data?.description ? this.state.pre_data?.description : null
    };
    const experienceData = {
      experience: this.state?.pre_data?.experiences?.length > 0 && Array.isArray(this.state?.pre_data?.experiences) ? this.state?.pre_data?.experiences : [
        {
          company: null,
          title: null,
          start_date: null,
          end_date: null,
          description: null,
        },
      ]
    }

    let finalData = await Object.assign(
      {},
      { profile: data },
      { experience: experienceData?.experience },
      { certificate: certificationData },
      { graduation: graduationData },
      { language: languageData },
      { contact: contactData }
    );
    console.log("testdata__", finalData);
    console.log("testdata__", finalData.experience);

    localStorage.setItem('employment_type', update_data.employment_type);
    if (update_data.employment_type == 'Student') {
      this.props.history.replace({ state: finalData });
      this.props.history.push({
        pathname: '/edit/profile/personal/achievements',
        state: finalData,
      });
    } else {
      this.props.history.replace({ state: finalData });
      this.props.history.push({
        pathname: '/edit/profile/personal/experience',
        state: finalData,
      });
    }
  };

  handleCategory = () => {
    let domain_skills = [...this.state.domain_skills];
    let personal_skills = [...this.state.personal_skills];
    let employe_type_obj = [...this.state.employe_type_obj];
    const children = [...this.state.children];
    const skills_2 = [...this.state.skills_2];
    const employment_type = [...this.state.employment_type];
    this.state.category_data?.map((data: any) => {
      if (data.attributes.name === 'Domain Skills') {
        domain_skills = data.attributes.sub_categories;
      }
      if (data.attributes.name === 'Personal Skills') {
        personal_skills = data.attributes.sub_categories;
      }
      if (data.attributes.name === 'Employment Type') {
        employe_type_obj = data.attributes.sub_categories;
      }
    });
    domain_skills.forEach((data: any, index) => {
      children.push(
        <Option
          style={{
            borderBottom: '1px solid #7aacd6',
            padding: '10px 20px 10px 20px',
          }}
          value={data.name}
          key={index}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              // paddingTop: "0.7rem",
            }}
          >
            <div>{data.name}</div>

          </div>
        </Option>
      );
    });
    personal_skills.forEach((data: any, index) => {
      skills_2.push(
        <Option
          style={{
            borderBottom: '1px solid #7aacd6',
            padding: '10px 20px 10px 20px',
          }}
          value={data.name}
          key={index}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              // paddingTop: "0.7rem",
            }}
          >
            <div>{data.name}</div>

          </div>
        </Option>
      );
    });
    employe_type_obj.forEach((data: any, index) => {
      employment_type.push(
        <Option
          style={{
            borderBottom: '1px solid #7aacd6',
            padding: '10px 20px 10px 20px',
          }}
          value={data.name}
          key={index}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              // paddingTop: "0.7rem",
            }}
          >
            <div>{data.name}</div>

          </div>
        </Option>
      );
    });
    this.setState({
      children: children,
      loading: false,
      skills_2: skills_2,
      employment_type: employment_type,
    });
  };

  getCategories = () => {
    const header = {
      'Content-Type': 'application/json',
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.setState({
      categories_id: requestMessage.id,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  antMessage = (msg: string) => {
    return message.error(msg, 1)
  }

  getProfileData = async () => {
    const header = {
      'Content-Type': 'application/json',
      "token": localStorage.getItem('token')
      // token:
      //   'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTM0OSwiZXhwIjoxNjMwNDA0NDIzLCJ0b2tlbl90eXBlIjoibG9naW4ifQ.hxVwjF3NVKDdwd81Y5yGSp6aht7U-2yHZH1DWDuYbw3av4yUlhmFmk9UYEGKFVwT0XW7iku5S3ryH3S8-M5viw',
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessPrfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    console.log(
      '@@@ Request ===========',
      requestMessage,
      this.getBusinessPrfileCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategoriesApiReceiveCall = async (getCategoriesResponseJson: any) => {
    if (getCategoriesResponseJson.data) {
      this.setState(
        {
          category_data: getCategoriesResponseJson.data,
        },
        this.handleCategory
      );
    } else if (getCategoriesResponseJson.errors) {
      if (getCategoriesResponseJson.errors[0]?.token) {
        localStorage.removeItem("token");
        await removeStorageData("loginUser");
        await removeStorageData("userProfile");
        await removeStorageData("accountData");
        await removeStorageData("signUpUser");
        await removeStorageData("signUpUserResponse");
        await removeStorageData("verificationData");
        await removeStorageData("signUpUserID");
        await removeStorageData("isFromLogin");
        await removeStorageData("updatedProfile");
        await removeStorageData("userProfile-1");
        this.antMessage(getCategoriesResponseJson.errors[0].token)
        this.props.history.push({
          pathname: "/"
        });
      }
      this.setState({
        isErrorModalShow: true,
        errorContent: {
          title: 'Error',
          isError: true,
          body: getCategoriesResponseJson.errors[0].token,
        },
      });
    } else {
      console.log('something went wrong');
    }
  }

  async receive(from: string, message: Message) {
    console.log('receive');
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId == this.getCategoriesApiCallId) {
          await this.getCategoriesApiReceiveCall(responseJson);
        } else if (apiRequestCallId == this.postUploadProfileImageApiCallId) {
          this.setState({
            isSuccess: true,
          });
          const usedata = await getStorageData('loginUser');
          const setUseData = { ...usedata, profile_pic: responseJson.data.attributes.photo }
          // console.log(usedata,"asdkajsdlgajsl from Edit Personal",setUseData)
          setStorageData('loginUser', setUseData)
          console.log(responseJson);
        } else if (
          getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.getBusinessPrfileCallId !== null &&
          this.getBusinessPrfileCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
          // this.apiGetUserOtpCallId = null;

          if (responseJson) {
            this.getPersonalProfileSuccessCallBack(responseJson);
          } else {
            this.setState({ profileLoading: false });
            this.getPersonalProfileFailureCallBack(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else {
        console.log('test');
      }
    }
  }

  // @@@@ Success And Failure Call Back For Get Profile Data=================

  getPersonalProfileSuccessCallBack = (responseJson: any) => {
    console.log(
      '@@@ Get Profile Data success call Back ========',
      responseJson?.data?.attributes?.profile?.data?.attributes
    );
    if (Array.isArray(responseJson.data?.attributes?.profile?.data?.attributes?.graduations)) {
      responseJson.data?.attributes?.profile?.data?.attributes?.graduations?.map((c: any) => {
        if (c.type === "post_graduations") {
          // console.log('hello world,', c)
          this.setState((prevState) => ({
            postGra: [...prevState.postGra, c],
          }));
        } else if (c.type === "senior_secondary") {
          // console.log('hello world,', c)
          this.setState((prevState) => ({
            srGra: [...prevState.srGra, c],
          }));
        } else {
          // console.log('hello world,', c)

          this.setState((prevState) => ({
            normalGradutation: [...prevState.normalGradutation, c],
          }), () => console.log(this.state.postGra, this.state.srGra, this.state.normalGradutation, 'test'));
        }
      });
    } else {
      console.log(this.state.postGra, this.state.srGra, this.state.normalGradutation, 'test')
    }
    // this.setState({ company_nane: company_nane });
    this.setState({ updateProfile: responseJson?.data?.attributes?.profile?.data?.attributes, profileLoading: false });
  };
  getPersonalProfileFailureCallBack = (responseJson: any) => {
    console.log(
      '@@@ Get Profile Data Failure call Back ========',
      responseJson
    );
    this.setState({ profileLoading: false });
  };

}
