import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
import { RouterProps } from "react-router";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  isResendOtp: boolean;
  seconds: number;
  isFromLogin: boolean
  token: string
  userMobile: string
  hasErrored: boolean
  time: any;
  isErrorModalShow: boolean
  errorContent: any
  verificationData: any
  verifyPhonePageBannerImageId: string | null;
  verifyPhonePageBannerImage: string;
}
interface SS {
  id: any;
}

export default class VerifyPhoneController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountApiCallId: any;
  otpResendApiCallId: any;
  getVerifyPhonePageBannerImageApiCallId = '';
  formref = React.createRef();
  timer: any;
  constructor(props: Props) {
    super(props);
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: false,
      isRegistration: true,
      data: this.props.history ? this.props.history.location.state : "",
      seconds: 60,
      isResendOtp: false,
      isFromLogin: false,
      token: '',
      userMobile: '',
      hasErrored: false,
      time: {},
      isErrorModalShow: false,
      errorContent: {},
      verificationData: {},
      verifyPhonePageBannerImageId: '',
      verifyPhonePageBannerImage: ''
    };
  }
  async componentDidMount() {
    this.getVerifyPhonePageBannerImage();
    let history: any = await this.props.history ? this.props.history.location.state : "";
    if (history && history.isFromLogin) {
      await this.setState({ isFromLogin: true })
      this.onResendOTP()
    } else {
      this.getTimer()
      this.setState({ isFromLogin: false })
    }
    let res: any = {}
    await getStorageData("signUpUser").then((result) => {
      res = result
    })
    if (this.state.isFromLogin) {
      let full_phone_number = this.state?.data?.full_phone_number ? this.state?.data?.full_phone_number : res?.full_phone_number
      this.setState({ userMobile: full_phone_number })
    } else {
      let full_phone_number = this.state?.data?.responseJson.data?.attributes?.full_phone_number ? this.state?.data?.responseJson?.data?.attributes.full_phone_number : res?.full_phone_number
      this.setState({ userMobile: full_phone_number })
    }
  }
  getTimer() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    this.startTimer();
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  async countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    // Check if we're at zero.
    if (seconds == 0) {
      await clearInterval(this.timer);
      this.timer = 0;
    }
  }
  secondsToTime(secs: any) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }
  handlePhoneOtp = async (data: {} | any) => {
    if (data.otp_code.length == 4) {
      this.setState({ loading: true, hasErrored: false })
      const header = {
        "Content-Type": "application/json",
      };
      let signuptoken = ''
      await getStorageData("signUpUserResponse").then(async (res) => {
        signuptoken = await res?.sms_otp?.meta.token
      });
      // console.log("signuptoken", this.state.data?.sms_otp?.meta.token, signuptoken)
      let token = this.state.isFromLogin || this.state.token ? this.state.token : signuptoken
      // let token = this.state.data?.sms_otp?.meta.token ? this.state.data.sms_otp.meta.token : signuptoken
      Object.assign(data, { token: token });
      const json_data = {
        data: data,
      };
      const httpBody = json_data;
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.matchEmailOTPApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      setTimeout(runEngine.sendMessage(requestMessage.id, requestMessage), 3000);
      // runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.setState({ hasErrored: true })
      this.setState({ isErrorModalShow: true, errorContent: { title: "error", isError: true, body: "Please Enter Valid OTP!" } })
    }

  };
  async receive(from: string, message: Message) {
    console.log("receive");
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId == this.otpResendApiCallId) {
          this.setState({ isResendOtp: false });
          if (responseJson.sms_otp) {
            this.setState({ token: responseJson?.sms_otp?.meta.token });
            this.setState({
              seconds: 60,
              isResendOtp: false,
            });
            this.getTimer();
          } else {
            this.setState({
              isResendOtp: false,
              seconds: 0,
            });
          }
          console.log("otpResendApiCallId", responseJson);
          // this.setState({ data: responseJson });
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          this.updatePhoneVerifyPageCreateAccountState(responseJson);
        } else if (apiRequestCallId == this.getVerifyPhonePageBannerImageApiCallId) {
          this.updateVerifyPhonePageBannerImageState(responseJson);
        }
      } else {
        console.log("hello");
      }
    }
  }
  async onverified(responseJson: any) {
    await this.setState({ verificationData: responseJson.messages[0].otp })
    await localStorage.setItem("token", responseJson.messages[0]?.otp?.token);
    // await localStorage.setItem("refresh_token", responseJson.messages[0]?.otp?.refresh_token);
    await setStorageData("verificationData", responseJson.messages[0]?.otp)
    await setStorageData('loginUser', responseJson.messages[0]?.otp)
    console.log("verificationData", this.state.verificationData);
    this.props.history.push({
      pathname: "/varification/success",
      state: this.state.verificationData
    });
  }

  getTime() {
    if (this.state.seconds > 0) {
      setTimeout(async () => {
        let data = this.state.seconds - 1;
        this.setState({ seconds: data });
        // console.log("seconds", this.state.seconds);
        // return this.state.seconds;
      }, 1000);
    } else {
      this.setState({ seconds: 0 });
    }
    if (this.state.seconds == 60) {
      return "01:00";
    } else if (this.state.seconds > 0) {
      return `00:${this.state.seconds.toString().length == 1
        ? "0" + this.state.seconds
        : this.state.seconds
        }`;
    } else {
      return this.state.seconds;
    }
  }
  async onResendOTP() {
    this.setState({ isResendOtp: true, seconds: 0 });
    const res = await getStorageData("signUpUser");
    let full_phone_number = ''
    const isPN = isNaN(res);
    console.log("isPN: ", res, isPN);
    if (this.state.isFromLogin) {
      console.log("from login", this.state?.data)
      full_phone_number = this.state?.data?.full_phone_number ? this.state?.data?.full_phone_number : res?.full_phone_number
    } else {
      full_phone_number = this.state?.data?.responseJson.data?.attributes?.full_phone_number ? this.state?.data?.responseJson?.data?.attributes?.full_phone_number : res?.full_phone_number
      console.log("from signup", this.state?.data)
    }
    const header = {
      "Content-Type": configJSON.apiResendOtpContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage = ", JSON.stringify(requestMessage));

    console.log(
      "requestMessage messageId = ",
      JSON.stringify(requestMessage.messageId)
    );

    this.otpResendApiCallId = requestMessage.messageId.toString();
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiResendOtpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const otpData = {
      full_phone_number: full_phone_number,
    };

    const httpBody = otpData;
    console.log("httpBody", httpBody);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiResendOtpMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getVerifyPhonePageBannerImage = () => {
    const header = {
      'Content-Type': 'application/json',
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVerifyPhonePageBannerImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandingPageImageAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.setState({
      verifyPhonePageBannerImageId: requestMessage.id,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateVerifyPhonePageBannerImageState = (verifyPhonePageBannerImageData: any) => {
    if (verifyPhonePageBannerImageData.data) {
      this.setState({
        verifyPhonePageBannerImage: verifyPhonePageBannerImageData.data[0].attributes.image,
      });
    } else {
      this.setState({
        isErrorModalShow: true,
        errorContent: {
          title: "Error",
          isError: true,
          body: "Unable to load verify phone banner image!"
        },
      });
    }
  };
  updatePhoneVerifyPageCreateAccountState = (phoneVerifyCreateAccountResponse: any) => {
    this.setState({ loading: false })
    if (phoneVerifyCreateAccountResponse.messages && phoneVerifyCreateAccountResponse.messages[0]?.otp) {
      this.onverified(phoneVerifyCreateAccountResponse)
    } else if (phoneVerifyCreateAccountResponse.errors) {
      if (phoneVerifyCreateAccountResponse.errors && phoneVerifyCreateAccountResponse.errors[0]?.pin == "OTP has been expired, please request a new one.") {
        this.setState({ isErrorModalShow: true, errorContent: { title: "error", isError: true, body: phoneVerifyCreateAccountResponse.errors[0]?.pin } })
      } else {
        this.props.history.push({
          pathname: "/varification/error",
          state: { isFromMail: false },
        });
      }
    } else {
      this.setState({ isErrorModalShow: true, errorContent: { title: "error", isError: true, body: phoneVerifyCreateAccountResponse.errors[0]?.pin } })
      this.props.history.push({
        pathname: "/varification/error",
        state: { isFromMail: false },
      });
    }
  };
}
