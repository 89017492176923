import React, { Component } from 'react';
// Customizable Area Start
import { runEngine } from '../../../framework/src/RunEngine';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import { IBlock } from '../../../framework/src/IBlock';
import confirm from '../../alert/src/alert.web';
import AlertError from '../../alert/src/alertError.web';
import { FormInstance } from "antd/lib/form";
import { RouterProps } from 'react-router';
export const configJSON = require('./config');
import { Select, message } from 'antd';
import { getStorageData, setStorageData, removeStorageData } from '../../../framework/src/Utilities';
const { Option } = Select;
// Customizable Area End
export interface Props extends RouterProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  profileLoading: boolean;
  data: any;
  industry_type: any;
  token: string | null;
  businessIndustryList: any;
  businessOwnership: any;
  noOfEmployee: any;
  selectedNoOfEmployee: any;
  Other_Business_industry: any;
  otherBusinessIndustryvisible: boolean;
  profileUrl: string;
  file: any;
  company_nane: any;
  value: any;
  prev_data: any;
  category_data: [];
  category_list: object[];
  sub_category: any[];
  sub_category_data: object[];
  loadLocation: boolean;
  loadSubCategory: boolean;
  latitude: string | null;
  longitude: string | null;
  updateProfile: any;
  locationReq: boolean;
  currentYear: number;
  yearList: number[];
  copyProfileUrl: string;
  openCropModal: boolean;
  backgroundImage: string
  backgroundImageFile: any
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EditBusinessBasicController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBusinessCategoryCallId: any;
  postUploadProfileImageApiCallId: any;
  getBusinessPrfileCallId: any;

  formref = React.createRef();
  profile_formref = React.createRef<FormInstance>();
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      loading: false,
      profileLoading: true,
      data: {},
      industry_type: localStorage.getItem('industry_type')
        ? localStorage.getItem('industry_type')
        : '',
      token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      businessIndustryList: [],
      businessOwnership: [],
      noOfEmployee: [],
      selectedNoOfEmployee: 'no.',
      Other_Business_industry: "",
      otherBusinessIndustryvisible: false,
      profileUrl: '',
      file: {},
      company_nane: '',
      value: '',
      prev_data: {},
      category_data: [],
      category_list: [],
      sub_category_data: [],
      sub_category: [],
      loadLocation: false,
      loadSubCategory: false,
      latitude: null,
      longitude: null,
      updateProfile: {},
      locationReq: true,
      currentYear: new Date().getFullYear(),
      yearList: [],
      copyProfileUrl: '',
      openCropModal: false,
      backgroundImage: '',
      backgroundImageFile: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubCategory = this.handleSubCategory.bind(this);
    this.updateSubCategories = this.updateSubCategories.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.getCordinates = this.getCordinates.bind(this);
    this.reverseGeoCodeCordinates = this.reverseGeoCodeCordinates.bind(this);
    this.handleLocationError = this.handleLocationError.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    let startYear = 1801;
    let year = [];
    while (startYear <= this.state.currentYear) {
      year.push(startYear);
      startYear = startYear + 1;
    }
    this.setState({ yearList: year.reverse() });
    this.getProfileData();
    this.getCategories();

  }

  handleChange(event: any) {
    this.setState({ selectedNoOfEmployee: event });
  }

  updateSubCategories() {
    this.setState({ loadSubCategory: true });

    if (this.state.updateProfile?.business_industry && this.state.category_data) {
      let checkIndustry: any = this.state.category_data?.filter(
        (item: any) => item.attributes.name === this.state.updateProfile.business
      );
      // console.log('demo', checkIndustry, this.state.updateProfile?.business_industry)
      this.setState({
        sub_category: checkIndustry[0]?.attributes.sub_categories,
      });
    } else if (this.state.data.industry_type && this.state.category_data) {
      let checkIndustry: any = this.state.category_data?.filter(
        (item: any) =>
          item.attributes.name === this.state.data?.industry_type
      );
      // console.log(checkIndustry, "update data", this.state.category_data?);
      this.setState({
        sub_category: checkIndustry[0]?.attributes?.sub_categories,
      });
    } else {
      let checkIndustry: any = this.state.category_data?.filter(
        (item: any) => item.attributes.name === this.state.updateProfile?.business_industry
      );
      this.setState({
        sub_category: checkIndustry[0]?.attributes?.sub_categories,
      });
      // console.log(this.state.category_data?, 'demo',
      //   this.state.updateProfile?.business_industry, this.state.updateProfile,
      //   this.state.updateProfile.business)
      // console.log("error in loading sub_category_data");
    }
    setTimeout(() => {
      this.setState({ loadSubCategory: false });
    }, 200);
  }

  handleSubCategory(data: any) {
    let checkIndustry: any = this.state.category_data.filter(
      (item: any) => item.attributes.name === data
    );
    this.setState(
      {
        sub_category: checkIndustry[0].attributes.sub_categories,
      }
    );

    this.profile_formref?.current?.setFieldsValue({ business_industry: undefined })
    this.setState({ otherBusinessIndustryvisible: false })
  }

  getLocation() {
    this.setState({ loadLocation: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.getCordinates,
        this.handleLocationError
      );
    } else {
      this.setState({ loadLocation: false });
    }
  }

  getCordinates(position: any) {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      // userAddress
    });
    this.reverseGeoCodeCordinates();
  }

  async reverseGeoCodeCordinates() {
    let key = "AIzaSyCBH92YX2oCbI7PChqFNrgzw-PolHAiKZc";
    await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.latitude
      },${this.state.longitude}&sensor=false&key=${key}`
    )
      .then((response) => response.json())
      .then((data) => {
        let newData = data.results[0].address_components;
        let city = newData.filter(
          (ac: { types: string | string[] }) => ~ac.types.indexOf("locality")
        )[0].long_name;
        let country = newData.filter(
          (ac: { types: string | string[] }) => ~ac.types.indexOf("country")
        )[0].long_name;
        this.setState(
          (state) => ((state.updateProfile.location = city + ", " + country), state)
        );
        setTimeout(() => {
          this.profile_formref?.current?.setFieldsValue({
            location: city + ", " + country
          });
        }, 0);
      })
      .catch((error) => {
        this.setState({ loadLocation: false });
        alert(`error while location, ${error}`);
      });
    this.setState({ loadLocation: false });
  }

  handleLocationError(error: any) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        this.setState({ loadLocation: false });
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        this.setState({ loadLocation: false });
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        this.setState({ loadLocation: false });
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        this.setState({ loadLocation: false });
        alert("An unknown error occurred.");
        break;
    }
  }

  //=============@@@ Upload Profile Image==========

  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  async beforeUpload(file: any) {
    const isJpgOrPng = (await file.type.slice(0, 5)) == 'image';
    if (!isJpgOrPng) {
      //  message.error("You can only upload Image file!");
    } else {
      this.getBase64(file, (imageUrl: string) => {
        this.setState(
          {
            copyProfileUrl: imageUrl,
            openCropModal: true,
            profileUrl: imageUrl,
            loading: false,
          },
        );
      });
    }
    return isJpgOrPng;
  }

  async uploadBackgroundImage(file: any) {
    const isJpgorPng = (await file.type.slice(0, 5)) == 'image';
    if (!isJpgorPng) {
      console.log("file", file);
    } else {
      this.getBase64(file, (imageurl: string) => {
        this.setState(
          {
            backgroundImage: imageurl,
          },
        );
      });
    }
    return isJpgorPng;
  }
  handleImageChange = (info: any) => {
    this.setState({
      file: info.file.originFileObj,
    });
  };

  handleInvalidToken = async (responseJson: { errors: { token: any; }[]; }) => {
    if (responseJson?.errors[0]?.token) {
      this.props.history.push({
        pathname: "/"
      });
      localStorage.removeItem("token");
      await removeStorageData("loginUser");
      await removeStorageData("userProfile");
      await removeStorageData("accountData");
      await removeStorageData("signUpUser");
      await removeStorageData("signUpUserResponse");
      await removeStorageData("verificationData");
      await removeStorageData("signUpUserID");
      await removeStorageData("isFromLogin");
      await removeStorageData("updatedProfile");
      await removeStorageData("userProfile-1");
    }
  }

  handleBusinessCategory = (message: Message) => {
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    console.log('get all buisness', errorReponse);
    if (responseJson) {
      if (responseJson?.errors) {
        this.handleInvalidToken(responseJson)
      } else {
        this.getbusinessCategorySuccessCallBack(responseJson);
      }
    } else {
      this.getBusinessCategoryFailureCallBack(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleUploadProfileImage = async (message: Message) => {
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const usedata = await getStorageData('loginUser');
    const setUseData = { ...usedata, profile_pic: responseJson.data.attributes.photo }
    setStorageData('loginUser', setUseData)
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson) {
      if (responseJson?.errors) {
        this.handleInvalidToken(responseJson)
      } else {
        this.postUploadImageSuccessCallBack(responseJson);
      }
    } else {
      this.postUploadImageFailureCallBack(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleGetBusinessPrfile = (message: Message) => {
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    console.log('get all buisness', errorReponse);
    if (responseJson) {
      if (responseJson?.errors) {
        this.handleInvalidToken(responseJson)
      } else {
        this.getbusinessProfileSuccessCallBack(responseJson);
      }
    } else {
      this.getBusinessProfileFailureCallBack(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);

  }

  //======================@@@@ Receive Function =======================

  async receive(from: string, message: Message) {
    // Get category Data=====================
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBusinessCategoryCallId !== null &&
      this.getBusinessCategoryCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.handleBusinessCategory(message);
    }
    // Upload Profile Picture=====================
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postUploadProfileImageApiCallId !== null &&
      this.postUploadProfileImageApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.handleUploadProfileImage(message)
    }
    // Get Profile Data
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBusinessPrfileCallId !== null &&
      this.getBusinessPrfileCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.handleGetBusinessPrfile(message);
    }
  }

  //======================@@@ Success And Failure Call Back ======================

  getbusinessCategorySuccessCallBack = (responseJson: any) => {
    let dropDownDetail = responseJson && responseJson.data;
    let checkBusinessInds = dropDownDetail.filter(
      (item: any) => item.attributes.name === "Business Industry"
    );
    let checkOwnerShip = dropDownDetail.filter(
      (item: any) => item.attributes.name === "Ownership"
    );
    let checkOfEmployee = dropDownDetail.filter(
      (item: any) => item.attributes.name === "No of Employees"
    );
    this.setState(
      {
        category_data: dropDownDetail,
        businessIndustryList: checkBusinessInds[0].attributes.sub_categories,
        businessOwnership: checkOwnerShip[0].attributes.sub_categories,
        noOfEmployee: checkOfEmployee[0].attributes.sub_categories,
      }
    );
    setTimeout(() => {
      console.log('calling updateSub');
      this.updateSubCategories();
    }, 1000)
  };

  getBusinessCategoryFailureCallBack = (responseJson: any) => {
    console.log(
      '@@@ Get Business Profile Failure call Back ========',
      responseJson
    );
  };

  //==========@@@ Success And Failure Call Back For upload Image==================

  postUploadImageSuccessCallBack = (responseJson: any) => {
    console.log(
      '@@@ Post Upload Profile Image success call Back ========',
      responseJson
    );
  };

  postUploadImageFailureCallBack = (responseJson: any) => {
    console.log(
      '@@@ Post Upload Profile Image Failure call Back ========',
      responseJson
    );
  };

  // @@@@ Success And Failure Call Back For Get Profile Data=================

  getbusinessProfileSuccessCallBack = (responseJson: any) => {
    this.setState({ updateProfile: responseJson.data.attributes.profile.data.attributes, }, () => {
      this.updateSubCategories();
      this.setState({ profileLoading: false })
    })
  };
  getBusinessProfileFailureCallBack = (responseJson: any) => {
    console.log(
      '@@@ Get Profile Data Failure call Back ========',
      responseJson
    );
  };

  // @@@@ Handle Date of Basic Screen, navigation to next page ===================

  handleAddProfileData = (data: any) => {
    if (this.state.otherBusinessIndustryvisible) {
      data.business_industry = this.state.Other_Business_industry;
    }
    if (data.profile_pic) {
      data.profile_pic = {
        profileUrl: this.state.profileUrl,
        picFile: this.state.file
      };
    }
    if (data.cover_pic) {
      data.cover_pic = {
        backgroundImageUrl: this.state.backgroundImage,
        backgroundImageFile: this.state.backgroundImageFile
      }
    }
    this.props.history.push({
      pathname: '/edit/BusinessProfile/about',
      state: { data, oldData: this.state.updateProfile },
    });
  };

  companyName = (event: any) => {
    this.setState({ value: event.target.company_nane });
  };

  //=========================@@@@ Call Get Api ============================

  getCategories = async () => {
    const header = {
      'Content-Type': 'application/json',
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessCategoryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_categories/categories'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //================ @@@@ Get Profile Get Api===================

  getProfileData = async () => {
    const header = {
      'Content-Type': 'application/json',
      "token": localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessPrfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //====================@@@ Post APi For Upload Image=======================
  uploadProfileImage = () => {
    const header = {
      token: this.state.token,
    };

    const formData = new FormData();
    formData.append("profile_pic", this.state.file);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postUploadProfileImageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/upload_profile_pic"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleFormValuesChange = (changedValues: any) => {
    const formFieldName = Object.keys(changedValues)[0];
    console.log(formFieldName, 'test===')
    // if (formFieldName === "typeCeso") {
    //   setValue(changedValues[formFieldName]);
    // }
  };
  // Customizable Area End
}

 // Customizable Area Start
  // Customizable Area End