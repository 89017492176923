import React from "react";
// Customizable Area Start
import {
  Row,
  Col,
  Image,
  Form,
  Input,
  Typography,
  Button,
  Spin
} from "antd";
import "antd/dist/antd.css";
import {
  iconImage,
  style,
  AppImage,
  AndroidImage,
  iosImage,
} from "./assets";
import ForgotController from "./forgotPasswordController.web";
const { Title, Text, Link } = Typography;
import CountryCodeSelector from "../src/../../country-code-selector/src/CountryCodeSelector.web";
import { AlertMessage } from "../../alert/src/AlertMessage.web";
const emailReg = RegExp(
  /^([a-zA-Z0-9_\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
);
// Customizable Area End
export default class ForgotPassword extends ForgotController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        {this.redirectUser()}
        <Row>
          <Col span={14} xs={0} sm={14} md={12} lg={12} xl={14} xxl={14}>
            {!this.state.forgotPasswordBannerImage ? <Spin /> :
              <Image
                preview={false}
                src={this.state.forgotPasswordBannerImage}
                wrapperStyle={{ height: "100%", width: "100%" }}
                style={{ height: "100%" }}
              />}
          </Col>
          <Col
            span={10}
            xs={24}
            sm={10}
            md={12}
            lg={12}
            xl={10}
            xxl={10}
            className={style.top_padding}
          >
            <Row>
              <Col span={5} />
              <Col span={14}>
                <Row>
                  <Col span={6} />
                  <Col span={12}>
                    <Image
                      preview={false}
                      className={style.logopadding}
                      src={iconImage}
                    />
                  </Col>
                  <Col span={6} />
                </Row>

                <Row className={style.loginText}>
                  <Title className={style.w_100_center} style={{ marginBottom: "0.4rem" }} level={3}>
                    Forgot Password
                  </Title>
                  <Text type="secondary" style={{ textAlign: 'center', fontWeight: 500, marginBottom: "0.5rem" }}>
                    Please enter email or phone number to reset password
                  </Text>
                </Row>
                {/* <br /> */}

                <Form
                  style={{ marginBottom: 15 }}
                  initialValues={{ full_phone_number: "", cc: "+91" }}
                  onFinish={this.handleOtp}
                >
                  <Form.Item
                    rules={[
                      { required: true, message: "Required field" },
                      this.state.isMobile
                        ? {}
                        : //  {
                        //     type: "email",
                        //     message: "Invalid mail",
                        //   }
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || emailReg.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Please Enter Valid Email!")
                            );
                          },
                        }),
                      this.state.isMobile
                        ? {
                          min: 4,
                          max: 15,
                          message: "invalid Phone Number",
                        }
                        : {},
                    ]}
                    name="full_phone_number"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                      }}
                    >
                      {this.state.isMobile && (
                        <CountryCodeSelector
                          id={"CountryCodeSelector"}
                          name="cc"
                          allowPropChange={true}
                          placeHolder={this.state.countryPlaceHolder}
                          disable={false}
                          value={this.state.countryCode}
                          navigation={this.props.navigation}
                        // outerStyle={{
                        //   borderRadius: "50px",
                        //   width: "140px",
                        //   marginRight: 20,
                        // }}
                        />
                      )}
                      <div style={{ width: this.state.isMobile ? "57%" : "100%" }}>
                        <Input
                          className={style.input_fields}
                          name="full_phone_number"
                          placeholder={
                            this.state.isMobile
                              ? "Phone Number"
                              : "Email Address / Phone Number"
                          }
                          type={this.state.isMobile ? "number" : "email"}
                          style={{ borderWidth: "1px", color: "#a9bac8" }}
                          size="large"
                          onChange={({ target: { value } }: { target: any }) => {
                            if (parseInt(value).toString() !== value) {
                              this.setState({ isMobile: false });
                            } else {
                              this.setState({ isMobile: true });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </Form.Item>
                  <Button
                    className={style.btn_colors}
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    size={"large"}
                    style={{ width: "100%" }}
                  >
                    Submit
                  </Button>
                </Form>

                <Row className={style.loginText}>
                  <Link
                    className={style.w_100_center}
                    style={{ fontSize: "16px", color: "#005574", fontWeight: 500 }}
                    href="/"
                  >
                    Back to Log In
                  </Link>
                </Row>
              </Col>
              <Col span={5} />
            </Row>
          </Col>
        </Row>
        <Row style={{ backgroundColor: "#f5f5f5" }}>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={14} xxl={14}>
            <Row
              className={style.app_alignCenter}
              style={{ justifyContent: "flex-end", width: "100%" }}
            >
              <Image
                preview={false}
                src={AppImage}
                style={{ objectFit: "fill" }}
              // wrapperStyle={{ width: "100%" }}
              />
            </Row>
          </Col>
          <Col
            span={12}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={10}
            xxl={10}
          // className={style.app_store}
          >
            <Row
              // className={style.app_alignCenter}
              style={{
                height: "100%",
                alignItems: "center",
                paddingRight: "40px",
                paddingLeft: "40px",
              }}
            >
              <Col span={6} xs={0} sm={6} md={3} lg={3} xl={3} xxl={6} />
              <Col span={10} xs={24} sm={24} md={18} lg={18} xl={18} xxl={10}>
                <Row>
                  <Title level={3}>Available for Android and IOS</Title>
                  <Text
                    type="secondary"
                    className={style.w_100}
                    style={{
                      fontSize: "1.1rem",
                      lineHeight: "1.3rem",
                      paddingBottom: "20px",
                    }}
                  >
                    Download applied app from your Play Store or App Store
                  </Text>
                  <Col span={11}>
                    <Image preview={false} src={AndroidImage} />
                  </Col>
                  <Col span={2} />
                  <Col span={11}>
                    <Image preview={false} src={iosImage} />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={8} sm={8} md={3} lg={3} xl={3} xxl={8} />
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            justifyContent: "center",
            paddingTop: "10px",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          <p>©2021 applied. All rights reserved.</p>
          <a href="/privacy-policy" target="_blank">&nbsp;&nbsp;Privacy Policy&nbsp;&nbsp;</a>
          <a href="/terms-and-conditions" target="_blank">&nbsp;&nbsp;Terms of use</a>
        </Row>
        <AlertMessage
          isModalVisible={this.state.isErrorModalShow}
          title={this.state.errorContent.title}
          body={this.state.errorContent.body}
          isError={this.state.errorContent.isError}
          onOk={() =>
            this.setState({ isErrorModalShow: false, errorContent: {} })
          }
        />
      </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End