import React from "react";

// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Redirect } from "react-router-dom";
import { message, FormInstance } from "antd"
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
export const configJSON = require("./config");
import { ApiDataType } from "./interface";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    isCreateCommunityVisible: boolean,
    isEditCommunityVisible: boolean,
    data?: ApiDataType,
    handleCancel: () => void,
    handleOk: () => void,
    isCreateCommunity: boolean,
    handleCreateAndEditCommunity: (value: number | string) => void,
    // Customizable Area End
}

// Customizable Area Start
interface InputValues {
    profile_pic: string | null,
    cover_pic: string | null,
    name: string,
    topics: string,
    desc: string,
    data_profile_pic?: RcFile | string,
    data_cover_pic?: RcFile | string
}
interface ILoading {
    profile_pic: boolean,
    cover_pic: boolean,
    loadCommunity: boolean
}
// Customizable Area End
interface S {
    // Customizable Area Start
    redirect: boolean,
    inputValue: InputValues,
    token: string,
    loading: ILoading,
    isCreateCommunityVisible: boolean;
    isCreateCommunity: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}



export default class CreateAndEditCommunityController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    createCommunity: string = "";
    formRef = React.createRef<FormInstance<string>>();

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            inputValue: {
                profile_pic: "",
                cover_pic: "",
                name: "",
                topics: "",
                desc: "",
                data_profile_pic: "",
                data_cover_pic: ""
            },
            redirect: false,
            token: "",
            loading: {
                profile_pic: false,
                cover_pic: false,
                loadCommunity: false
            },
            isCreateCommunityVisible: false,
            isCreateCommunity: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        let token = await localStorage.getItem("token");
        if (token && token !== null && token !== undefined) {
            this.setState({ redirect: false, token: token });
        } else {
            this.setState({ redirect: true, token: "" });
        }

        this.redirectUser()
    }
    redirectUser() {
        if (this.state.redirect) {
            return <Redirect to="/" />;
        }
    }
    async componentDidUpdate(prevProps: Props) {
        const { isCreateCommunityVisible, data } = this.props
        if (prevProps.isEditCommunityVisible !== this.props.isEditCommunityVisible ||
            prevProps.isCreateCommunityVisible !== isCreateCommunityVisible
        ) {
            this.resetForm()
            if (data !== undefined) {
                this.setState({
                    inputValue: {
                        ...this.state.inputValue,
                        profile_pic: isCreateCommunityVisible ? "" : data.attributes.profile_pic,
                        cover_pic: isCreateCommunityVisible ? "" : data.attributes.cover_pic,
                        name: isCreateCommunityVisible ? "" : data.attributes.name,
                        topics: isCreateCommunityVisible ? "" : data.attributes.topics,
                        desc: isCreateCommunityVisible ? "" : data.attributes.description
                    }
                })
            } else {
                this.setState({
                    inputValue: {
                        ...this.state.inputValue,
                        profile_pic: "",
                        cover_pic: "",
                        name: "",
                        topics: "",
                        desc: ""
                    }
                })
            }
        }
    }


    createEditOkModal = () => {
        this.props.handleOk()
        this.resetForm()
    }

    createEditCancel = () => {
        this.props.handleCancel()
        this.resetForm()
    }

    resetForm = () => {
        this.formRef.current?.resetFields()
        this.setState({
            inputValue: {
                data_profile_pic: "",
                data_cover_pic: "",
                profile_pic: "",
                cover_pic: "",
                name: "",
                topics: "",
                desc: ""
            }
        })
    }

    getBase64 = (image: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(image);
    };

    beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        } else {

        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    handleAvatarChange = (info: (UploadChangeParam<UploadFile>)) => {
        const { loading, inputValue } = this.state
        if (info.file?.status === 'uploading') {
            this.setState({
                loading: {
                    ...loading,
                    profile_pic: true
                }
            });
            return;
        }
        if (info.file?.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file?.originFileObj as RcFile, (url) => {
                this.setState({
                    loading: {
                        ...loading,
                        cover_pic: false
                    }
                });
                this.setState({
                    inputValue: {
                        ...inputValue, profile_pic: url, data_profile_pic: info.file.originFileObj
                    }
                });
            });
        }
        if (info.file?.status === "error") {
            this.getBase64(info.file?.originFileObj as RcFile, (url) => {
                this.setState({
                    loading: {
                        ...loading,
                        cover_pic: false
                    }
                });
                this.setState({
                    inputValue: {
                        ...inputValue, profile_pic: url, data_profile_pic: info.file.originFileObj
                    }
                });
            });
        }
    };

    handleCoverPicChange = (info: (UploadChangeParam<UploadFile>)) => {
        const { loading, inputValue } = this.state
        if (info.file?.status === 'uploading') {
            this.setState({
                loading: {
                    ...loading,
                    cover_pic: true
                }
            });
            return;
        }
        if (info.file?.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file?.originFileObj as RcFile, (url) => {
                this.setState({
                    loading: {
                        ...loading,
                        cover_pic: false
                    }
                })
                this.setState({
                    inputValue: {
                        ...inputValue, cover_pic: url, data_cover_pic: info.file.originFileObj
                    }
                });
            });

        }
        if (info.file?.status === "error") {
            this.getBase64(info.file?.originFileObj as RcFile, (url) => {
                this.setState({
                    loading: {
                        ...loading,
                        cover_pic: false
                    }
                })
                this.setState({
                    inputValue: {
                        ...inputValue, cover_pic: url, data_cover_pic: info.file.originFileObj
                    }
                });
            });
        }
    };

    createAndEditCommunity = async () => {
        const { loading, inputValue } = this.state
        const { isCreateCommunity, data } = this.props
        this.setState({
            loading: { ...loading, loadCommunity: true }
        })
        let formdata = new FormData();
        if (isCreateCommunity) {
            formdata.append("profile_pic", inputValue.data_profile_pic || "");
            formdata.append("cover_pic", inputValue.data_cover_pic || "");
        } else {

            if (inputValue.profile_pic !== data?.attributes?.profile_pic) {
                formdata.append("profile_pic", inputValue?.data_profile_pic || "");
            }
            if (inputValue.cover_pic !== data?.attributes?.cover_pic) {
                formdata.append("cover_pic", inputValue?.data_cover_pic || "");
            }
            formdata.append("post_request", "true");
            formdata.append("join_request", "true");
        }
        formdata.append("name", inputValue.name.trim());
        formdata.append("topics", inputValue.topics.trim());
        formdata.append("description", inputValue.desc.trim());

        const token = localStorage.getItem("token");
        const header = {
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createCommunity = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            isCreateCommunity ? `${configJSON.createCommunityEndPoint}` :
                `${configJSON.EditCommunityEndPoint}/${data?.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            isCreateCommunity ? configJSON.createCommunityPostApiMethodType :
                configJSON.createCommunityPutApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Customizable Area End

    async receive(_from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            if (apiRequestCallId && responseJson && !responseJson?.errors) {
                if (apiRequestCallId == this.createCommunity) {
                    if (responseJson.data) {
                        this.setState({
                            loading: { ...this.state.loading, loadCommunity: false }
                        })
                        await this.props.handleCreateAndEditCommunity(responseJson.data.attributes.id)
                        await this.resetForm()
                    } else {
                        this.setState({
                            loading: { ...this.state.loading, loadCommunity: false }
                        })
                        this.resetForm()
                    }
                }
            }
        } else {
            this.resetForm()
        }
        // Customizable Area End
    }

}
