import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Spin,
  DatePicker,
  Steps,
  Popover,
  Space,
  Select,
  Avatar,
  Upload,
  message,
  Divider,
} from "antd";
import { logo, style, NETWORK_PROFILE_ING } from "../../assets";
const { Text, Link, Title } = Typography;
import {
  UserOutlined,
  EnvironmentOutlined,
  CloseCircleOutlined,
  CheckCircleTwoTone,
  PlusCircleFilled,
} from "@ant-design/icons";
import ContactusController from "../../../../contactus/src/ContactusController";
// Customizable Area End

const ViewInvitation = (props: any) => {
  // Customizable Area Start
  console.log("get all props", props);
  const ViewInvitationEnvironmentOutlinedicon = ()=>{
    return <EnvironmentOutlined translate={{}} rev={{}} />
  }
  // Customizable Area End

  // Customizable Area Start
  return (
    <Row
      style={{
        minHeight: "18vh",
        padding: "0 15px 15px 15px",
        // backgroundColor: "#eff8fe",
      }}
    >
      {/* {props.userList.map((item: any, index: any) => {
        console.log("@@@@Invitation item data", item);
        return ( */}
      <Col xs={0} sm={0} md={3} lg={4} xl={4}>
        <Card hoverable className="padding-card-network">
          <Row justify="center">
            {/* <Avatar size={64} src={item.attributes.photo} /> */}
            <Avatar size={64} src={NETWORK_PROFILE_ING} />
          </Row>
          <Row justify="center" style={{ paddingTop: "12px" }}>
            <Text
              style={{ fontSize: 14, fontWeight: "bold", alignItems: "center" }}
            >
              {/* {item.attributes.full_name}{" "} */}
              Anje Keizer
            </Text>
            <br />
            <p
              style={{
                width: "100%",
                marginBottom: 0,
                fontSize: 12,
                textAlign: "center",
              }}
            >
              Sr. Python Developer <Text type="secondary"> at </Text> Google
            </p>
            <br />
            <Text
              style={{
                width: "100%",
                marginBottom: 0,
                fontSize: 12,
                textAlign: "center",
              }}
              type="secondary"
            >
              {ViewInvitationEnvironmentOutlinedicon()}
              San Diego, Callifornia, US
            </Text>
          </Row>
          <Divider />
          <Row gutter={5} style={{ alignItems: "center" }}>
            <Col span={12}>
              <Button
                className={style.skip_add_btn}
                type="primary"
                shape="round"
                size={"small"}
              >
                View Profile
              </Button>
            </Col>
            <Col span={4} />
            <Col span={4} className="network-button-cancel">
              <Button
                type="primary"
                shape="circle"
                size={"small"}
                icon={
                  <CloseCircleOutlined
                    translate={{}}
                    style={{ fontSize: "20px" }}
                    twoToneColor="#52c41a"
                    rev={{}}
                  />
                }
              />
            </Col>
            {/* <Col span={1}></Col> */}
            <Col span={4} className="network-button-success">
              <Button
                type="primary"
                shape="circle"
                size={"small"}
                icon={
                  <CheckCircleTwoTone
                    translate={{}}
                    style={{ fontSize: "20px" }}
                    twoToneColor="#52c41a"
                    rev={{}}
                  />
                }
              />
            </Col>
          </Row>
        </Card>
      </Col>
      {/* ); */}
      {/* })} */}
    </Row>
  );
  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
};
// Customizable Area Start
export default ViewInvitation;
// Customizable Area End
