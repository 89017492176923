import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Spin,
  Space,
  Select,
  Avatar,
  Upload,
  message,
  Image
} from "antd";
const { Title } = Typography;
import { logo, NETWORK_PROFILE_ING } from "../assets";
import { PlusCircleFilled } from "@ant-design/icons";
const style = require(`../../../social-media-account/src/login.module.css`);
import "../profile.css";

import CreateProfileBasicControllerV2 from "../ProfileSetupBasicControllerV2.web";
import { Colors } from "react-native/Libraries/NewAppScreen";
import { lighten } from "@material-ui/core";
import AimOutlined from "@ant-design/icons/lib/icons/AimOutlined";
import CropModal from "../CommonUI/CropModal.web";
const { Option } = Select;
// Customizable Area End
export default class ProfileSetupBasicBusinessV2 extends CreateProfileBasicControllerV2 {
  // Customizable Area Start
  renderLocationInput = () => {
    return (
      <Form.Item shouldUpdate>
        {() => {
          return (
            <Form.Item rules={[
              { required: true, message: "Required field" },
            ]}
              name="location">
              <Input
                placeholder="Location"
                className={style.input_fields}
                onChange={event => {
                  const value = event.target.value;
                  setTimeout(() => {
                    this.profile_formref?.current?.setFieldsValue({
                      location: value
                    });
                  }, 0);
                }}
                size="large"
                style={{ color: "#a9bac8", borderWidth: 1 }}
                suffix={
                  <AimOutlined
                    style={{ color: "#005574" }}
                    translate={{}}
                    rev={{}}
                    onClick={() => this.getLocation()}
                  />
                }
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    )
  }
  renderSubCategory = () => {
    return (
      <Form.Item
        rules={[
          {
            required: true,
            message: "Required field"
          }
        ]}
        style={{ borderWidth: 1 }}
        name="business_industry"
      >
        <Select
          data-testid='businessIndustry'
          id={"select"}
          style={{
            padding: "0 !important",
            borderRadius: '25px',
            border: '1px solid #a9bac8',
            color: "#a9bac8",
            overflow: "hidden"
          }}
          placeholder="Business Industry / Category"
          size="large"
          bordered={true}
          dropdownStyle={{
            borderRadius: "15px",
            border: "1px solid #a9bac8",
            paddingTop: "0px",
            paddingBottom: "0px"
          }}
          notFoundContent={
            this.state.loadSubCategory ? (
              <Spin size="small" />
            ) : (
              "No Data Found "
            )
          }
          onChange={this.handleChange}
          onSelect={(value) => {
            console.log("val", value);
            if (value == "Other") {
              this.setState({ otherBusinessIndustryVisible: true });
            } else {
              this.setState({ otherBusinessIndustryVisible: false });
            }
          }}
        >
          {this.state.sub_category &&
            this.state.sub_category.map((item: any) => {
              return (
                <option
                  style={{
                    borderBottom: "1px solid #a9bac8",
                    padding: "10px 20px 10px 20px"
                  }}
                  value={item.name}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%"
                    }}
                  >
                    <div style={{ color: "#a9bac8" }}>{item.name}</div>
                  </div>
                </option>
              );
            })}
        </Select>
      </Form.Item>
    )
  }
  renderOtherBusinessIndustry = () => {
    return (
      <Form.Item
      >
        <Input
          className={style.input_fields}
          autoFocus
          style={{ borderWidth: 1, color: "#a9bac8" }}
          placeholder="Other Business Industry / Category"
          size="large"
          onChange={(e: any) => {
            this.setState(
              {
                Other_Business_Industry: e.target.value
              }
            )
          }}
        />
      </Form.Item>
    )
  }
  renderNoOfEmployee = () => {
    return (
      this.state.noOfEmployee.map((item: any) => {
        return (
          <option
            style={{
              borderBottom: "1px solid #a9bac8",
              padding: "10px 20px 10px 20px"
            }}
            value={item.name}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <div style={{ color: "#a9bac8" }}>{item.name}</div>
            </div>
          </option>
        );
      })
    )
  }
  renderYeraList = () => {
    return (
      this.state.yearList.map((item: number) => {
        return (
          <option
            style={{
              borderBottom: "1px solid #a9bac8",
              padding: "10px 20px 10px 20px"
            }}
            value={item}
            key={item}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <div style={{ color: "#a9bac8" }}>{item}</div>
            </div>
          </option>
        );
      })
    )
  }
  renderProfile = () => {
    return (
      <>
        <Avatar size={64} src={NETWORK_PROFILE_ING} />
        <Avatar
          style={{
            top: "40px",
            left: "45px",
            justifyContent: "center",
            alignItems: "center",
            position: 'absolute'
          }}
          size={20}
          icon={
            <PlusCircleFilled
              translate={{}}
              rev={{}}
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#005574"
              }}
            />
          }
        />
      </>
    )
  }
  handleBackgroundImage = () => {
    return (
      this.state.backgroundImage ? (
        <Image
          height="270px"
          width="100%"
          style={{ borderTopRightRadius: "5px", borderTopLeftRadius: "5px", objectFit: "cover" }}
          src={this.state.backgroundImage}
        />
      ) : (
        <Image
          width="100%"
          height="270px"
          style={{ borderTopRightRadius: "5px", borderTopLeftRadius: "5px", objectFit: "cover" }}
          src={this.state.prev_data?.cover_photo}
        />
      )
    )
  }
  renderForm = (initialValuesData: any) => {
    return (
      <Form
        data-testid='addFormData'
        initialValues={initialValuesData}
        ref={this.profile_formref}
        onFinish={this.handleAddProfileData}
      >
        <Card>
          <Form.Item
            name="cover_pic"
          >
            <Col>
              <div className="cover-img"
                style={{
                  height: "270px",
                  width: "100%",
                  marginBottom: '40px',
                }}>
                {
                  this.state.backgroundImage || this.state.prev_data?.cover_photo ? (
                    this.handleBackgroundImage()
                  )
                    :
                    <Upload
                      className={style.background_Image}
                      style={{ margin: '5px' }}
                      name="backgroundImage"
                      listType="picture-card"
                      data-testid="backgroundImageEdit"
                      multiple={false}
                      showUploadList={false}
                      beforeUpload={(file) => this.uploadBackgroundImage(file)}
                      onChange={(info) => {
                        console.log("infoinfo", info);

                        this.setState({
                          backgroundImageFile: info.file.originFileObj
                        })
                      }
                      }
                    >
                      <div
                        style={{
                          width: "100%",
                          display: 'flex',
                          height: "100%",
                          justifyContent: 'center',
                          alignItems: "center",
                          borderRadius: '7px',
                          border: '2px solid #a9bac8 ',
                        }}
                      >
                        <p style={{ fontSize: 15, color: "#a9bac8" }}>
                          + Add Background Image
                        </p>
                      </div>
                    </Upload>
                }
                <span style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                  top: ' 235px',
                  height: "60px",
                  borderRadius: '50%',
                  left: '25px',
                  width: "60px",
                }}>
                  <Form.Item
                    rules={[{ required: true, message: "Required field" }]}
                    name="profile_pic"
                  >
                    <Upload
                      data-testid='uploadImage'
                      name="file"
                      showUploadList={false}
                      multiple={false}
                      beforeUpload={file => this.beforeUpload(file)}
                      onChange={this.handleImageChange}
                      onRemove={() => {
                        this.setState({ profileUrl: "" });
                      }}
                    >
                      {this.state.profileUrl ? (
                        <Avatar size={64} src={this.state.profileUrl} />
                      ) : (
                        this.renderProfile()
                      )}
                    </Upload>
                  </Form.Item>
                </span>
                <Upload
                  data-testid='UploadImg'
                  name="file"
                  className="edit-cover-icon"
                  showUploadList={false}
                  multiple={false}
                  beforeUpload={(file) => this.uploadBackgroundImage(file)}
                  onChange={(info) => {
                    console.log("infoinfo", info);

                    this.setState({
                      backgroundImageFile: info.file.originFileObj
                    })
                  }
                  }
                >
                  <Avatar
                    style={{
                      left: "-20px",
                      alignItems: "center",
                      justifyContent: "center",
                      position: 'absolute',
                      top: "0px",
                    }}
                    size={20}
                    icon={
                      <PlusCircleFilled
                        translate={{}}
                        rev={{}}
                        style={{
                          fontSize: "20px",
                          color: "#005574",
                          fontWeight: "bold",
                        }}
                      />
                    }
                  />
                </Upload>
              </div>
            </Col>
          </Form.Item>
          <div
            style={{
              marginBottom: "16px",
              justifyContent: "center",
              display: "flex",
            }}
          >
          </div>
          <Row gutter={12}>
            <Col
              span={12}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Required field" },
                  {
                    pattern: new RegExp(/^[a-zA-Z ]+$/i),
                    message: "input accept only alphabets"
                  }
                ]}
                name="company_name"
              >
                <Input
                  className={style.input_fields}
                  placeholder="Company Name"
                  style={{ borderWidth: 1, color: "#a9bac8" }}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col
              span={12}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Required field" }
                ]}
                name="business"
              >
                <Select
                  id={"select"}
                  data-testid='BusinessSelect'
                  style={{ padding: "0 !important", borderRadius: '25px', border: '1px solid #a9bac8', color: "#a9bac8", overflow: "hidden" }}
                  placeholder="Business"
                  bordered={true}
                  size="large"
                  dropdownStyle={{
                    borderRadius: "15px",
                    border: "1px solid #a9bac8",
                    paddingTop: "0px",
                    paddingBottom: "0px"
                  }}
                  notFoundContent={
                    this.state.loading ? (
                      <Spin size="small" />
                    ) : (
                      "No Data Found "
                    )
                  }
                  onChange={this.handleSubCategory}
                >
                  {this.state.businessIndustryList.map(
                    (item: any, index: any) => {
                      return (
                        <option
                          style={{
                            borderBottom: "1px solid #a9bac8",
                            padding: "10px 20px 10px 20px"
                          }}
                          key={index}
                          value={item.name}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%"
                            }}
                          >
                            <div style={{ color: "#a9bac8" }}>{item.name}</div>
                          </div>
                        </option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col
              span={12}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              {this.state.loadSubCategory ? (
                <Spin />
              ) : (
                this.renderSubCategory()
              )}
              {this.state.otherBusinessIndustryVisible && this.renderOtherBusinessIndustry()
              }
            </Col>
            <Col
              span={12}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Required field" }
                ]}
                name="ownership_type"
              >
                <Select
                  placeholder="Ownership Type"
                  style={{
                    padding: "0 !important",
                    borderRadius: '25px',
                    border: '1px solid #a9bac8',
                    color: "#a9bac8",
                    overflow: "hidden"
                  }}
                  allowClear
                  size="large"
                  bordered={true}
                  dropdownStyle={{
                    borderRadius: "15px",
                    border: "1px solid #a9bac8",
                    paddingTop: "0px",
                    paddingBottom: "0px"
                  }}
                  onChange={this.handleChange}
                >
                  {this.state.businessOwnership.map(
                    (item: any, index: any) => {
                      return (
                        <option
                          style={{
                            borderBottom: "1px solid #a9bac8",
                            padding: "10px 20px 10px 20px"
                          }}
                          key={index}
                          value={item.name}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%"
                            }}
                          >
                            <div style={{ color: "#a9bac8" }}>{item.name}</div>
                          </div>
                        </option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col
              span={12}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Required field"
                  }
                ]}
                style={{ borderWidth: 1 }}
                name="established_year"
              >
                <Select
                  id={"select"}
                  style={{
                    padding: "0 !important",
                    borderRadius: '25px',
                    border: '1px solid #a9bac8',
                    color: "#a9bac8",
                    overflow: "hidden"
                  }}
                  placeholder="Established Year"
                  bordered={true}
                  size="large"
                  dropdownStyle={{
                    borderRadius: "15px",
                    border: "1px solid #a9bac8",
                    paddingTop: "0px",
                    paddingBottom: "0px"
                  }}
                >
                  {this.renderYeraList()}
                </Select>
              </Form.Item>
            </Col>
            <Col
              span={12}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Required field" },
                  {
                    pattern: new RegExp(/^[a-zA-Z ]+$/i),
                    message: "input accept only alphabets"
                  }
                ]}
                name="founder"
              >
                <Input
                  className={style.input_fields}
                  placeholder="Founder"
                  style={{ borderWidth: 1, color: "#a9bac8" }}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col
              span={12}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Form.Item
                data-testid='contactPerson'
                rules={[
                  {
                    required: true,
                    message: "Required field"
                  }
                ]}
                name="contact_person"
              >
                <Input
                  type={"text"}
                  className={style.input_fields}
                  placeholder="Contact Person"
                  style={{ borderWidth: 1, color: "#a9bac8" }}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col
              span={12}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Form.Item
                rules={[
                  { required: true, message: "Required field" }
                ]}
                name="num_of_employees"
                style={{ borderWidth: 1 }}
              >
                <Select
                  id={"select"}
                  bordered={true}
                  dropdownStyle={{
                    borderRadius: "15px",
                    border: "1px solid #a9bac8",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    color: "#a9bac8"
                  }}
                  style={{
                    padding: "0 !important",
                    borderWidth: "0 !important", borderRadius: '25px',
                    border: '1px solid #a9bac8',
                    color: "#a9bac8",
                    overflow: "hidden"
                  }}
                  size="large"
                  placeholder="Number of Employees"
                  onChange={this.handleChange}
                  value={this.state.selectedNoOfEmployee}
                >
                  {this.renderNoOfEmployee()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col
              span={12}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              {this.state.loadLocation ? (
                <Spin />
              ) : (
                this.renderLocationInput()
              )}
            </Col>
            <Col
              span={12}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <Form.Item
                rules={[
                  {
                    message: "eg: http://www.sample.com or https://www.sample.com/xyz",
                    pattern: new RegExp(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)
                  },
                ]}
                name="website"
              >
                <Input
                  className={style.input_fields}
                  placeholder="Website"
                  style={{ borderWidth: 1, color: "#a9bac8" }}
                  size="large"
                  type={"url"}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "15px",
            background: "#f8f8f8"
          }}
        >
          <Button
            className={style.btn_colors}
            type="primary"
            htmlType="submit"
            shape="round"
            size={"large"}
          >
            Next
          </Button>
        </div>
      </Form>
    )
  }
  renderBusnissProfileV2 = () => {
    const industry_type = this.state.data?.industry_type
      ? this.state.data.industry_type
      : null
    const established_year = this.state.prev_data.established_year
      ? this.state.prev_data.established_year
      : null
    const initialValuesData = {
      company_name: this.state.prev_data.company_name
        ? this.state.prev_data.company_name
        : "",
      business: this.state.prev_data.business
        ? this.state.prev_data.business
        : industry_type,
      business_industry: this.state.prev_data.business_industry
        ? this.state.prev_data.business_industry
        : null,
      ownership_type: this.state.prev_data.ownership_type
        ? this.state.prev_data.ownership_type
        : null,
      established_year:
        this.state.prev_data && established_year,
      founder: this.state.prev_data.founder
        ? this.state.prev_data.founder
        : "",
      contact_person: this.state.prev_data.contact_person
        ? this.state.prev_data.contact_person
        : "",
      num_of_employees: this.state.prev_data.num_of_employees
        ? this.state.prev_data.num_of_employees
        : null,
      location: this.state.prev_data.location
        ? this.state.prev_data.location
        : '',
      website: this.state.prev_data.website
        ? this.state.prev_data.website
        : "",
      profile_pic: this.state.profileUrl
        ? this.state.profileUrl
        : ""
    }
    return (
      <div>
        {this.redirectUser()}
        <PageHeader
          style={{
            color: "white",
            backgroundImage: "linear-gradient(180deg, rgba(60,96,134,1) 0%, rgba(53,135,196,1) 100%)"
          }}
          className="site-page-header"
          title={
            <span style={{ color: "white" }}>
              <img style={{ marginRight: "8px", height: "32px" }} src={logo} />
              applied
            </span>
          }
        />
        <Row
          style={{
            backgroundColor: "#eff8fe",
            alignItems: "center",
            minHeight: "90vh"
          }}
        >
          <Col xs={0} sm={0} md={3} lg={6} xl={4} />
          <Col xs={23} sm={22} md={18} lg={15} xl={17}>
            <Title
              style={{
                marginTop: "50px",
                marginBottom: "15px",
                textAlign: "center"
              }}
              level={2}
            >
              Setup Your Business Profile
            </Title>
            <Col className="basic_profile_card" span={24} offset={1}>
              {this.state.loading ? (
                <Spin />
              ) : (this.renderForm(initialValuesData))}
            </Col>
            <Col xs={0} sm={0} md={3} lg={7} xl={8} />
          </Col>
        </Row>
      </div>
    );
  };
  renderCropModal = () => {
    return (<>
      <CropModal
        history={this.props.history}
        openCropModal={this.state.openCropModal}
        setCropModal={() => {
          this.setState({
            openCropModal: false
          })
        }}
        setCroppedImageUrl={(file) => {
          this.setState({
            profileUrl: file.url,
            openCropModal: false,
            file: file.pic
          })
        }}
        data-testid="CropModal"
        src={this.state.copyProfileUrl}
      />
    </>)
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return <div>
      {this.renderBusnissProfileV2()}
      {this.renderCropModal()}
    </div>;
    // Customizable Area End
  }

}
// Customizable Area Start
// Customizable Area End