import React from "react"
// Customizable Area Start
import ReactCrop from "react-image-crop";
import { Button, Modal, Row, Col } from 'antd';
import CropModalController from "./CropModalController.web"
// Customizable Area End

export default class CropModal extends CropModalController {
    // Customizable Area Start 
    // Customizable Area End
    render() {
        // Customizable Area Start 
        return (
            <Modal
                data-testid="CropModal"
                className="Logout_Popup"
                style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden", height: '500px' }}
                centered
                title={
                    <div
                        style={{
                            width: "100%",
                            cursor: "move",
                            fontWeight: "bold",
                            borderWidth: "0px"
                        }}
                    // end
                    >
                        Crop Picture
                    </div>
                }
                visible={this.props.openCropModal}
                // onOk={onOk}
                footer={[
                    <Row key="Crop" data-testid="CropFooteRow">
                        <Col span={24}>
                            <Button
                                size="large"
                                className="crop_ok_btn"
                                key="report"
                                data-testid="OkButton"
                                onClick={() => this.onCropComplete(this.state.crop)}
                            >
                                Ok
                            </Button>
                        </Col>
                    </Row>
                ]}
            >
                <div className="crop_modal_content">
                    <ReactCrop
                        data-testid="ReactCrop"
                        src={this.props.src}
                        crop={this.state.crop}
                        onImageLoaded={this.onImageLoaded}
                        onChange={this.onCropChange}
                    />

                </div>
            </Modal>
        )
        // Customizable Area Endt
    }
}
// Customizable Area Start
// Customizable Area End