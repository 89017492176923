import React, { Component } from 'react';
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Modal,
  Select
} from 'antd';
const { Title, Text, Link } = Typography;
import { logo } from '../assets';
const style = require(`../../../social-media-account/src/login.module.css`);
import EditBusinessHistoryController from '../EditBusinessHistoryController.web';
import '../profile.css';
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
// Customizable Area End
export default class EditBusinessProfileHistory extends EditBusinessHistoryController {
  // Customizable Area Start
  renderCloseEditBusinessProfileHistory = () => {
    return <CloseOutlined rev={{}} translate={{}} />
  }
  renderCloseEditBusinessProfileExpansion = () => {
    return <CloseOutlined rev={{}} translate={{}} />
  }
  renderCloseEditBusinessProfileCoreTeam = () => {
    return <CloseOutlined rev={{}} translate={{}} />
  }
  renderCloseEditBusinessProfileClient = () => {
    return <CloseOutlined rev={{}} translate={{}} />
  }
  renderCloseModal = () => {
    return (
      <Modal
        data-testid='deletepopup'
        className="delete_popup"
        title="Business Profile"
        visible={this.state.isModalVisible}
        onCancel={() =>
          this.setState({
            isModalVisible: false
          })
        }
        footer={null}
        style={{ borderRadius: "0.5rem" }}
      >
        <p>
          Want to Delete {this.state.modalString} {this.state.modalIndex + 1} ?
        </p>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "0.5rem",
            paddingBottom: "1rem"
          }}
        >
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              data-testid='ModalVisible'
              className={style.btn_colors2}
              type="primary"
              shape="round"
              size={"large"}
              style={{ width: "200px" }}
              onClick={() => {
                this.setState({
                  isModalVisible: false
                });
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              data-testid='deleteData'
              className={style.btn_danger}
              type="primary"
              shape="round"
              size={"large"}
              danger
              style={{ width: "200px" }}
              onClick={() => {
                this.deleteData(this.state.modalIndex);
              }}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { all_data } = this.state;

    return (
      <div style={{ paddingBottom: "5%" }}>
        {this.renderCloseModal()}
        {this.alertModal()}
        {this.errorAlertModal()}
        {this.redirectUser()}

        <Row
          style={{
            minHeight: "90vh",
            height: "100%",
            alignItems: "center",
            backgroundColor: "#eff8fe"
          }}
        >
          <Col xs={0} sm={0} md={3} lg={6} xl={4} />
          <Col xs={23} sm={22} md={18} lg={15} xl={17}>
            <Title
              level={2}
              style={{
                textAlign: "center",
                marginBottom: "10px",
                marginTop: "50px"
              }}
            >
              Edit Your Business Profile
            </Title>
            <Col span={24} offset={1} className="basic_profile_card">
              <Form onFinish={this.handleHistoryData} ref={this.year_formref}>
                {all_data.history && all_data.history.length > 0
                  ? all_data.history.map((item: any, index: any) => {
                    console.log(item)
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "#e0eaf4",
                            minHeight: "35px"
                          }}
                        >
                          <Title
                            level={4}
                            style={{
                              marginLeft: "15px",
                              marginTop: "10px",
                              color: "steelblue",
                              height: "100%",
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            History {index + 1}
                          </Title>
                          <div
                            data-testid='showModalHistory'
                            style={{
                              display: index === 0 ? "none" : "block",
                              cursor: "pointer",
                              padding: "1rem"
                            }}
                            onClick={() => this.showModal(index, "History")}
                          >
                            {this.renderCloseEditBusinessProfileHistory()}
                          </div>
                        </div>
                        <Card>
                          <div>
                            <Row gutter={12}>
                              <Col
                                span={12}
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required field"
                                    }
                                  ]}
                                  style={{ borderWidth: 1 }}
                                // name="established_year"
                                >
                                  <Select
                                    id={"select"}
                                    // style={{
                                    //   padding: "0 !important",
                                    //   borderRadius: '15px'
                                    // }}
                                    data-testid='selectHistoryYear'
                                    className={style.input_fields_select}
                                    value={item?.year || undefined}
                                    placeholder="Year"
                                    bordered={true}
                                    dropdownStyle={{
                                      borderRadius: "15px",
                                      border: "1px solid #7aacd6",
                                      paddingTop: "0px",
                                      paddingBottom: "0px"
                                    }}
                                    onChange={e =>
                                      this.handleInputChange(
                                        e,
                                        index,
                                        "History"
                                      )
                                    }
                                  >
                                    {this.state.yearList.map((item: number) => {
                                      return (
                                        <option
                                          style={{
                                            borderBottom: "1px solid #7aacd6",
                                            padding: "10px 20px 10px 20px"
                                          }}
                                          value={item}
                                          key={item}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              width: "100%"
                                            }}
                                          >
                                            <div>{item}</div>
                                          </div>
                                        </option>
                                      );
                                    })}
                                  </Select>
                                  {/* <Input
                                    className={style.input_fields}
                                    name="year"
                                    placeholder="Year"
                                    style={{ borderWidth: 1 }}
                                    value={item.year}
                                    onChange={e =>
                                      this.handleInputChange(
                                        e,
                                        index,
                                        "History"
                                      )
                                    }
                                  /> */}
                                </Form.Item>
                              </Col>
                              <Col
                                span={12}
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required field"
                                    }
                                  ]}
                                >
                                  <Input
                                    className={style.input_fields}
                                    data-testid='DescriptionHistory'
                                    name="description"
                                    placeholder="Description"
                                    style={{ borderWidth: 1 }}
                                    value={item?.description}
                                    onChange={e =>
                                      this.handleInputChange(
                                        e,
                                        index,
                                        "History"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Col
                              xs={24}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            >
                              <Button
                                className={style.experience_add_btn}
                                type="primary"
                                shape="round"
                                htmlType="submit"
                                size={"large"}
                                style={{
                                  display:
                                    index === all_data.history.length - 1
                                      ? "block"
                                      : "none",
                                  width: "100%",
                                  backgroundColor: "white",
                                  borderColor: "#d9d9d9",
                                  overflow: "hidden"
                                }}
                              >
                                Add Additional History
                              </Button>
                            </Col>
                          </div>
                        </Card>
                      </div>
                    );
                  })
                  : ""}
              </Form>
              <Form
                onFinish={this.handleExpansionData}
                ref={this.Expansion_formref}
              >
                {all_data.expansion && all_data.expansion.length > 0
                  ? all_data.expansion.map((item: any, index: any) => {
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "#e0eaf4",
                            minHeight: "35px"
                          }}
                        >
                          <Title
                            level={4}
                            style={{
                              marginLeft: "15px",
                              marginTop: "10px",
                              color: "steelblue",
                              height: "100%",
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            Expansion / Mergers & Acquisitions {index + 1}
                          </Title>
                          <div
                            data-testid='showExpansionModal'
                            style={{
                              display: index === 0 ? "none" : "block",
                              cursor: "pointer",
                              padding: "1rem"
                            }}
                            onClick={() => this.showModal(index, "Expansion")}
                          >
                          {this.renderCloseEditBusinessProfileExpansion()}
                          </div>
                        </div>
                        <Card>
                          <div>
                            <Row gutter={12}>
                              <Col
                                span={12}
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required field"
                                    }
                                  ]}
                                  style={{ borderWidth: 1 }}
                                // name="established_year"
                                >
                                  <Select
                                    id={"select"}
                                    // style={{
                                    //   padding: "0 !important",
                                    //   borderRadius: '15px'
                                    // }}
                                    data-testid='selectExpansionYear'
                                    className={style.input_fields_select}
                                    placeholder="Year"
                                    bordered={true}
                                    dropdownStyle={{
                                      borderRadius: "15px",
                                      border: "1px solid #7aacd6",
                                      paddingTop: "0px",
                                      paddingBottom: "0px"
                                    }}
                                    value={item?.year || undefined}
                                    onChange={e =>
                                      this.handleInputChange(
                                        e,
                                        index,
                                        "Expansion"
                                      )
                                    }
                                  >
                                    {this.state.yearList.map((item: number) => {
                                      return (
                                        <option
                                          style={{
                                            borderBottom: "1px solid #7aacd6",
                                            padding: "10px 20px 10px 20px"
                                          }}
                                          value={item}
                                          key={item}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              width: "100%"
                                            }}
                                          >
                                            <div>{item}</div>
                                          </div>
                                        </option>
                                      );
                                    })}
                                  </Select>
                                  {/* <Input
                                    className={style.input_fields}
                                    name="year"
                                    placeholder="Year"
                                    style={{ borderWidth: 1 }}
                                    value={item.year}
                                    onChange={e =>
                                      this.handleInputChange(
                                        e,
                                        index,
                                        "Expansion"
                                      )
                                    }
                                  /> */}
                                </Form.Item>
                              </Col>
                              <Col
                                span={12}
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required field"
                                    }
                                  ]}
                                >
                                  <Input
                                    className={style.input_fields}
                                    data-testid='DescriptionExpansion'
                                    name="description"
                                    placeholder="Description"
                                    style={{ borderWidth: 1 }}
                                    value={item.description}
                                    onChange={e =>
                                      this.handleInputChange(
                                        e,
                                        index,
                                        "Expansion"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Col
                              xs={24}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            >
                              <Button
                                className={style.experience_add_btn}
                                type="primary"
                                shape="round"
                                htmlType="submit"
                                size={"large"}
                                style={{
                                  display:
                                    index === all_data.expansion.length - 1
                                      ? "block"
                                      : "none",
                                  width: "100%",
                                  backgroundColor: "white",
                                  borderColor: "#d9d9d9",
                                  overflow: "hidden"
                                }}
                              >
                                Add Additional Expansion
                              </Button>
                            </Col>
                          </div>
                        </Card>
                      </div>
                    );
                  })
                  : ""}
              </Form>

              <Form
                onFinish={this.handleCoreTeamData}
                ref={this.Core_Teams_formref}
              >
                {all_data.core_team && all_data.core_team.length > 0
                  ? all_data.core_team.map((item: any, index: any) => {
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "#e0eaf4",
                            minHeight: "35px"
                          }}
                        >
                          <Title
                            level={4}
                            style={{
                              marginLeft: "15px",
                              marginTop: "10px",
                              color: "steelblue",
                              height: "100%",
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            Core Teams&nbsp;
                            {index + 1}
                          </Title>
                          <div
                            data-testid='showModalCoreTeam'
                            style={{
                              display: index === 0 ? "none" : "block",
                              cursor: "pointer",
                              padding: "1rem"
                            }}
                            onClick={() => this.showModal(index, "Core Team")}
                          >
                          {this.renderCloseEditBusinessProfileCoreTeam()}
                          </div>
                        </div>
                        <Card>
                          <div>
                            <Row gutter={12}>
                              <Col
                                span={12}
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required field"
                                    }
                                  ]}
                                >
                                  <Input
                                    className={style.input_fields}
                                    data-testid='TeamMemberName'
                                    name="name"
                                    placeholder="Team Member Name"
                                    style={{ borderWidth: 1 }}
                                    value={item.name}
                                    onChange={e =>
                                      this.handleInputChange(
                                        e,
                                        index,
                                        "Core Team"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col
                                span={12}
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required field"
                                    }
                                  ]}
                                >
                                  <Input
                                    className={style.input_fields}
                                    data-testid='coreTeamDesignation'
                                    name="designation"
                                    placeholder="Designation"
                                    style={{ borderWidth: 1 }}
                                    value={item.designation}
                                    onChange={e =>
                                      this.handleInputChange(
                                        e,
                                        index,
                                        "Core Team"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Col
                              xs={24}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            >
                              <Button
                                className={style.experience_add_btn}
                                type="primary"
                                shape="round"
                                htmlType="submit"
                                size={"large"}
                                style={{
                                  display:
                                    index === all_data.core_team.length - 1
                                      ? "block"
                                      : "none",
                                  width: "100%",
                                  backgroundColor: "white",
                                  borderColor: "#d9d9d9",
                                  overflow: "hidden"
                                }}
                              >
                                Add Additional Core Team Members
                              </Button>
                            </Col>
                          </div>
                        </Card>
                      </div>
                    );
                  })
                  : ""}
              </Form>

              <Form
                onFinish={this.handleClientRefData}
                ref={this.References_formref}
              >
                {all_data.client_references &&
                  all_data.client_references.length > 0
                  ? all_data.client_references.map((item: any, index: any) => {
                    return (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "#e0eaf4",
                            minHeight: "35px"
                          }}
                        >
                          <Title
                            level={4}
                            style={{
                              marginLeft: "15px",
                              marginTop: "10px",
                              color: "steelblue",
                              height: "100%",
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            Client References&nbsp;
                            {index + 1}
                          </Title>
                          <div
                            data-testid='ClientReferencesModal'
                            style={{
                              display: index === 0 ? "none" : "block",
                              cursor: "pointer",
                              padding: "1rem"
                            }}
                            onClick={() =>
                              this.showModal(index, "Client References")
                            }
                          >
                          {this.renderCloseEditBusinessProfileClient()}
                          </div>
                        </div>
                        <Card>
                          <div>
                            <Row gutter={12}>
                              <Col
                                span={12}
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required field"
                                    }
                                  ]}
                                >
                                  <Input
                                    className={style.input_fields}
                                    data-testid="ClientReferences"
                                    name="reference"
                                    placeholder="Client References"
                                    style={{ borderWidth: 1 }}
                                    value={item.reference}
                                    onChange={e =>
                                      this.handleInputChange(
                                        e,
                                        index,
                                        "Client References"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Col
                              xs={24}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            >
                              <Button
                                className={style.experience_add_btn}
                                type="primary"
                                shape="round"
                                htmlType="submit"
                                size={"large"}
                                style={{
                                  display:
                                    index ===
                                      all_data.client_references.length - 1
                                      ? "block"
                                      : "none",
                                  width: "100%",
                                  backgroundColor: "white",
                                  borderColor: "#d9d9d9",
                                  overflow: "hidden"
                                }}
                              >
                                Add Additional Client References
                              </Button>
                            </Col>
                          </div>
                        </Card>
                      </div>
                    );
                  })
                  : ""}
              </Form>
              <Row style={{ background: "#f8f8f8" }} >
                <Col className="EditProfile_action_buttons" lg={12} xl={12} md={12} sm={12} xs={24} style={{ padding: "15px", }}>
                  {/* <Button
                        className={style.skip_add_btn}
                        type="primary"
                        shape="round"
                        size={"large"}
                        onClick={() => this.onHistoryScreen()}
                      >
                        Skip Profile Setup
                      </Button> */}
                </Col>
                <Col lg={12} xl={12} md={12} sm={12} xs={24}
                  // className="Previous_Next_Button_Scope"
                  style={{
                    padding: "15px 0px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end"
                  }}
                  className={style.responsive_bottom_action_button}
                >
                  <Button
                    data-testid="goToPrevious"
                    onClick={() => this.handlePrevious()}
                    className={style.previous_btn}
                    type="primary"
                    shape="round"
                    size={"large"}
                  >
                    Previous
                  </Button>
                  <Button
                    data-testid="SubmitBtn"
                    onClick={this.handleAddProfileData}
                    loading={this.state.isSubmitting}
                    className={style.btn_colors}
                    type='primary'
                    shape='round'
                    size={'large'}
                  // style={{ width: '140px', marginLeft: 5 }}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col xs={0} sm={0} md={3} lg={7} xl={7} />
        </Row>
      </div>
    );
    //Customizable Area End
  }
}
// Customizable Area Start
//Customizable Area End
