Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start

exports.GETMethod = "GET";
exports.POSTMethod = "POST";
exports.PUTMethod = "PUT";
exports.DELETEMethod = "DELETE";
exports.APPLICATIONJSONContentType = "application/json";
exports.MULTIPARTFormData = "multipart/form-data";

exports.GetProfileInfoEndPoint = "bx_block_profile/profiles";
exports.newUserSearchEndPoint = "bx_block_search/search/user_search?search=";
exports.GetCategoriesEndPoint = "bx_block_categories/categories";
exports.GetSendRegEndPoint = "bx_block_request_management/requests";
exports.postsApiEndpoint = "bx_block_posts/posts";
exports.GetCommunityPostsEndPoint =
  "bx_block_posts/posts/fetch_community_posts";
exports.commentsApiEndpoint = "bx_block_comments/comments";
exports.GetNetConnectionEndPoint = "bx_block_request_management/requests";
exports.getCommunitiesApiEndpoint = "bx_block_communityforum/community_forums";
exports.createEditCommunityApiEndPoint =
  "bx_block_communityforum/community_forums";
exports.deleteCommunityApiEndPoint = "bx_block_communityforum/community_forums";
exports.getCommunityDataApiEndpoint =
  "bx_block_communityforum/community_forums";
exports.getSetttingsFlagsApiEndpoint =
  "bx_block_communityforum/community_forums";
exports.getPostRequestsApiEndpoint = "bx_block_communityforum/community_forums";
exports.getJoinRequestsApiEndpoint = "bx_block_follow/community_joins";
exports.joinCommunityApiEndpoint = "bx_block_follow/community_joins";
exports.followCommunityApiEndpoint = "bx_block_follow/community_joins/follow";
exports.acceptRejectJoinRequestApiEndpoint = "bx_block_follow/community_joins";
exports.communityForumApiEndPoint = "bx_block_communityforum/community_forums";
exports.blockUserApiEndpoint = "bx_block_follow/community_blocks";
exports.anniversariesApiEndPoint = "account_block/accounts/anniversaries";
exports.searchAPIEndPoint = "bx_block_search/search?search=";
exports.refreshTokenApiEndPoint = "bx_block_login/refresh_tokens";
exports.notificationListingApiEndPoint =
  "bx_block_notifications/notifications?today_page=1&today_per_page=10&older_page=1&older_per_page=10&yesterday_page=1&yesterday_per_page=10"; //
exports.addDeviceTokenApiEndPoint = "account_block/accounts/add_device_token";
exports.followUnFollowEndPoint = "bx_block_follow/follows";
exports.getAnyAccountEndPoint = "account_block/accounts/";
exports.accountEndpoint = "account_block/accounts/";
exports.getNetConnectionEndPoint = "bx_block_request_management/requests";
exports.InviteByAdminEndPoint = "bx_block_follow/community_joins/admin_invite";
exports.getInvitedFriendsEndPoint =
  "bx_block_follow/community_joins/fetch_admin_invitations";
exports.manageInvitedFriendsEndPoint = "bx_block_follow/community_joins/";
exports.sendLinkEndPoint = "send_link";
exports.communityEndPoint = "bx_block_communityforum/community_forums/";
// Customizable Area End
exports.getInvitedFriendsEndPoint = "bx_block_follow/community_joins/fetch_admin_invitations";
exports.manageInvitedFriendsEndPoint ='bx_block_follow/community_joins/';
exports.sendLinkEndPoint = "send_link";
exports.communityEndPoint = "bx_block_communityforum/community_forums/"
// Customizable Area End 
