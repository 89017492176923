import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Spin,
  DatePicker,
  Steps,
  Popover,
  Space,
  Select,
  Avatar,
  Upload,
  message,
  Modal
} from "antd";
const { Title } = Typography;
import { logo, } from "../assets";
const style = require(`../../../social-media-account/src/login.module.css`);
import "../profile.css";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import ProfileExperienceController from "./profileExperienceController.web";
import moment from "moment";
const { Option } = Select;
const { Step } = Steps;
// Customizable Area End
// Customizable Area Start
const customDot = (dot: any, { status, index }: any) => (
  <Popover content={<span>step {index}</span>}>{dot}</Popover>
);
// Customizable Area End
export default class ProfileExperience extends ProfileExperienceController {
  //Customizable Area Start
  renderCloseModal = () => {
    return (
      <Modal
        className="delete_popup"
        title="Personal Profile"
        visible={this.state.isModalVisible}
        onOk={() => {
          this.deleteData(this.state.modalIndex);
        }}
        onCancel={() =>
          this.setState({
            isModalVisible: false
          })
        }
        footer={null}
      >
        <p>
          Want to Delete {this.state.modalString} {this.state.modalIndex + 1} ?
        </p>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "0.5rem",
            paddingBottom: "1rem"
          }}
        >
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              className={style.btn_colors2}
              type="primary"
              shape="round"
              size={"large"}
              style={{ width: "200px" }}
              onClick={() => {
                this.setState({
                  isModalVisible: false
                });
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              className={style.btn_danger}
              type="primary"
              shape="round"
              size={"large"}
              danger
              style={{ width: "200px" }}
              onClick={() => {
                this.deleteData(this.state.modalIndex);
              }}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { data } = this.state;
    // console.log("input ", data);
    return (
      <div style={{ backgroundColor: "#eff8fe", height: "100%" }}>
        {this.renderCloseModal()}
        {this.redirectUser()}

        <PageHeader
          className="site-page-header"
          title={
            <span style={{ color: "white" }}>
              <img style={{ height: "32px", marginRight: "8px" }} src={logo} />
              applied
            </span>
          }
          style={{
            backgroundImage: "linear-gradient(#005574, #7aacd6)",
            color: "white"
          }}
        />
        <Row
          style={{
            alignItems: "center",
            padding: "10% 0",
            backgroundColor: "#eff8fe"
          }}
        >
          <Col span={7} xs={2} sm={2} md={3} lg={7} xl={7} />
          <Col span={10} xs={20} sm={20} md={18} lg={10} xl={10}>
            <Title level={2} style={{ textAlign: "center" }}>
              Setup Your Personal Profile
            </Title>
            <Steps current={0} style={{ padding: "15px" }}>
              <Step />
              <Step />
              <Step />
              <Step />
              <Step />
            </Steps>
          </Col>
          <Col span={7} xs={2} sm={2} md={3} lg={7} xl={7} />
          <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
          <Col
            span={12}
            xs={20}
            sm={20}
            md={18}
            lg={18}
            xl={12}
            xxl={12}
            style={{ height: "100%" }}
            className="basic_profile_card"
          >
            {this.state.loading ? (
              <Spin />
            ) : (
              data &&
              data.map((item: any, index: any) => {
                // console.log(item,'personal profiles')
                return (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#e0eaf4",
                        minHeight: "35px"
                      }}
                    >
                      <Title
                        level={4}
                        style={{
                          marginLeft: "15px",
                          marginTop: "10px",
                          color: "steelblue",
                          height: "100%",
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        Experience{' '}
                        {index + 1}
                      </Title>
                      <div
                        style={{
                          display: index === 0 ? "none" : "block",
                          cursor: "pointer",
                          padding: "1rem"
                        }}
                        onClick={() => this.showModal(index, "Experience")}
                      >
                        <CloseOutlined translate={{}} rev={{}} />
                      </div>
                    </div>
                    <Form
                      ref={this.formref}
                      onFinish={this.handleExperienceData}
                    >
                      <Card>
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" },
                                { min: 1, message: "min character limit to 1" },
                                {
                                  max: 70,
                                  message: "max character limit to 70"
                                }
                              ]}
                            // name="company"
                            >
                              <Input
                                className={style.input_fields}
                                name="company"
                                placeholder="Company"
                                style={{ borderWidth: 1, color: "#a9bac8" }}
                                size="large"
                                value={item.company}
                                onChange={e => this.handleInputChange(e, index)}
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: false, message: "Required field" },
                                { min: 1, message: "min character limit to 1" },
                                {
                                  max: 70,
                                  message: "max character limit to 70"
                                }
                              ]}
                            // name="title"
                            >
                              <Input
                                className={style.input_fields}
                                name="title"
                                placeholder="Title"
                                style={{ borderWidth: 1, color: "#a9bac8" }}
                                size="large"
                                value={item.title}
                                onChange={e => this.handleInputChange(e, index)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        {/* /// */}
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            {/* {" "} */}
                            <Form.Item
                              rules={[
                                { required: false, message: "Required field" }
                              ]}
                            // name="start_date"
                            >
                              <DatePicker
                                name="start_date"
                                placeholder={"Start Date"}
                                className={style.input_fields}
                                format="DD-MM-YYYY"
                                style={{ color: "#a9bac8" }}
                                size="large"
                                disabledDate={(current: any) => {
                                  var a = moment().add(0, "days")
                                  var b = moment(this.state.finalData?.profile?.date_of_birth)
                                  return b.diff(current) <= 0 && a.diff(current) >= 0 ? false : true
                                }}
                                value={
                                  item.start_date
                                    ? moment(item.start_date, "YYYY-MM-DD")
                                    : undefined
                                }
                                onChange={(date, dateString) =>
                                  this.handleStartDate(date, dateString, index)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: false, message: "Required field" }
                              ]}
                            // name='end_date'
                            >
                              <DatePicker
                                name="end_date"
                                placeholder={"End Date"}
                                className={style.input_fields}
                                style={{ color: "#a9bac8" }}
                                size="large"
                                value={
                                  item.end_date
                                    ? moment(item.end_date, "YYYY-MM-DD")
                                    : undefined
                                }
                                format="DD-MM-YYYY"
                                disabled={item?.start_date ? false : true}
                                disabledDate={current => {
                                  let customDate = moment(item?.start_date).subtract(-1, "day").format("YYYY-MM-DD");
                                  return current < moment(customDate, "YYYY-MM-DD") || current > moment();
                                }}
                                onChange={(date, dateString) =>
                                  this.handleEndDate(date, dateString, index)
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        {/* /// */}
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: false, message: "Required field" },
                                {
                                  min: 15,
                                  message: "min character limit to 15"
                                }
                              ]}
                            // name="description"
                            >
                              <Input
                                className={style.input_fields}
                                name="description"
                                placeholder="Description"
                                value={item.description}
                                style={{ borderWidth: 1, color: "#a9bac8" }}
                                size="large"
                                onChange={e => this.handleInputChange(e, index)}
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <div className="active-btn-white">
                              <Button
                                className={style.experience_add_btn}
                                style={{
                                  display:
                                    index === data.length - 1 ? "block" : "none"
                                }}
                                type="primary"
                                htmlType="submit"
                                shape="round"
                                size={"large"}
                              >
                                Add Experience
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        {/* /// */}
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          />
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          />
                        </Row>
                      </Card>
                    </Form>
                  </div>
                );
              })
            )}

            <Row style={{ background: "#f8f8f8" }}>
              <Col lg={12} xl={12} md={12} sm={12} xs={24} style={{ padding: "15px", }}>
                <Button
                  onClick={this.onSkip}
                  className={style.skip_add_btn}
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  size={"large"}
                  style={{ width: "100%" }}
                >
                  Skip Profile Setup
                </Button>
              </Col>
              <Col
                lg={12} xl={12} md={12} sm={12} xs={24}
                style={{
                  padding: "15px 0px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end"
                }}
                className={style.responsive_bottom_action_button}
              >
                {/* <Button
                  className={style.previous_btn}
                  type="primary"
                  shape="round"
                  onClick={() => this.handlePrevious()}
                  size={"large"}
                >
                  Previous
                </Button> */}
                <Button
                  className={style.btn_colors}
                  type="primary"
                  onClick={this.handleNext}
                  shape="round"
                  size={"large"}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
        </Row>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End