import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import "./../userFeed.css";
import { Modal, message as antmessage } from "antd";
import { CheckCircleFilled } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import axios from "axios";
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';

export default function Check(props: any) {
    const stripe = useStripe();
    const elements = useElements();
    const [succeeded, setSucceeded] = useState(false);

    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent?.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });

    }, [stripe]);

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const response = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {

                // Make sure to change this to your payment completion page
                return_url: window.location.href,
            },
        });

        if (response?.paymentIntent?.status === "succeeded") {
            setSucceeded(true);
            console.log("jjjjjjjjj")
            var formdata = new FormData();
            formdata.append('transaction_result', 'successful');
            axios.put(`${baseURL}/bx_block_subscriptions/account_subscriptions/6/subscribe`,
                {
                    "transaction_result": 'successful'
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: props.token
                    },
                }
            )
        } else if (response?.error?.message) {
            antmessage.error(response?.error?.message)
            setMessage(response?.error?.message)
        }

        setIsLoading(false);
    };

    const toggleIsOpen = () => {
        setSucceeded(false)
        history.push({ pathname: '/UpgradeAccount' })
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <button data-testid="button-submit" disabled={isLoading || !stripe || !elements} id="submit"
                style={{ width: 300, marginTop: 10, border: 'none' }}
                className="pre_plan_btn login_btn_colors__kpBfq"
            >
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Place Order"}
                </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
            {succeeded && <Modal
                visible={true}
                onOk={toggleIsOpen}
                onCancel={toggleIsOpen}
            >
                <div style={{ textAlign: 'center' }}>
                    <CheckCircleFilled translate={undefined} rev={{}} style={{ fontSize: 50, color: '#36a906', marginBottom: 25 }} />
                    <h1 style={{ marginBottom: 4 }}>Account upgraded successfully!</h1>
                    <span style={{ fontSize: 16, fontWeight: 500, color: '#a6a6a6' }}>Your free account is now upgraded to <br /> Premium Personal Plan.</span>
                </div>
            </Modal>}
        </form>
    );
}