import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Spin,
  DatePicker,
  Space,
  Select,
  Avatar,
  Upload,
  message,
} from "antd";
const { Title } = Typography;
import { createProfile, logo, } from "../assets";
const style = require(`../../../social-media-account/src/login.module.css`);
import "../profile.css";
import { PlusCircleFilled } from "@ant-design/icons";
import CropModal from "../CommonUI/CropModal.web";
import PersonalBasicProfileController from "./personalBasicProfileController.web";
import { AlertMessage } from "../../../alert/src/AlertMessage.web";
import moment from "moment";
const { Option } = Select;
const userRegex = /^[a-zA-Z ]*$/;
const emailReg = RegExp(
  /^([a-zA-Z0-9_\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
);
// Customizable Area End
export default class CreatePersonalProfile extends PersonalBasicProfileController {
  // Customizable Area Start
  render() {
    // console.log(this.state.children);
    const { sessionData } = this.state;
    return (
      <div style={{ backgroundColor: "#eff8fe", height: '100%' }} >
        {this.redirectUser()}
        <PageHeader
          className="site-page-header"
          title={
            <span style={{ color: "white" }}>
              <img
                style={{ height: "32px", marginRight: "8px" }}
                src={logo}
              />
              applied
            </span>
          }
          style={{
            backgroundImage: "linear-gradient(180deg, rgba(60,96,134,1) 0%, rgba(53,135,196,1) 100%)",
            color: "white",
          }}
        />
        <Row
          style={{
            // minHeight: "90vh",
            padding: "60px 0",
            backgroundColor: "#eff8fe",
          }}
        >
          <Col span={24}  >
            <Title
              level={2}
              style={{ textAlign: "center", marginBottom: "15px" }}
            >
              Setup Your Personal Profile
            </Title>
          </Col>
          <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
          <Col
            span={12} xs={20} sm={20} md={18} lg={18} xl={12} xxl={12}
            style={{ height: "100%" }}
            className="basic_profile_card"
          >
            {this.state.loading ? <Spin /> : <Form
              initialValues={{
                full_name: this.state.pre_data?.full_name
                  ? this.state.pre_data.full_name
                  : sessionData && sessionData ? sessionData.first_name + " " + sessionData.last_name ? sessionData.first_name + " " + sessionData.last_name : sessionData.full_name + " " + sessionData.last_name : "",
                primary_email: this.state.pre_data?.primary_email
                  ? this.state.pre_data.primary_email
                  : sessionData && sessionData ? sessionData.email : "",
                secondary_email: this.state.pre_data?.secondary_email
                  ? this.state.pre_data.secondary_email
                  : "",
                employment_type: this.state.pre_data?.employment_type
                  ? this.state.pre_data.employment_type
                  : null,
                domain_skills: this.state.pre_data?.domain_skills
                  ? this.state.pre_data.domain_skills
                  : undefined,
                personal_skills: this.state.pre_data?.personal_skills
                  ? this.state.pre_data.personal_skills
                  : undefined,
                profile_pic: this.state.profileUrl ? this.state.profileUrl : '',
                date_of_birth: this.state.pre_data?.date_of_birth ? moment(this.state.pre_data.date_of_birth) : ''
              }}
              onFinish={this.handleData}
            >
              <Card>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    marginBottom: "16px",
                  }}
                >
                  <Form.Item
                    rules={[{ required: true, message: "Required field" }]}
                    name="profile_pic"
                  >
                    <Upload
                      name="file"
                      showUploadList={false}
                      multiple={false}
                      data-testid="Upload"
                      // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={(file) => this.beforeUpload(file)}
                      onChange={this.handleImageChange}
                      onRemove={() => {
                        this.setState({
                          profileUrl: "",
                          profilePictureFile: {},
                        });
                      }}
                    >
                      {this.state.profileUrl ? (
                        <Avatar size={64} src={this.state.profileUrl} />
                      ) : (
                        <>
                          <Avatar size={64} src={createProfile} />
                          <Avatar
                            style={{
                              top: "25px",
                              left: "-23px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            size={20}
                            icon={
                              <PlusCircleFilled translate={{}}
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "#005574",
                                }}
                                rev={{}}
                              />
                            }
                          />
                        </>
                      )}
                    </Upload>
                  </Form.Item>
                </div>
                <Row
                  gutter={15}
                >
                  <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
                    <Form.Item
                      rules={[
                        { required: true, message: "Required field" },
                        {
                          min: 1,
                          max: 40,
                          message: "Max length should be 40.",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("full_name").match(userRegex)
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("can enter only alphabets")
                            );
                          },
                        }),
                      ]}
                      name="full_name"
                      data-testid="form_full_name"
                    >
                      <Input
                        className={style.input_fields}
                        name="full_name"
                        data-testid="full_name"
                        placeholder="Full Name"
                        disabled={true}
                        style={{ borderWidth: 1, color: "#a9bac8" }}
                        size="large"
                      />
                    </Form.Item>

                  </Col>
                  <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      rules={[
                        { required: true, message: "Required field" },
                      ]}
                      name="employment_type"
                    >
                      <Select
                        id={"select"}
                        className={style.input_fields_select}
                        style={{ padding: "0 !important", border: '1px solid #a9bac8', color: "#a9bac8", overflow: "hidden" }}
                        placeholder="Employment Type"
                        bordered={true}
                        dropdownStyle={{
                          borderRadius: "15px",
                          border: "1px solid #a9bac8",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}

                        notFoundContent={
                          this.state.loading ? (
                            <Spin size="small" />
                          ) : (
                            "No Data Found "
                          )
                        }
                        size="large"

                      >
                        {this.state.employment_type}

                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={15}
                >
                  <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Required field",
                        },
                        {
                          type: "email",
                          message: "It is not valid email",
                        },
                      ]}
                      name="primary_email"
                    >
                      <Input
                        className={style.input_fields}
                        name="primary_email"
                        placeholder="Primary Email"
                        disabled={true}
                        style={{ borderWidth: 1, color: "#a9bac8" }}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Required field",
                        },
                        () => ({
                          validator(_, value) {
                            if (!value || emailReg.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Please Enter Valid Email!")
                            );
                          },
                        }),
                      ]}
                      data-testid="form_secondary_email"
                      name="secondary_email"
                    >
                      <Input
                        className={style.input_fields}
                        data-testid="secondary_email"
                        name="secondary_email"
                        placeholder="Secondary Email"
                        type={"email"}
                        style={{ borderWidth: 1, color: "#a9bac8" }}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={15}
                >
                  <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >

                    {this.state.domainSkillVisible && (
                      <Form.Item
                        name="other_domain_skills"
                        rules={[
                          {
                            required: true,
                            message: "Required field",
                          }
                        ]}
                      // rules={[
                      //   { required: true, message: "Required field" },
                      //   {
                      //     min: 1,
                      //     max: 20,
                      //     message: "Max length should be 20.",
                      //   },
                      //   ({ getFieldValue }) => ({
                      //     validator(_, value) {
                      //       if (
                      //         !value ||
                      //         value.match(userRegex)
                      //       ) {
                      //         return Promise.resolve();
                      //       }
                      //       return Promise.reject(
                      //         new Error("can enter only alphabets")
                      //       );
                      //     },
                      //   }),
                      // ]}
                      >
                        <Input
                          className={style.input_fields}
                          // name="other_domain_skills"
                          placeholder="Other Domain skills"
                          style={{ borderWidth: 1, color: "#a9bac8" }}
                          size="large"
                          autoFocus
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      name="domain_skills"
                    >
                      {this.state.pre_data && this.state.pre_data.domain_skills ? (
                        <Select
                          mode="multiple"
                          className={style.input_fields_select}
                          placeholder="Domain skills"
                          style={{ padding: "0 !important", border: '1px solid #a9bac8', color: "#a9bac8", overflow: "hidden" }}
                          // allowClear
                          size="large"
                          bordered={true}
                          value={this.state.pre_data.domain_skills}
                          // defaultValue={this.state.pre_data.domain_skills}
                          dropdownStyle={{
                            borderRadius: "15px",
                            border: "1px solid #a9bac8",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                          data-testid="test_domain_skills"
                          showArrow
                          onSelect={(value) => {
                            console.log("val", value);
                            if (value == "Other") {
                              this.setState({ domainSkillVisible: true });
                            }
                          }}
                          onDeselect={(val) => {
                            console.log("diselect", val);
                            if (val == "Other") {
                              this.setState({
                                domainSkillVisible: false,
                              });
                            }
                          }}
                          onClear={() => {
                            console.log("clear");
                            this.setState({
                              domainSkillVisible: false,
                            });
                          }}
                          maxTagCount={"responsive"}
                        >
                          {/* {console.log(this.state.children, "CHild1")} */}
                          {this.state.children}
                        </Select>
                      ) : (
                        <Select
                          mode="multiple"
                          className={style.input_fields_select}
                          placeholder="Domain skills"
                          style={{ padding: "0 !important", border: '1px solid #a9bac8', color: "#a9bac8", overflow: "hidden" }}
                          allowClear
                          size="large"
                          bordered={true}
                          dropdownStyle={{
                            borderRadius: "15px",
                            border: "1px solid #a9bac8",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                          showArrow
                          onSelect={(value) => {
                            console.log("val", value);
                            if (value == "Other") {
                              this.setState({ domainSkillVisible: true });
                            }
                          }}
                          onDeselect={(val) => {
                            console.log("diselect", val);
                            if (val == "Other") {
                              this.setState({
                                domainSkillVisible: false,
                              });
                            }
                          }}
                          onClear={() => {
                            console.log("clear");
                            this.setState({
                              domainSkillVisible: false,
                            });
                          }}
                          maxTagCount={"responsive"}
                        >
                          {/* {console.log(this.state.children, "CHild1")} */}
                          {this.state.children}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >

                    {this.state.personalSkillVisible && (
                      <Form.Item
                        name="other_personal_skills"
                        rules={[
                          {
                            required: true,
                            message: "Required field",
                          }
                        ]}
                      // rules={[
                      //   { required: true, message: "Required field" },
                      //   {
                      //     min: 1,
                      //     max: 20,
                      //     message: "Max length should be 20.",
                      //   },
                      //   ({ getFieldValue }) => ({
                      //     validator(_, value) {
                      //       if (
                      //         !value ||
                      //         value.match(userRegex)
                      //       ) {
                      //         return Promise.resolve();
                      //       }
                      //       return Promise.reject(
                      //         new Error("can enter only alphabets")
                      //       );
                      //     },
                      //   }),
                      // ]}
                      >
                        <Input
                          className={style.input_fields}
                          // name="other_personal_skills"
                          placeholder="Other Personal skills"
                          style={{ borderWidth: 1, color: "#a9bac8" }}
                          size="large"
                          autoFocus
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      name="personal_skills"
                    >
                      {this.state.pre_data && this.state.pre_data.personal_skills ? (

                        <Select
                          mode="multiple"
                          className={style.input_fields_select}
                          placeholder="Personal skills"
                          size="large"
                          style={{ padding: "0 !important", border: '1px solid #a9bac8', color: "#a9bac8", overflow: "hidden" }}
                          // defaultValue={this.state.pre_data.personal_skills}
                          value={this.state.pre_data.personal_skills}
                          allowClear
                          data-testid="test_personal_skills"
                          showArrow
                          bordered={true}
                          dropdownStyle={{
                            borderRadius: "15px",
                            border: "1px solid #a9bac8",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                          onSelect={(value) => {
                            // console.log("val", value);
                            if (value == "Other") {
                              this.setState({ personalSkillVisible: true });
                            }
                          }}
                          onDeselect={(val) => {
                            // console.log("diselect", val);
                            if (val == "Other") {
                              this.setState({
                                personalSkillVisible: false,
                              });
                            }
                          }}
                          onClear={() => {
                            console.log("clear");
                            this.setState({
                              personalSkillVisible: false,
                            });
                          }}
                          maxTagCount={"responsive"}
                        >
                          {this.state.skills_2}
                        </Select>
                      ) :
                        <Select
                          mode="multiple"
                          className={style.input_fields_select}
                          placeholder="Personal skills"
                          style={{ padding: "0 !important", border: '1px solid #a9bac8', color: "#a9bac8", overflow: "hidden" }}
                          allowClear
                          showArrow
                          size="large"
                          bordered={true}
                          dropdownStyle={{
                            borderRadius: "15px",
                            border: "1px solid #a9bac8",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                          onSelect={(value) => {
                            // console.log("val", value);
                            if (value == "Other") {
                              this.setState({ personalSkillVisible: true });
                            }
                          }}
                          onDeselect={(val) => {
                            // console.log("diselect", val);
                            if (val == "Other") {
                              this.setState({
                                personalSkillVisible: false,
                              });
                            }
                          }}
                          onClear={() => {
                            console.log("clear");
                            this.setState({
                              personalSkillVisible: false,
                            });
                          }}
                          maxTagCount={"responsive"}
                        >
                          {this.state.skills_2}
                        </Select>}
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={15}
                >
                  <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} >
                    <Form.Item
                      rules={[
                        { required: true, message: "Required field" },
                      ]}
                      name="date_of_birth"
                    >
                      {this.state.pre_data?.date_of_birth ? (
                        <DatePicker
                          name="date_of_birth"
                          placeholder={"Date of Birth"}
                          className={style.input_fields}
                          style={{ color: "#a9bac8", border: "1px solid #a9bac8" }}
                          size="large"
                          defaultValue={moment(this.state.pre_data.date_of_birth)}
                          format="DD-MM-YYYY"
                          disabledDate={(current: any) => {
                            var a = moment().add(-100, 'years')
                            var b = moment().add(-18, 'years')

                            return a.diff(current) <= 0 && b.diff(current) >= 0 ? false : true

                            // moment().add(0, "days") <= current ||
                            //   current >= moment().add(-100, 'years') &&
                            //   moment().add(-18, 'years') <= current
                          }}
                        />
                      ) : (
                        <DatePicker
                          name="date_of_birth"
                          placeholder={"Date of Birth"}
                          className={style.input_fields}
                          style={{ color: "#a9bac8", border: "1px solid #a9bac8" }}
                          size="large"
                          format="DD-MM-YYYY"
                          disabledDate={(current: any) => {
                            var a = moment().add(-100, 'years')
                            var b = moment().add(-18, 'years')

                            return a.diff(current) <= 0 && b.diff(current) >= 0 ? false : true

                            // moment().add(0, "days") <= current ||
                            //   current >= moment().add(-100, 'years') &&
                            //   moment().add(-18, 'years') <= current
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} ></Col>
                </Row>

              </Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "15px",
                  background: "#f8f8f8",
                }}
              >
                <Button
                  className={style.btn_colors}
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  size={"large"}
                >
                  Next
                </Button>
              </div>
            </Form>
            }
          </Col>
          <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
        </Row>
        <AlertMessage
          isModalVisible={this.state.isErrorModalShow}
          title={this.state.errorContent.title}
          body={this.state.errorContent.body}
          isError={this.state.errorContent.isError}
          onOk={() =>
            this.setState({ isErrorModalShow: false, errorContent: {} })
          }
        />
        <CropModal
          history={this.props.history}
          openCropModal={this.state.openCropModal}
          setCropModal={() => {
            this.setState({
              openCropModal: false
            })
          }}
          setCroppedImageUrl={(file) => {
            console.log(file)
            this.setState({
              profileUrl: file.url,
              openCropModal: false,
              file: file.pic
            })
          }}
          data-testid="CropModal"
          src={this.state.copyProfileUrl}
        />
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End