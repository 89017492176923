import React, { FunctionComponent, useState } from 'react';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Row, Col, Image, Form, Input, Typography, Button, Modal } from 'antd';
import 'antd/dist/antd.css';
const { Title, Text, Link } = Typography;
interface Props {
  title?: string;
  body: string;
  btnPositiveText?: string;
  navigation?: any;
  isError: boolean | true;
  isModalVisible: boolean | true;
  onOk: Function | any;
}
interface S {
  title: string;
  body: string;
  btnPositiveText: string;
  btnNegativeText: string;
  btnNeutralText: string;
  isModalVisible: boolean;
}

interface SS { }
// export default function AlertMessage(props: Props) {
export const AlertMessage: FunctionComponent<Props> = (props) => {
  return (
    <Modal
      closable={false}
      title={props.isError ? 'Error' : 'Success'}
      visible={props.isModalVisible}
      footer={null}
      style={{
        borderRadius: '2px',
        overflow: 'hidden',
      }}
      bodyStyle={{
        textAlign: 'center',
        padding: '0px 16px 16px',
      }}
    >
      {props.isError ? (
        <CloseCircleFilled
          translate={{}}
          style={{
            fontSize: '50px',
            paddingBottom: '16px',
            color: '#ea0c0c',
          }}
          rev={{}}
        />
      ) : (
        <CheckCircleFilled
          translate={{}}
          style={{
            fontSize: '50px',
            paddingBottom: '16px',
            color: '#52c41a',
          }}
          rev={{}}
        />
      )}
      <br />
      <Text type='secondary'>
        {props.body ? props.body : 'Something went Wrong'}
      </Text>
      <br />
      <br />
      <Button
        type='primary'
        shape='round'
        size={'large'}
        onClick={() => props.onOk()}
        style={{
          backgroundColor: '#005574',
          width: '50%',
        }}
      >
        {props.btnPositiveText ? props.btnPositiveText : 'Ok'}
      </Button>
    </Modal>
  );
};
