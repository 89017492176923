import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
// Customizable Area Start
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Customizable Area End

const useStyles = makeStyles((theme) => ({
    root: {
        // width: 300 + theme.spacing(3) * 2,
        width: "100%",
    },
    margin: {
        height: theme.spacing(3),
    }
}));

// function ValueLabelComponent(props: any) {
//     console.log(props);
//     const { children, open, value } = props;

//     return (
//         <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
//             {children}
//         </Tooltip>
//     );
// }

const AirbnbSlider = withStyles(
    {
        root: {
            // color: "#b24040",
            height: 3,
            padding: '13px 0',
        },
        thumb: {
            height: 27,
            width: 27,
            // backgroundColor: "#b24040",
            color: "#ffffff",
            // border: '1px solid currentColor',
            marginTop: -12,
            marginLeft: -3,
            boxShadow: '#ebebeb 0 2px 2px',
            '&:focus, &:hover, &$active': {
                boxShadow: '#ccc 0 2px 3px 1px',
            },
            '& .bar': {
                // display: inline-block !important;
                height: 9,
                width: 1,
                // backgroundColor: 'currentColor',
                marginLeft: 1,
                marginRight: 1,
            },
        },
        active: {},
        track: {
            height: 3,
        },
        rail: {
            color: '#d8d8d8',
            opacity: 1,
            height: 3,
        },
    }
)(Slider);

// ValueLabelComponent.propTypes = {
//     children: PropTypes.element.isRequired,
//     open: PropTypes.bool.isRequired,
//     value: PropTypes.number.isRequired,
// };

export default function SliderView(props: any) {
    // console.log(props)
    // Customizable Area Start
    const classes = useStyles();
    const [value, setValue] = useState<number>(0);
    const [colorCode, setColorCode] = useState<string>("")

    useEffect(() => {
        setValue(props.value)
        if (props.value < 60) {
            setColorCode("#b24040")
        } else if (props.value < 80) {
            setColorCode("#3a87a8")
        } else {
            setColorCode("#3c9b83")
        }
    }, [props.value]);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
        if (newValue < 60) {
            setColorCode("#b24040")
        } else if (newValue < 80) {
            setColorCode("#3a87a8")
        } else {
            setColorCode("#3c9b83")
        }
        props.changeHandler && props.changeHandler(newValue);
    };


    const AirbnbThumbComponent = (props2: any) => {
        // console.log(props2);
        return (
            <span {...props2}>
                <div style={{
                    backgroundColor: colorCode,
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Typography style={{ fontSize: "9px" }}>{Math.round(value)}%</Typography>
                </div>
            </span>
        );
    }

    return (
        <div className={classes.root}>
            <Typography gutterBottom variant='body2'>{props.name}{' '}
                <Tooltip title={props?.description}>
                    <ExclamationCircleOutlined
                        translate={undefined}
                        rev={{}}
                        style={{ marginLeft: "0.4rem", cursor: "pointer", color: "#919191" }}
                    />
                </Tooltip>
            </Typography>
            <AirbnbSlider
                ThumbComponent={AirbnbThumbComponent}
                onChange={handleChange}
                defaultValue={props.value || 0}
                // disabled
                style={{
                    color: colorCode
                }}
            >
            </AirbnbSlider>
        </div>
    );
    // Customizable Area End
}
