import React from "react";
import PostRatingController, { Props } from "./PostRatingController.web";
import { style } from "../assets";
import { UserOutlined } from "@ant-design/icons";
import "./../userFeed.css";
import {
  Card,
  Row,
  Col,
  List,
  Input,
  Typography,
  Avatar,
  Button,
  Upload,
  Image,
  Badge,
  Radio,
  Tabs
} from "antd";
import { Dimensions, PixelRatio } from "react-native";
import { Divider } from "react-native-elements/dist/divider/Divider";
const { Title, Text, Link } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

export default class AnniversariesRating extends PostRatingController {
  renderUserOutlinednetwork = () => {
    return <UserOutlined translate={{}} rev={{}} />
  }
  renderUserOutlinedGoogle = () => {
    return <UserOutlined translate={{}} rev={{}} />
  }
  renderUserOutlinedPython = () => {
    return <UserOutlined translate={{}} rev={{}} />
  }
  renderUserOutlinedDeveloper = () => {
    return <UserOutlined translate={{}} rev={{}} />
  }
  renderBirthAnniversariesView = () => {
    return (
      <div>
        <Row style={{ paddingLeft: "16px", paddingRight: "16px" }}>
          <Col span={12}>
            <Title level={4} style={{ fontSize: 16, fontWeight: "bold" }}>
              Today
            </Title>
          </Col>
        </Row>
        <Row
          gutter={10}
          style={{ minHeight: "38vh", padding: "0 15px 15px 15px" }}
        >
          <Col xs={0} sm={0} md={3} lg={5} xl={4.3}>
            <Card
              hoverable
              className="padding-card-network"
              style={{ borderRadius: 8 }}
            >
              <Row justify="center">
                <Avatar size={64} icon={this.renderUserOutlinednetwork()} />
              </Row>
              <Row
                justify="center"
                style={{
                  paddingTop: "12px",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                  Anje Keizer
                </Text>
                <br />
                <p
                  style={{
                    width: "100%",
                    marginBottom: "0",
                    fontSize: 12,
                    textAlign: "center"
                  }}
                >
                  Sr. Python Developer <Text type="secondary"> at </Text> Google
                </p>
                <br />
                <Text
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    fontSize: 12,
                    textAlign: "center"
                  }}
                  type="secondary"
                >
                  13 Aug, 2021
                </Text>
              </Row>
              <Divider />
              <Row justify="center" style={{ paddingTop: "12px" }}>
                <Button
                  className={style.skip_add_btn_anniversaries}
                  type="primary"
                  shape="round"
                  size={"small"}
                >
                  View Profile
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  renderWorkAnniversariesView = () => {
    return (
      <div>
        <Row style={{ paddingLeft: "16px", paddingRight: "16px" }}>
          <Col span={12}>
            <Title level={4} style={{ fontSize: 16, fontWeight: "bold" }}>
              Today
            </Title>
          </Col>
        </Row>
        <Row
          gutter={10}
          style={{ minHeight: "38vh", padding: "0 15px 15px 15px" }}
        >
          <Col xs={0} sm={0} md={3} lg={5} xl={4.3}>
            <Card
              hoverable
              className="padding-card-network"
              style={{ borderRadius: 8 }}
            >
              <Row justify="center">
                <Avatar size={64} icon={this.renderUserOutlinedGoogle()} />
              </Row>
              <Row
                justify="center"
                style={{
                  paddingTop: "12px",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                  Anje Keizer
                </Text>
                <br />
                <p
                  style={{
                    width: "100%",
                    marginBottom: "0",
                    fontSize: 12,
                    textAlign: "center"
                  }}
                >
                  Sr. Python Developer <Text type="secondary"> at </Text> Google
                </p>
                <br />
                <Text
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    fontSize: 12,
                    textAlign: "center"
                  }}
                  type="secondary"
                >
                  13 Aug, 2021
                </Text>
              </Row>
              <Divider />
              <Row justify="center" style={{ paddingTop: "12px" }}>
                <Button
                  className={style.skip_add_btn_anniversaries}
                  type="primary"
                  shape="round"
                  size={"small"}
                >
                  View Profile
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row style={{ paddingLeft: "16px", paddingRight: "16px" }}>
          <Col span={12}>
            <Title level={4} style={{ fontSize: 16, fontWeight: "bold" }}>
              Next Week
            </Title>
          </Col>
        </Row>
        <Row
          gutter={10}
          style={{ minHeight: "38vh", padding: "0 15px 15px 15px" }}
        >
          <Col xs={0} sm={0} md={3} lg={5} xl={4.3}>
            <Card
              hoverable
              className="padding-card-network"
              style={{ borderRadius: 8 }}
            >
              <Row justify="center">
                <Avatar size={64} icon={this.renderUserOutlinedPython()} />
              </Row>
              <Row
                justify="center"
                style={{
                  paddingTop: "12px",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                  Anje Keizer
                </Text>
                <br />
                <p
                  style={{
                    width: "100%",
                    marginBottom: "0",
                    fontSize: 12,
                    textAlign: "center"
                  }}
                >
                  Sr. Python Developer <Text type="secondary"> at </Text> Google
                </p>
                <br />
                <Text
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    fontSize: 12,
                    textAlign: "center"
                  }}
                  type="secondary"
                >
                  13 Aug, 2021
                </Text>
              </Row>
              <Divider />
              <Row justify="center" style={{ paddingTop: "12px" }}>
                <Button
                  className={style.skip_add_btn_anniversaries}
                  type="primary"
                  shape="round"
                  size={"small"}
                >
                  View Profile
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row style={{ paddingLeft: "16px", paddingRight: "16px" }}>
          <Col span={12}>
            <Title level={4} style={{ fontSize: 16, fontWeight: "bold" }}>
              This Month
            </Title>
          </Col>
        </Row>
        <Row
          gutter={10}
          style={{ minHeight: "38vh", padding: "0 15px 15px 15px" }}
        >
          <Col xs={0} sm={0} md={3} lg={5} xl={4.3}>
            <Card
              hoverable
              className="padding-card-network"
              style={{ borderRadius: 8 }}
            >
              <Row justify="center">
                <Avatar size={64} icon={this.renderUserOutlinedDeveloper()} />
              </Row>
              <Row
                justify="center"
                style={{
                  paddingTop: "12px",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                  Anje Keizer
                </Text>
                <br />
                <p
                  style={{
                    width: "100%",
                    marginBottom: "0",
                    fontSize: 12,
                    textAlign: "center"
                  }}
                >
                  Sr. Python Developer <Text type="secondary"> at </Text> Google
                </p>
                <br />
                <Text
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    fontSize: 12,
                    textAlign: "center"
                  }}
                  type="secondary"
                >
                  13 Aug, 2021
                </Text>
              </Row>
              <Divider />
              <Row justify="center" style={{ paddingTop: "12px" }}>
                <Button
                  className={style.skip_add_btn_anniversaries}
                  type="primary"
                  shape="round"
                  size={"small"}
                >
                  View Profile
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div className="background" style={{ backgroundColor: "#f6f6f6" }}>
        {this.redirectUser()}
        <Row style={{ paddingTop: 15 }}>
          <Text
            className="textlabel_subheadingText"
          >
            My Profile /
          </Text>
          <Text
            className="textlabel_subheadingText2"
          >
            {" "}
            Anniversaries
          </Text>
        </Row>
        <Col style={{ marginLeft: "20px", marginTop: "10px" }}>
          <Text
            className="textlabel_headingText"
          >
            Anniversaries
          </Text>
        </Col>
        <Tabs
          defaultActiveKey="1"
          tabBarStyle={{ backgroundColor: "#DEE5EB" }}
          style={{ backgroundColor: "#F1F0F5", margin: 20 }}
        >
          <TabPane tab="Work Anniversaries" key="1" style={{ marginLeft: 10 }}>
            {this.renderWorkAnniversariesView()}{" "}
          </TabPane>
          <TabPane tab="Birth Anniversaries" key="2" style={{ marginLeft: 10 }}>
            {this.renderBirthAnniversariesView()}{" "}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
