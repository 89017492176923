import React from 'react';
import { Avatar, Button, Card, Input, Typography } from "antd";
import {
    HeartTwoTone,
    HeartFilled,
    RollbackOutlined
} from "@ant-design/icons";
import { sendIcon, faceIcon } from "./assets";
import Picker from 'emoji-picker-react';
import RenderReplyCommentController from './RenderReplyCommentController.web';

class RenderReplyComment extends RenderReplyCommentController {
    render() {
        const { displayComment, emoji, onSendReplyComment, rplyPostComment, } = this.state
        const { item, handleUserRequest, onCommentLike, } = this.props;



        return (
            <div key={item.attributes.id}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: "row",
                        alignItems: "baseline",
                        justifyContent: "flex-start",
                        gap: "10px"
                    }}
                >
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleUserRequest(item.attributes.account.data.attributes.profile?.data?.attributes)}
                        data-testid="userNavigation"
                    >
                        <Avatar
                            data-testid="Avatar"
                            src={item.attributes.account.data.attributes?.photo ? item.attributes.account.data.attributes.photo : null} size={30} />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Typography.Text
                            style={{
                                cursor: "pointer",
                                color: "rgba(0, 0, 0, 0.85)",
                                fontSize: "13px",
                                fontWeight: 500,
                                marginBottom: "-13px"
                            }}
                            onClick={() => { handleUserRequest(item.attributes.account.data.attributes.profile.data.attributes) }}
                            data-testid="textBody"
                        >
                            {item && item.attributes.account.data.attributes?.role === "business" ?
                                item.attributes.account.data.attributes.profile.data.attributes?.company_name :
                                item.attributes.account.data.attributes?.full_name}
                        </Typography.Text>
                        <Typography.Text
                            data-testid="itemComment"
                            style={{
                                color: '#bbbbbb',
                                fontSize: 13,
                                fontWeight: 400,
                                marginBottom: "-13px"
                            }}
                        >
                            {item && item.attributes.comment}
                        </Typography.Text>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: "row",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                gap: "10px"

                            }}
                            data-testid="likeDislike"
                        >
                            <Button
                                type="text"
                                style={{ color: "red", padding: "0px", fontSize: "12px" }}
                                onClick={() =>
                                    onCommentLike(item.id, item.attributes.liked)
                                }
                                icon={item.attributes.liked ? <HeartFilled translate={{}} rev={{}}/> : <HeartTwoTone translate={{}} twoToneColor="red" rev={{}}/>}
                                data-testid="LikeorDislike"
                            >
                                {item && item.attributes.like_count} {item && item.attributes.like_count < 2 ? " Like" : " Likes"}
                            </Button>
                            <Button
                                type="text"
                                style={{ color: "#bbbbbb", padding: "0px", fontSize: "12px" }}
                                icon={<RollbackOutlined translate={{}} rev={{}}/>}
                                onClick={() => this.onReply()}
                                data-testid="showOnReplycomment"
                            >
                                Reply
                            </Button>
                        </div>
                        {displayComment &&
                            <div>
                                <Card
                                    bodyStyle={{
                                        flexDirection: "row",
                                        display: "flex",
                                        padding: "5px",
                                    }}
                                    style={{ borderWidth: "0px" }}
                                >
                                    <Input
                                        disabled={onSendReplyComment}
                                        style={{
                                            fontSize: "13px",
                                            backgroundColor: "#FAFAFC",
                                            borderRadius: 40,
                                            width: '100%',
                                            border: '1px solid #F3F2F6'
                                        }}
                                        type="text"
                                        data-testid="replyonComment"
                                        value={rplyPostComment}
                                        placeholder="Write your comment..."
                                        onKeyDown={(event) => event.key === "Enter" && this.handleOnSubmitCommentReply(item.attributes.id, rplyPostComment)}
                                        onChange={(event) => this.onRplyChange(event.target.value)}
                                        suffix={[
                                            <Button
                                                style={{ border: "none", outline: "none" }}
                                                type="ghost"
                                                shape="circle"
                                                icon={<img src={faceIcon} alt="" width={20} />}
                                                onClick={this.handleEmoji}
                                            />,
                                            rplyPostComment && rplyPostComment.length > 0 &&
                                            <Button
                                                shape="circle"
                                                type="ghost"
                                                data-testid="clickButton"
                                                style={{ border: "none", outline: "none" }}
                                                onClick={async () => {
                                                    this.handleOnSubmitCommentReply(item.attributes.id, rplyPostComment)
                                                }}
                                                icon={
                                                    <img
                                                        src={sendIcon}
                                                        alt=""
                                                        width={30}
                                                    />}
                                            />
                                        ]}
                                    />
                                </Card>
                                {emoji && <Picker disableAutoFocus data-testid="emojiPicker" onEmojiClick={this.onReplyEmojiClick} />}
                            </div>
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default RenderReplyComment;