import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Typography } from 'antd';
import {
    CheckCircleFilled,
} from '@ant-design/icons';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 18,
        height: 18,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
});



// Inspired by blueprintjs
function StyledRadio(props: RadioProps) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            checkedIcon={<CheckCircleFilled translate={undefined} rev={{}} style={{ color: "#fc595a", fontSize: "18px" }} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

export default function CustomRadioButton(props: any) {
    const { data, handleOnClick } = props;
    const [select, setSelected] = React.useState(null)
    return (
        <FormControl component="fieldset">
            <RadioGroup row defaultValue="yes" aria-label="gender" name="customized-radios">
                {
                    data.map((item: any) => {
                        return (
                            <FormControlLabel
                                key={item.id}
                                value={item.value}
                                control={<StyledRadio />}
                                style={{ color: "rgb(117, 117, 117)", marginRight: "40px", marginBottom: "10px" }}
                                label={<Typography style={{
                                    fontSize: "15px",
                                    color: "rgba(151, 151, 151, 1)",
                                    fontWeight: select === null ? item.id === 2 ? 600 : 500 : item.id === select ? 600 : 500
                                }}>{item.name}</Typography>}
                                onClick={() => {
                                    handleOnClick ? handleOnClick(item.name) : null
                                    setSelected(item.id)
                                }}
                            />
                        )
                    })
                }
            </RadioGroup>
        </FormControl>
    );
}