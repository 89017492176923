import React from "react";
import CommonSettingController from "./CommonSettingsController.web";
import "./settings.css";
import { List, Row, Col, Typography, Menu, Space, Divider } from 'antd';
import AccountSettings from "./AccountSettings.web";
import DeleteAccount from "./DeleteAccount.web";
import AppliedPolicy from "./AppliedPolicy.web";
import ContactUs from "./ContactUs.web";
import SuspendAccount from "./SuspendAccount.web";
import AboutApplied from "./AboutApplied.web";
import Logout from "./Logout.web";
import { deleteIcon, suspendIcon, logoutIcon, aboutIcon, appliedIcon, contactUsIcon, settingIcon } from '../assets';
import { isEmpty } from "../../../../framework/src/Utilities";
import './pdfs.css';
import {
  SettingOutlined, ContactsOutlined, ScheduleOutlined, InfoCircleOutlined, UsergroupAddOutlined, PhoneFilled,
  VideoCameraOutlined, AlertOutlined, TrophyOutlined, ReadOutlined, ProfileOutlined, SecurityScanOutlined
} from "@ant-design/icons";
import { CircularProgress, Box, LinearProgress } from "@material-ui/core";
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import SchoolIcon from '@material-ui/icons/School';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import VpnLockIcon from '@material-ui/icons/VpnLock';

const { Title, Text } = Typography;

export default class Pdf extends CommonSettingController {

  getDomainSkills = (userDataHere: any) => {
    if (
      isEmpty(userDataHere)
      // isEmpty(userDataHere.attributes) ||
      // isEmpty(userDataHere.attributes.domain_skills)
    )
      return "";
    const domainSkillsPresent = userDataHere;
    const domainSkillsStr = domainSkillsPresent.join(", ");
    // console.log("domainSkillsStr = ", domainSkillsStr);
    return domainSkillsStr;
  }


  // Customizable Area Start
  render() {
    console.log(this.state.userAccountDetails, "uuuuuu")
    const profile = this.state.userAccountDetails?.attributes?.account?.data?.attributes?.profile?.data?.attributes
    const ratings = this.state.userAccountDetails?.attributes?.account?.data?.attributes?.rating?.data?.attributes
    const pro = this.state.userAccountDetails?.attributes?.account?.data?.attributes
    return (
      <div style={{ width: "100%", minHeight: "100vh", backgroundColor: "rgba(245, 245, 245, 1)", boxSizing: "border-box", padding: '20px' }}>
        <div style={{ width: "80%", margin: 'auto', display: 'flex', flexFlow: 'row wrap' }}>
          <div style={{ padding: "30px 15px 10px", backgroundColor: '#F3F3F3', display: 'block', flex: '0 0 25%', maxWidth: '25%', position: 'relative', minHeight: 1 }}>
            <div>
              <div className="profile_pic">
                <img
                  src={this.state.userAccountDetails?.attributes?.account?.data?.attributes?.photo}
                  className="pic"
                />
              </div>

              <div style={{ textAlign: 'center' }}>
                <span className="profile_name">
                  {this.state.userAccountDetails?.attributes?.account?.data?.attributes?.full_name}
                </span>
                <span className="domine_expert_text">{this.state.userAccountDetails?.attributes?.account?.data?.attributes?.designation?.title}</span>
                {/* <Text className="domine_expert_text">Years of Experience</Text> */}
                {
                  this.state.userAccountDetails?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.experience_years >= 1 &&
                  <span className="domine_expert_text">{this.state.userAccountDetails?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.experience_years} Years of Experience</span>
                }
              </div>

              <div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <VpnLockIcon className="domine_icon" />
                  <span className="domine_skill" style={{ marginLeft: 12 }}>
                    DOMINE SKILLS
                  </span>
                </div>
                <hr className="hr_line" />
                {this.state.userAccountDetails?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills ? (
                  <div>
                    <span className="" style={{ fontSize: 12, fontWeight: 'bold' }}>
                      {this.getDomainSkills(this.state.userAccountDetails?.attributes?.account?.data?.attributes?.profile?.data?.attributes?.domain_skills)}
                    </span>
                  </div>
                ) : null}

              </div>

              {/* contact component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <PhoneFilled translate={undefined} rev={{}} style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>Contact details</span>
                </div>
                <hr className="hr_line" />
                <div>
                  <p className="left_head">Email : <span className="left_text">{pro?.email ? pro?.email : 'null'}</span></p>
                  <p className="left_head">Phone : <span className="left_text">{pro?.full_phone_number ? pro?.full_phone_number : 'null'}</span></p>
                </div>
              </div>
              {/* contact component end */}

              {/* LANGUAGES component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <LanguageOutlinedIcon style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>LANGUAGES details</span>
                </div>
                <hr className="hr_line" />
              </div>
              {/* LANGUAGES component end */}

              {/* Circular Progress bar Start */}
              <div>
                <div className="rating_main" style={{ width: '100%' }}>
                  <div style={{ padding: 6 }}>
                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        value={98}
                        // color={'#0076de'}
                        size={45}
                        thickness={5}
                        style={{
                          color: "#2f5597",
                          zIndex: 1,
                          transform: "rotate(90deg)"
                        }}
                      // className={classes.bottom}
                      />
                      <div
                        style={{
                          background: '#fff',
                          borderRadius: '50%',
                          textAlign: 'center',
                          position: 'absolute',
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          top: 0, right: 0, left: 0, bottom: 0
                        }}
                      >
                        <div >
                          <span className="text_inner_circle_left">Leadership<br />Rating</span>
                          {/* <span className="text_inner_circle_left">Rating</span> */}
                          <p className="value_inner_circle_left">98%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: 6 }}>
                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        value={95}
                        // color={'#0076de'}
                        size={45}
                        thickness={5}
                        style={{
                          color: "#2f5597",
                          zIndex: 1,
                          transform: "rotate(90deg)"
                        }}
                      // className={classes.bottom}
                      />
                      <div
                        style={{
                          background: '#fff',
                          borderRadius: '50%',
                          textAlign: 'center',
                          position: 'absolute',
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          top: 0, right: 0, left: 0, bottom: 0
                        }}
                      >
                        <div >
                          <span className="text_inner_circle_left">Team<br />Rating</span>
                          {/* <span className="text_inner_circle_left">Rating</span> */}
                          <p className="value_inner_circle_left">95%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: 6 }}>
                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        value={92}
                        // color={'#0076de'}
                        size={45}
                        thickness={5}
                        style={{
                          color: "#2f5597",
                          zIndex: 1,
                          transform: "rotate(90deg)"
                        }}
                      // className={classes.bottom}
                      />
                      <div
                        style={{
                          background: '#fff',
                          borderRadius: '50%',
                          textAlign: 'center',
                          position: 'absolute',
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          top: 0, right: 0, left: 0, bottom: 0
                        }}
                      >
                        <div >
                          <span className="text_inner_circle_left">Customer<br />Rating</span>
                          {/* <span className="text_inner_circle_left">Rating</span> */}
                          <p className="value_inner_circle_left">92%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: 6 }}>
                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        value={92}
                        // color={'#0076de'}
                        size={45}
                        thickness={5}
                        style={{
                          color: "#2f5597",
                          zIndex: 1,
                          transform: "rotate(90deg)"
                        }}
                      // className={classes.bottom}
                      />
                      <div
                        style={{
                          background: '#fff',
                          borderRadius: '50%',
                          textAlign: 'center',
                          position: 'absolute',
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                          top: 0, right: 0, left: 0, bottom: 0
                        }}
                      >
                        <div >
                          <span className="text_inner_circle_left">Community<br />Rating</span>
                          {/* <span className="text_inner_circle_left">Rating</span> */}
                          <p className="value_inner_circle_left">92%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Circular Progress bar End */}

              {/* Linear Progress bar start */}
              <div>
                <div>
                  <div>
                    <div className="rating_applied_heading" style={{ fontSize: 12 }}>
                      <span style={{ fontSize: 12 }} >APPLIED Rating Distribution</span>
                    </div>
                    {
                      ratings?.rating?.['General Personal Rating'] ? (
                        ratings?.rating?.['General Personal Rating'].slice(0, (ratings?.rating?.['General Personal Rating'].length - 1)).map((item: any, index: number) => {
                          return <div className="rating_main_22" key={index}>
                            <div className="rating_applied_l1">
                              <span style={{ fontSize: 8 }}>{item.name}</span>
                            </div>
                            <div className="rating_applied_rr_left">
                              <div>
                                <LinearProgress
                                  variant="determinate"
                                  value={item.score == null ? 0 : item.score}
                                  // color='blue'
                                  // size={250}
                                  // thickness={3}
                                  style={{
                                    height: 6
                                  }}
                                // className={classes.bottom}
                                />
                                <div
                                  style={{
                                    background: `#c1efff`,
                                    left: `${item.score == null ? 0 : item.score}%`,
                                    height: 6,
                                    display: 'flex', position: 'absolute', alignItems: 'center', top: 0, right: 0, bottom: 0
                                  }}
                                >
                                </div>
                              </div>
                            </div>
                            <span>{item.score == null ? 0 : item.score}%</span>
                          </div>
                        })
                      )
                        : null
                    }
                  </div>
                </div>
              </div>
              {/* Linear Progress bar End */}

              {/* {this.state.userProfile?.attributes?.profile?.data?.attributes ? (
                <Text style={{ color: "#757575" }}>
                  {this.getDomainSkills(this.state.userProfile?.attributes?.profile?.data?.attributes?.domain_skills)}
                </Text>
              ) : null} */}
            </div>
          </div>
          <div style={{ padding: "30px 15px 10px", backgroundColor: '#FFF', display: 'block', width: '75%', position: 'relative', minHeight: 1 }}>
            <div>
              <div className="headline" style={{ height: 80 }} >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <ContactsOutlined
                      translate={undefined}
                      rev={{}}
                      className="header_icon" />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>overview</span>
                </div>
                <hr className="hr_line" />
              </div>
              {/* <div><span className="domine_expert_text" style={{ fontSize: 12, marginBottom: 10 }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span></div> */}
              <div>
                <span className="domine_expert_text" style={{ fontSize: 12, marginBottom: 10 }}>
                  {profile?.description}
                </span>
              </div>
              {/* Circular Progress bar Start */}
              <div>
                <div className="rating_main">
                  {
                    ratings?.rating &&
                    Array.prototype.slice.call(ratings?.rating, 4).map((item: any, index: number) => {
                      return (
                        <div>
                          {item.map((i: any) => {
                            return <div className="rating_view_1">
                              <div style={{ position: 'relative', display: 'inline-flex' }}>
                                <CircularProgress
                                  variant="determinate"
                                  value={i.score}
                                  // color={'#0076de'}
                                  size={120}
                                  thickness={5}
                                  style={{
                                    color: "#2f5597",
                                    zIndex: 1,
                                    transform: "rotate(90deg)"
                                  }}
                                // className={classes.bottom}
                                />
                                <div
                                  style={{
                                    background: '#fff',
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    top: 0, left: 0, right: 0, bottom: 0
                                  }}
                                >
                                  <div >
                                    <span className="text_inner_circle">Leadership</span>
                                    <span className="text_inner_circle">Rating</span>
                                    <p className="value_inner_circle">{i.score}%</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          })}
                        </div>
                      )
                    })
                  }
                  <div className="rating_view_1">
                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        value={98}
                        // color={'#0076de'}
                        size={120}
                        thickness={5}
                        style={{
                          color: "#2f5597",
                          zIndex: 1,
                          transform: "rotate(90deg)"
                        }}
                      // className={classes.bottom}
                      />
                      <div
                        style={{
                          background: '#fff',
                          borderRadius: '50%',
                          textAlign: 'center',
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: 0, left: 0, right: 0, bottom: 0
                        }}
                      >
                        <div >
                          <span className="text_inner_circle">Leadership</span>
                          <span className="text_inner_circle">Rating</span>
                          <p className="value_inner_circle">98%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rating_view_1">
                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        value={95}
                        // color={'#0076de'}
                        size={120}
                        thickness={5}
                        style={{
                          color: "#2f5597",
                          zIndex: 1,
                          transform: "rotate(90deg)"
                        }}
                      // className={classes.bottom}
                      />
                      <div
                        style={{
                          background: '#fff',
                          borderRadius: '50%',
                          textAlign: 'center',
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: 0, left: 0, right: 0, bottom: 0
                        }}
                      >
                        <div >
                          <span className="text_inner_circle">Team</span>
                          <span className="text_inner_circle">Rating</span>
                          <p className="value_inner_circle">95%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rating_view_1">
                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        value={92}
                        // color={'#0076de'}
                        size={120}
                        thickness={5}
                        style={{
                          color: "#2f5597",
                          zIndex: 1,
                          transform: "rotate(90deg)"
                        }}
                      // className={classes.bottom}
                      />
                      <div
                        style={{
                          background: '#fff',
                          borderRadius: '50%',
                          textAlign: 'center',
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: 0, left: 0, right: 0, bottom: 0
                        }}
                      >
                        <div >
                          <span className="text_inner_circle">Customer</span>
                          <span className="text_inner_circle">Rating</span>
                          <p className="value_inner_circle">92%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rating_view_1">
                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress
                        variant="determinate"
                        value={92}
                        // color={'#0076de'}
                        size={120}
                        thickness={5}
                        style={{
                          color: "#2f5597",
                          zIndex: 1,
                          transform: "rotate(90deg)"
                        }}
                      // className={classes.bottom}
                      />
                      <div
                        style={{
                          background: '#fff',
                          borderRadius: '50%',
                          textAlign: 'center',
                          position: 'absolute',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: 0, left: 0, right: 0, bottom: 0
                        }}
                      >
                        <div >
                          <span className="text_inner_circle">Customer</span>
                          <span className="text_inner_circle">Rating</span>
                          <p className="value_inner_circle">92%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Circular Progress bar End */}
              <div className="rating_view_1">
                <div style={{ position: 'relative', display: 'inline-flex' }}>
                  <div className="circle-wrap">
                    <div className="circle">
                      <div className="mask half">
                        <div className="fill">
                          <div className="inside-circle"> 75% </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Linear Progress bar start */}
              <div>
                <div>
                  <div style={{ width: '75%', margin: 'auto' }}>
                    <div className="rating_applied_heading">
                      <span>APPLIED Rating Distribution</span>
                    </div>
                    {
                      ratings?.rating?.['General Personal Rating'] ? (
                        ratings?.rating?.['General Personal Rating'].slice(0, (ratings?.rating?.['General Personal Rating'].length - 1)).map((item: any, index: number) => {
                          return <div className="rating_main_2" key={index}>
                            <div className="rating_applied_l1">
                              <span style={{ fontSize: 14 }}>{item.name}</span>
                            </div>
                            <div className="rating_applied_rr">
                              <div>
                                <LinearProgress
                                  variant="determinate"
                                  value={item.score == null ? 0 : item.score}
                                  // color='blue'
                                  // size={250}
                                  // thickness={3}
                                  style={{
                                    height: 15
                                  }}
                                // className={classes.bottom}
                                />
                                <div
                                  style={{
                                    background: `#c1efff`,
                                    left: `${item.score == null ? 0 : item.score}%`,
                                    height: 15,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    top: 0, right: 0, bottom: 0
                                  }}
                                >
                                </div>
                              </div>
                            </div>
                            <span>{item.score == null ? 0 : item.score}%</span>
                          </div>
                        })
                      )
                        : null
                    }
                  </div>
                </div>
              </div>
              {/* Linear Progress bar End */}

              <div style={{ width: '75%', margin: 'auto', marginTop: 25 }}>
                <div>
                  <Row
                    gutter={12}
                  >
                    <Col className="col1" span={6}>
                      <div>
                        <div className="headerr">
                          <span>Leadership View</span>
                        </div>
                        <div>
                          {
                            ratings?.rating?.Leader && (
                              ratings?.rating?.Leader.slice(0, (ratings?.rating?.Leader.length - 1)).map((item: any, index: number) => {
                                return <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }} key={index} >
                                  <div className="rating_view_11">
                                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                                      <CircularProgress
                                        variant="determinate"
                                        value={item.score == null ? 100 : item.score}
                                        // color={'#0076de'}
                                        size={40}
                                        thickness={4}
                                        style={{
                                          color: item.score == null ? "rgb(193, 239, 255) " : "#2f5597",
                                          zIndex: 1,
                                          transform: "rotate(90deg)"
                                        }}
                                      // className={classes.bottom}
                                      />
                                      <div
                                        style={{
                                          background: '#fff',
                                          borderRadius: '50%',
                                          textAlign: 'center',
                                          position: 'absolute',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          top: 0, right: 0, bottom: 0, left: 0
                                        }}
                                      >
                                        <div >
                                          <p className="percent_text">{item.score == null ? 0 : item.score}%</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="text_span">{item.name}</span>
                                </div>
                              })
                            )
                          }
                        </div>
                      </div>
                    </Col>
                    <Col className="col1" span={6}>
                      <div>
                        <div className="headerr">
                          <span>Team View</span>
                        </div>
                        <div>
                          {
                            ratings?.rating?.Team && (
                              ratings?.rating?.Team.slice(0, (ratings?.rating?.Team.length - 1)).map((item: any, index: number) => {
                                return <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }} key={index} >
                                  <div className="rating_view_11">
                                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                                      <CircularProgress
                                        variant="determinate"
                                        value={item.score == null ? 100 : item.score}
                                        // color={'#0076de'}
                                        size={40}
                                        thickness={4}
                                        style={{
                                          color: item.score == null ? "rgb(193, 239, 255) " : "#2f5597",
                                          zIndex: 1,
                                          transform: "rotate(90deg)"
                                        }}
                                      // className={classes.bottom}
                                      />
                                      <div
                                        style={{
                                          background: '#fff',
                                          borderRadius: '50%',
                                          textAlign: 'center',
                                          position: 'absolute',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          top: 0, right: 0, bottom: 0, left: 0
                                        }}
                                      >
                                        <div >
                                          <p className="percent_text">{item.score == null ? 0 : item.score}%</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="text_span">{item.name}</span>
                                </div>
                              })
                            )
                          }
                        </div>
                      </div>
                    </Col>
                    <Col className="col1" span={6}>
                      <div>
                        <div className="headerr">
                          <span>Customer View</span>
                        </div>
                        <div>
                          {
                            ratings?.rating?.['Personal Customer'] && (
                              ratings?.rating?.['Personal Customer'].slice(0, (ratings?.rating?.['Personal Customer'].length - 1)).map((item: any, index: number) => {
                                return <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }} key={index} >
                                  <div className="rating_view_11">
                                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                                      <CircularProgress
                                        variant="determinate"
                                        value={item.score == null ? 100 : item.score}
                                        // color={'#0076de'}
                                        size={40}
                                        thickness={4}
                                        style={{
                                          color: item.score == null ? "rgb(193, 239, 255) " : "#2f5597",
                                          zIndex: 1,
                                          transform: "rotate(90deg)"
                                        }}
                                      // className={classes.bottom}
                                      />
                                      <div
                                        style={{
                                          background: '#fff',
                                          borderRadius: '50%',
                                          textAlign: 'center',
                                          position: 'absolute',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          top: 0, right: 0, bottom: 0, left: 0
                                        }}
                                      >
                                        <div >
                                          <p className="percent_text">{item.score == null ? 0 : item.score}%</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="text_span">{item.name}</span>
                                </div>
                              })
                            )
                          }
                        </div>
                      </div>
                    </Col>
                    <Col className="col1" span={6}>
                      <div>
                        <div className="headerr">
                          <span>Community View</span>
                        </div>
                        <div>
                          {
                            ratings?.rating?.['Personal Community'] && (
                              ratings?.rating?.['Personal Community'].slice(0, (ratings?.rating?.['Personal Community'].length - 1)).map((item: any, index: number) => {
                                return <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }} key={index} >
                                  <div className="rating_view_11">
                                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                                      <CircularProgress
                                        variant="determinate"
                                        value={item.score == null ? 100 : item.score}
                                        // color={'#0076de'}
                                        size={40}
                                        thickness={4}
                                        style={{
                                          color: item.score == null ? "rgb(193, 239, 255) " : "#2f5597",
                                          zIndex: 1,
                                          transform: "rotate(90deg)"
                                        }}
                                      // className={classes.bottom}
                                      />
                                      <div
                                        style={{
                                          background: '#fff',
                                          borderRadius: '50%',
                                          textAlign: 'center',
                                          position: 'absolute',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          top: 0, right: 0, bottom: 0, left: 0
                                        }}
                                      >
                                        <div >
                                          <p className="percent_text">{item.score == null ? 0 : item.score}%</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="text_span">{item.name}</span>
                                </div>
                              })
                            )
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              {/* experience component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <ScheduleOutlined translate={undefined} rev={{}} style={{ fontSize: 35, marginTop: 7, color: '#fff' }} />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>experience</span>
                </div>
                <hr className="hr_line" />
                <ul className="ul_list">
                  {
                    profile?.experiences && profile?.experiences.map((item: any, index: any) => {
                      return <li key={index}>
                        <div>
                          <p className="left_head">Title : <span className="left_text">{item.title ? item.title : 'null'}</span></p>
                          <p className="left_head">Company : <span className="left_text">{item.company ? item.company : 'null'}</span></p>
                          <p className="left_head">Last Working : <span className="left_text">{item.end_date ? item.end_date : 'null'}</span></p>
                          <p className="left_head">Join Date : <span className="left_text">{item.start_date ? item.start_date : 'null'}</span></p>
                          <p className="left_head">Description : <span className="left_text">{item.description ? item.description : 'null'}</span></p>
                        </div>
                      </li>
                    })
                  }
                </ul>
              </div>
              {/* experience component end */}

              {/* education component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <SchoolIcon style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>education</span>
                </div>
                <hr className="hr_line" />
                <ul className="ul_list">
                  {
                    profile?.graduations && profile?.graduations.map((item: any, index: any) => {
                      return <li key={index}>

                      </li>
                    })
                  }
                </ul>
              </div>
              {/* education component end */}
              {/* academic score component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <ReadOutlined translate={undefined} rev={{}} style={{ fontSize: 35, color: '#fff', marginTop: 7 }} />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>academic score</span>
                </div>
                <hr className="hr_line" />
                <ul className="ul_list">
                  {
                    profile?.graduations && profile?.graduations.map((item: any, index: any) => {
                      return <li key={index}>

                      </li>
                    })
                  }
                </ul>
              </div>
              {/* academic score component end */}
              {/* CERTIFICATIONS component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <ProfileOutlined
                      translate={undefined}
                      rev={{}}
                      className="header_icon" />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>CERTIFICATIONS</span>
                </div>
                <hr className="hr_line" />
                <ul className="ul_list">
                  {
                    profile?.certifications && profile?.certifications.map((item: any, index: any) => {
                      return <li key={index}>

                      </li>
                    })
                  }
                </ul>
              </div>
              {/* CERTIFICATIONS component end */}
              {/* ACHIEVEMENTS component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <ScheduleOutlined
                      translate={undefined}
                      rev={{}}
                      className="header_icon" />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>ACHIEVEMENTS</span>
                </div>
                <hr className="hr_line" />
                <ul className="ul_list">
                  {
                    profile?.achievements && profile?.achievements.map((item: any, index: any) => {
                      return <li key={index}>

                      </li>
                    })
                  }
                </ul>
              </div>
              {/* ACHIEVEMENTS component end */}
              {/* AWARDS & RECOGNITIONS component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <TrophyOutlined
                      translate={undefined}
                      rev={{}}
                      className="header_icon" />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>AWARDS & RECOGNITIONS</span>
                </div>
                <hr className="hr_line" />
                <ul className="ul_list">
                  {
                    profile?.awards && profile?.awards.map((item: any, index: any) => {
                      return <li key={index}>

                      </li>
                    })
                  }
                </ul>
              </div>
              {/* AWARDS & RECOGNITIONS component end */}
              {/* PATENTS component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <AlertOutlined
                      translate={undefined}
                      rev={{}}
                      className="header_icon" />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>PATENTS</span>
                </div>
                <hr className="hr_line" />
                <ul className="ul_list">
                  {
                    profile?.patents && profile?.patents.map((item: any, index: any) => {
                      return <li key={index}>

                      </li>
                    })
                  }
                </ul>
              </div>
              {/* PATENTS component end */}
              {/* OTHER INTERESTS component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <SportsSoccerIcon
                      className="header_icon" />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>OTHER INTERESTS</span>
                </div>
                <hr className="hr_line" />
                <ul className="ul_list">
                  {
                    profile?.interests && profile?.interests.map((item: any, index: any) => {
                      return <li key={index}>

                      </li>
                    })
                  }
                </ul>
              </div>
              {/* OTHER INTERESTS component end */}
              {/* REFERENCES component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <UsergroupAddOutlined
                      translate={undefined}
                      rev={{}}
                      className="header_icon" />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>REFERENCES</span>
                </div>
                <hr className="hr_line" />
                <ul className="ul_list">
                  {
                    profile?.graduations && profile?.graduations.map((item: any, index: any) => {
                      return <li key={index}>

                      </li>
                    })
                  }
                </ul>
              </div>
              {/* REFERENCES component end */}
              {/* GENERAL INFORMATION component start */}
              <div className="headline" >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="overview">
                    <InfoCircleOutlined
                      translate={undefined}
                      rev={{}}
                      className="header_icon" />
                  </div>
                  <span className="domine_expert_text" style={{ color: '#2f5597', marginLeft: 15, textTransform: 'uppercase' }}>GENERAL INFORMATION</span>
                </div>
                <hr className="hr_line" />
                <ul className="ul_list">
                  {
                    profile?.graduations && profile?.graduations.map((item: any, index: any) => {
                      return <li key={index}>

                      </li>
                    })
                  }
                </ul>
              </div>
              {/* GENERAL INFORMATION component end */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  // Customizable Area End
}
