import React from "react";
import {
    Typography,
    LinearProgress,
    Box,
} from "@material-ui/core";
import { Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

// Customizable Area Start
export default function ProfileRatingAppliedProgressBar(props: any) {

    const { title, value, color, description } = props;

    return (
        <React.Fragment>
            <div
                className="profile_rating_flowx"
            >
                <div className="rating_applied_lx">
                    <span style={{ fontSize: 14, fontWeight: 500, color: "#434343" }}>{title}
                        {props?.description && <Tooltip
                            trigger="click"
                            title={props?.description}>
                            <ExclamationCircleOutlined
                                translate={undefined}
                                rev={{}}
                                style={{ marginLeft: "0.3rem", cursor: "pointer", color: "#bbcbd8" }}
                            />
                        </Tooltip>}
                    </span>
                </div>
                <div className="rating_applied_rx">
                    <div
                        style={{
                            height: "4px",
                            width: "92%",
                            position: "relative"
                        }}
                    >
                        <Box>
                            <LinearProgress
                                variant="determinate"
                                value={value}
                                color={color}
                                style={{
                                    color: color,
                                    backgroundColor: color
                                }}
                            />
                            <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                style={{
                                    marginRight: '-25px',
                                    backgroundColor: `#dfdfdf`,
                                    left: `${(value)}%`
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    component="div"
                                    className="rating_applied_r_line_css"
                                    style={{
                                        backgroundColor: color,
                                        width: "25px",
                                        height: "25px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <span
                                        style={{ fontSize: "9px" }}
                                    >
                                        {Math.round(value)}%
                                    </span>
                                </Typography>
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )

}

// Customizable Area End
