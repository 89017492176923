import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import SendBird from 'sendbird';
import { Button, Empty } from 'antd';
import { getStorageData, setStorageData, isEmpty } from "../../../framework/src/Utilities";

// Customizable Area Start
import axios from "axios";
import React, { createRef } from "react";
import { Avatar, message } from 'antd';
import _debounce from 'lodash/debounce';
import { Redirect } from "react-router-dom";
import { Alert, BackHandler } from "react-native";
import { RouterProps } from "react-router";
// @ts-ignore
import { baseURL } from "../../../framework/src/config.js"
// Customizable Area End

export const configJSON = require("./config");
// import SendBird from "sendbird";
// import { getStorageData } from "../../../framework/src/Utilities";
// import PushNotification from "react-native-push-notification";
// import firebase from "@react-native-firebase/app";
// Customizable Area End

// const config = require('../../../framework/src/config.js')

export const appId =
  window.location.host === "linkedinlikeapp-39389-react-native.b39389.stage.us-east-1.aws.svc.builder.ai"
    ? "C46CB398-030E-49E1-9540-A2F6E662817D" :
    window.location.host === "linkedinlikeapp-39389-react-native.b39389.dev.eastus.az.svc.builder.cafe"
      ? 'C46CB398-030E-49E1-9540-A2F6E662817D' :
      window.location.host === "localhost:3000" ?
        'C46CB398-030E-49E1-9540-A2F6E662817D' : "63ED24B3-8113-41A9-B336-5FF66EB9450B";
export const sb = new SendBird({
  appId: appId,
  localCacheEnabled: true
});
export const api_Token =
  window.location.host === "linkedinlikeapp-39389-react-native.b39389.stage.us-east-1.aws.svc.builder.ai" ?
    "ab6a3e950583de9ee4200dac0274cd85cdcd6b26" :
    window.location.host === "linkedinlikeapp-39389-react-native.b39389.dev.eastus.az.svc.builder.cafe" ?
      "ab6a3e950583de9ee4200dac0274cd85cdcd6b26" :
      window.location.host === "localhost:3000" ?
        'ab6a3e950583de9ee4200dac0274cd85cdcd6b26' : "aea33323ff912a49b00db0c3ff46e685daf3b870";
console.log(window.location.host, "window.location.origin", appId, api_Token)
console.log(window.location)
// var sb = config.sb

// const firebaseConfig = {
//   apiKey: "AIzaSyD5znbK_bxSVQ1IDW4HGePhmU-UBY0I5go",
//   authDomain: "thermal-talon-316017.firebaseapp.com",
//   projectId: "thermal-talon-316017",
//   storageBucket: "thermal-talon-316017.appspot.com",
//   messagingSenderId: "1347984169",
//   appId: "1:1347984169:web:bcb2baf3d77690deef7538",
//   measurementId: "G-3Y65XC3Q2N"
// };
// SendBirdCall.init(appId);

export interface Props extends RouterProps {
  navigation: any;
  id: string;
  // Customizable Area Start
  route?: any;
  // Customizable Area End
}
interface IOption {
  label: any
  options: any

}

export interface S {
  // Customizable Area Start
  isloader: boolean;
  failureModal: boolean;
  modalContent: any;
  value: string;
  openChannel: any;
  List: any;
  userId: string;
  searchvalue: string;
  searchData: any;
  isLoading: boolean;
  newOwnMessage: any;
  allMessages: any;
  ownMessages: any;
  replyMessages: any;
  activeUserTab: any;
  userProfile: any;
  userIsOnline: boolean;
  unreadMessages: any;
  GroupChannel: any;
  messageCollection: any;
  offlineMessageList: any;
  customchatHandler: boolean;
  textHeadingModal: any;
  subHeadingModal: any;
  modalType: any;
  searchTxt: any;
  option: any;
  searchLoading: boolean;
  MessageList: any;
  chatImages: any;
  videoArrayList: any;
  AllMedia: any;
  loadingChatImg: boolean;
  popupSendFile: boolean;
  sendFileLoad: boolean;
  closeFileLoad: boolean;
  isLoadingReport: boolean;
  showSuccessCustomChatHandler: boolean;
  deleteModalVisibility: boolean;
  deleteMessageData: any;
  deleteLoad: any;
  isEmojiPickerEnable: boolean;
  radioValues: any;
  loadMessages: boolean;
  active_userId: any;
  redirect: boolean;
  uploadImgStatus: string;
  scrollChat: boolean;
  MobileResponsiveWidth: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatScreenController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAcceptedConnectionsId: any;
  getPendingConnectionsId: any;
  getDefaultCategoriesId: any;
  reportAccountId: any
  unsubscribe: any;
  backHandler: any;
  contentRef = createRef<any>();
  messagesEnd = React.createRef<null | HTMLDivElement>()
  onreloadMessasges = React.createRef<null | HTMLDivElement>()
  customClose = createRef<any>();

  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      uploadImgStatus: '',
      isloader: false,
      failureModal: false,
      modalContent: {
        title: "",
        content: "",
      },
      value: "",
      openChannel: '',
      List: [],
      userId: '',
      searchvalue: "",
      searchData: [],
      isLoading: true,
      newOwnMessage: "",
      allMessages: [],
      ownMessages: [],
      replyMessages: [],
      activeUserTab: { name: 'test1', msg: 'hii', time: '10:21AM' },
      userProfile: {},
      userIsOnline: false,
      unreadMessages: 0,
      GroupChannel: null,
      messageCollection: [],
      offlineMessageList: [],
      customchatHandler: false,
      textHeadingModal: '',
      subHeadingModal: '',
      modalType: '',
      searchTxt: '',
      option: [],
      searchLoading: false,
      MessageList: [],
      chatImages: [],
      videoArrayList: [],
      AllMedia: [],
      loadingChatImg: false,
      popupSendFile: false,
      sendFileLoad: false,
      closeFileLoad: false,
      isLoadingReport: false,
      showSuccessCustomChatHandler: false,
      deleteModalVisibility: false,
      deleteMessageData: {},
      deleteLoad: false,
      isEmojiPickerEnable: false,
      radioValues: null,
      loadMessages: false,
      active_userId: '',
      redirect: false,
      scrollChat: true,
      MobileResponsiveWidth: false,
      // Customizable Area End
    };
    window.addEventListener("resize", this.updateWidth);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // this.debounceFn = this.debounceFn.bind(this);
    // Customizable Area End
  }
  updateWidth = () => {
    let width = window.innerWidth
    // console.log(width, "width")
    if (width < 768) {
      this.setState({
        MobileResponsiveWidth: true
      });
    } else {
      this.setState({
        MobileResponsiveWidth: false
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    // console.log("responseJson rcvd = ", responseJson);
    // console.log("errorReponse rcvd = ", errorReponse);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId && responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getPendingConnectionsId) {
          console.log(
            "chatslist pendingConnections ",
            responseJson, this.state.searchData.concat(responseJson.data)
          );
          if (this.state.searchTxt?.length > 0) {
            this.setState({
              isloader: false, searchLoading: false,
              option: [
                {
                  label: `results for ${this.state.searchTxt}`,
                  options: [this.renderItem(responseJson, 'response', 'value'),]
                }
              ],
            });
            this.setState({
              // pendingConnections: responseJson.data,
              // filteredList: responseJson.data,
              // isPendingNetworkLoader: false,
              searchLoading: false,
              // searchData: this.state.searchData.concat(responseJson.data)
            });
          } else {
            this.setState({
              isloader: false, searchLoading: false,
              option: [
                {
                  label: `results for ${this.state.searchTxt}`,
                  options: [this.renderItem([], 'response', 'value'),]
                }
              ],
            });
          }
        } else if (apiRequestCallId === this.getAcceptedConnectionsId) {
          console.log(
            "chatslist acceptedConnections ",
            responseJson, this.state.searchData.concat(responseJson.data)
          );
          if (this.state.searchTxt?.length > 0) {
            this.setState({
              isloader: false, searchLoading: false,
              option: [
                {
                  label: `results for ${this.state.searchTxt}`,
                  options: [this.renderItem(responseJson, 'response', 'value'),]
                }
              ],
            });
            this.setState(
              {
                // acceptedConnections: responseJson.data,
                // filteredList: responseJson.data,
                // isAcceptedNetworkLoader: false,
                searchLoading: false,
                searchData: this.state.searchData.concat(responseJson.data)
              }
            );
          } else {
            this.setState({
              isloader: false, searchLoading: false,
              option: [
                {
                  label: `results for ${this.state.searchTxt}`,
                  options: [this.renderItem([], 'response', 'value'),]
                }
              ],
            });
          }
        }
      } else if (apiRequestCallId === this.reportAccountId) {
        // console.log("responseJson reportAccountId = ", responseJson);
        this.setState(
          {
            isLoadingReport: false,
            customchatHandler: false,
            showSuccessCustomChatHandler: true,
            modalContent: {
              title: "Report has been submitted",
              content: "",
            },
          });
      } else {
        console.log(
          "chatslist Connections ",

          // responseJson, this.state.searchData.concat(responseJson.data)
        );
        this.setState({
          isloader: false, searchLoading: false,
          searchData: [],
          option: [
            {
              label: `results for ${this.state.searchTxt}`,
              options: [this.renderItem(responseJson, 'response', 'value'),]
            }
          ],
        });

        // this.parseApiErrorResponse(responseJson);
      }
    } else {
      // console.log("in else of another differnt thing", errorReponse);
      this.setState({ isloader: false, searchLoading: false });
    }
    // Customizable Area End
  }

  renderItem = (response: any, searchPage: any, value: any) => ({
    label: (
      <>
        {response?.length === 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
            {response.slice(0, 6).map((item: any) => {
              // console.log(item, 'demo***')
              return (
                <div
                  style={{
                    margin: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >

                  <div style={{ display: 'flex', alignItems: 'center', }}>

                    <span style={{ marginLeft: 8 }}>{item.account.data.attributes.role === "business" ? item.account.data.attributes.company_name :
                      item.account.data.attributes.full_name}</span>
                    {item.account.data.attributes.role == 'business' &&
                      <span style={{ marginLeft: 8, opacity: 0.6, fontSize: 12.5 }}>{
                        item.account.data.attributes?.profile?.data?.attributes?.business_industry
                      }</span>}
                    {item.account.data.attributes.role == 'personal' &&
                      <>
                        <span style={{ marginLeft: 8, opacity: 0.6, fontSize: 12.5 }}>{
                          item.account.data.attributes?.profile?.data?.attributes?.designation?.title
                        }</span>
                      </>
                    }
                  </div>
                  <div>
                    <span style={{ marginRight: 8 }}>
                      {item.account.data.attributes.role}
                    </span>
                    <Avatar
                      size="small"
                      src={item.account.data.attributes.photo}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <>
            <span style={{ textAlign: 'center' }}>No Data Found</span>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </>
        )}

        {response.length > 5 && (
          <div style={{ textAlign: 'center' }}>
            <Button type="link" onClick={() => {
              // props.history.push({ pathname: '/search', state: { "searchResults": searchPage?.data, "searchTxt": value } });
              // window.location.reload();
            }}>See All</Button>
          </div>
        )

        }
      </>
    ),
  })

  // Customizable Area Start
  backAction = () => {
    this.props.navigation.goBack();
    return true;
  };

  scrollToBottom = () => {
    if (this.messagesEnd?.current != null) {
      this.messagesEnd.current.scrollIntoView({
        behavior: "smooth", block: 'end',
        inline: 'nearest'
      });
    }
  }

  onReLoad = () => {
    if (this.onreloadMessasges?.current != null) {
      // console.log(this.onreloadMessasges.current.scrollTo(0, 0))
      // if(this.onreloadMessasges.current.scrollTo(0, 0)){

      // };
    }
  }

  // componentDidMount() {
  // }

  componentDidUpdate() {
    if (this.state.scrollChat) {
      this.scrollToBottom();
    }
    // this.onReLoad();
  }

  async componentDidMount() {
    this.updateWidth()
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false });
    } else {
      this.setState({ redirect: true });
    }

    // console.log(,'chat__location')
    let active_userId: any = this.props.history.location?.state;
    // console.log("11111111111", this.props.history)
    if (active_userId) {
      this.setState({ active_userId: active_userId.userId })
      // console.log("111111111111", this.state.active_userId)
    }
    await getStorageData("userProfile").then((res) => {
      // console.log("userProfile res-->", res, active_userId);
      if (res) {

        // // if(active_userId){
        // this.viewUser(this.state.active_userId.toString(), res.attributes?.profile?.data?.attributes?.account_id);
        // }else{
        this.getConnections(res.attributes?.profile?.data?.attributes?.account_id);
        // }
        this.setState({ userProfile: res });
        this.scrollToBottom();
      }
    });

    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 1000);

    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (this.state.isEmojiPickerEnable && this.customClose.current && !this.customClose.current.contains(e.target)) {
        this.setState({ isEmojiPickerEnable: false })
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)
  }

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }

  onToggleEmojiWindow = () => {
    const { isEmojiPickerEnable } = this.state;
    this.setState({ isEmojiPickerEnable: !isEmojiPickerEnable });
  };

  viewUser = async (friendId: any, userId: any, channelUrl?: any) => {
    // console.log(friendId, userId, 'friendRes__')
    // try {
    return await fetch("https://api-" + appId + ".sendbird.com/v3/users/" + friendId,
      {
        method: 'GET',
        headers: {
          "Accept": "application/json",
          "Api-Token": api_Token,
          Authorization:
            "Bearer " +
            api_Token
        }
      },
    ).then((data) => data.json()).then((friendRes) => {
      console.log(friendRes, 'friendRes__12', this.state.activeUserTab);
      if (friendRes) {
        if (channelUrl) {
          this.readUserMessages(channelUrl)
        }
        this.setState({
          // activeUserTab: {
          //   name: userDetails?.nickname,
          //   msgType: groupList[0]?.lastMessage?.messageType,
          //   msg: groupList[0]?.lastMessage?.messageType === 'file' ? 'Image' : groupList[0]?.lastMessage?.message,
          //   channelUrl: groupList[0]?.url,
          //   myMutedState: userDetails?.myPushTriggerOption,
          //   avatar: userDetails?.plainProfileUrl,
          //   metaData: userDetails?.metaData?.designation,
          //   createdAt: groupList[0]?.lastMessage?.createdAt,
          //   userId: userDetails?.userId
          // }
          activeUserTab: {
            name: friendRes?.nickname,
            msgType: this.state.activeUserTab?.msgType,
            msg: this.state.activeUserTab?.msg,
            channelUrl: this.state.activeUserTab?.channelUrl,
            myMutedState: friendRes?.myPushTriggerOption ? friendRes?.myPushTriggerOption : this.state.activeUserTab?.myMutedState,
            avatar: friendRes.profile_url,
            metaData: friendRes?.metadata?.designation,
            createdAt: friendRes?.created_at,
            userId: friendRes?.user_id
          },
        })

        this.setState({
          userIsOnline: friendRes?.is_online,
        })
        return this.createChannel(userId, friendRes);
      }
    }).catch((err) => {
      // console.log(err, 'view user error friendRes__', friendId);
    });
  }

  readUserMessages = (channelUrl: any) => {
    sb.markAsReadWithChannelUrls([channelUrl], (mess, err) => {
      if (err) {
        console.log(err)
      }
      // console.log("markAsReadAll mess, err", mess, err)
      // this.setState({
      //     showSuccessAlertModal: true
      //   });
      // *********
      setTimeout(() => {
        this.setState(
          {
            isLoadingReport: false,
            customchatHandler: false,
            showSuccessCustomChatHandler: true,
            modalContent: {
              title: "Report has been submitted",
              content: "",
            },
          });
      }, 1000)
    })
  }


  getConnections = async (id: any) => {
    // console.log(this.state.active_userId,'friendRes__')
    await sb.connect(id.toString(), api_Token, function (user, error) {
      if (error) {
        return;
      }
      // console.log(user, id, 'get connect')
    });
    var listQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    listQuery.includeEmpty = true;
    listQuery.memberStateFilter = "joined_only"; // 'all', 'joined_only', 'invited_only', 'invited_by_friend', and 'invited_by_non_friend'
    listQuery.order = "latest_last_message"; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
    listQuery.limit = 15; // The value of pagination limit could be set up to 100.

    // console.log(listQuery, 'create GroupChannel list')
    if (listQuery.hasNext) {
      listQuery.next((groupList: any, error) => {
        // sb.markAsDelivered()
        if (error) {
          // Handle error.
          return;
        }
        // var user = sb.
        // console.log(groupList, 'list query when create group')
        if (groupList?.length > 0) {
          // if (this.state.active_userId) {
          // console.log("ssssssss", this.state.active_userId, id)
          this.viewUser(this.state.active_userId?.toString(), id);
          // } else {
          let nextid: any;
          groupList.map((item: any, index: any) => {
            let abc = item.members?.filter((e: any) => e.userId?.toString() === this.state.active_userId?.toString());
            if (abc.length > 0) {
              nextid = index
              // console.log("44444", abc[0], index, nextid)
            }
            // userDetails=item.members?.filter((e: any) => e.userId?.toString() === this.state.active_userId?.toString())
          })
          let userDetails = groupList[nextid]?.members?.filter((e: any) => e.userId?.toString() !== id.toString())[0];
          // console.log('groupList__', userDetails, groupList);
          this.setState({
            List: groupList, GroupChannel: groupList[0],
            // activeUserTab: {
            //   name: userDetails?.nickname,
            //   msgType: groupList[0]?.lastMessage?.messageType,
            //   msg: groupList[0]?.lastMessage?.messageType === 'file' ? 'Image' : groupList[0]?.lastMessage?.message,
            //   channelUrl: groupList[0]?.url,
            //   myMutedState: userDetails?.myPushTriggerOption,
            //   avatar: userDetails?.plainProfileUrl,
            //   metaData: userDetails?.metaData?.designation,
            //   createdAt: groupList[0]?.lastMessage?.createdAt,
            //   userId: userDetails?.userId
            // }
          });
          // this.viewUser(userDetails?.userId?.toString(), id);
          // }
          this.messageUpdates(id);
          //  if(this.state.active_userId){
          //   this.viewUser(this.state.active_userId,id);
          //  }else{
          //    this.viewUser(this.state.active_userId ? this.state.active_userId?.userId?.toString() : userDetails?.userId?.toString(),id);
          //  }
        } else {
          this.setState({ List: groupList });
        }
      });
    } else {
      // console.log(this.state.active_userId, 'friendRes__')
      // if(this.state.active_userId?.userId){
      //   this.viewUser(this.state.active_userId?.userId?.toString(),id);
      // }
    }

  }

  muteUnMuteHandler = async () => {
    // console.log(this.state.activeUserTab?.userId,'mute')
    if (this.state.activeUserTab?.myMutedState == 'off') {
      // Mute a user.
      let response = await this.state.GroupChannel.setMyPushTriggerOption(this.state.GroupChannel.myPushTriggerOption == 'off' ? 'default' : 'off')
      // let response = await this.state.GroupChannel?.muteUserWithUserId(this.state.activeUserTab?.userId.toString());
      if (response) {
        // console.log(response, 'mute')
      } else {
        // console.log(response, 'mute')
      }
      // *********
      setTimeout(() => {
        this.setState(
          {
            isLoadingReport: false,
            customchatHandler: false,
            showSuccessCustomChatHandler: true,
            activeUserTab: {
              name: this.state.activeUserTab?.name,
              msgType: this.state.activeUserTab?.msgType,
              msg: this.state.activeUserTab?.msg,
              channelUrl: this.state.activeUserTab?.channelUrl,
              myMutedState: 'dafault',
              avatar: this.state.activeUserTab?.avatar,
              metaData: this.state.activeUserTab?.metaData,
              createdAt: this.state.activeUserTab?.createdAt,
              userId: this.state.activeUserTab?.userId
            },
            modalContent: {
              title: "Report has been submitted",
              content: "",
            },
          });
      }, 1000)
    }
    else {
      // Unmute a user.
      let response = await this.state.GroupChannel.setMyPushTriggerOption(this.state.GroupChannel.myPushTriggerOption == 'off' ? 'default' : 'off')
      // let response = await this.state.GroupChannel?.unmuteUserWithUserId(this.state.activeUserTab?.userId.toString());
      if (response) {
        console.log(response, 'mute')
      } else {
        // console.log(response, 'mute')
      }
      // *********
      setTimeout(() => {
        this.setState(
          {
            isLoadingReport: false,
            customchatHandler: false,
            showSuccessCustomChatHandler: true,
            activeUserTab: {
              name: this.state.activeUserTab?.name,
              msgType: this.state.activeUserTab?.msgType,
              msg: this.state.activeUserTab?.msg,
              channelUrl: this.state.activeUserTab?.channelUrl,
              myMutedState: 'off',
              avatar: this.state.activeUserTab?.avatar,
              metaData: this.state.activeUserTab?.metaData,
              createdAt: this.state.activeUserTab?.createdAt,
              userId: this.state.activeUserTab?.userId
            },
            modalContent: {
              title: "Report has been submitted",
              content: "",
            },
          });
      }, 1000)
    }
  }

  async getPendingConnections() {
    this.setState({
      isloader: true
    })
    const header = {
      "Content-Type": configJSON.APPLICATIONJSONContentType,
      token: await localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPendingConnectionsId = requestMessage.messageId;

    let endpoint = configJSON.getNetConnectionEndPoint + "?status=pending";
    if (this.state.searchTxt) {
      endpoint = endpoint + `&search=${this.state.searchTxt}`;
    }

    // console.log("endpoint network pending = ", endpoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    //   requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),httpBody);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GETMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAcceptedConnections = async () => {
    this.setState({
      isloader: true
    })
    const header = {
      "Content-Type": configJSON.APPLICATIONJSONContentType,
      token: await localStorage.getItem("token"),
    };

    let endpoint = configJSON.getNetConnectionEndPoint + "?status=accepted";
    if (this.state.searchTxt) {
      endpoint = endpoint + `&search=${this.state.searchTxt}`;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAcceptedConnectionsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    //   requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),httpBody);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GETMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async componentWillUnmount() {
    // this.backHandler.remove();
    // BackHandler.removeEventListener("hardwareBackPress", this.backAction);
    // this.unsubscribe();
  }

  callDefaultApis() {
    this.setState(
      {
        // pendingConnections: [],
        // filteredList: [],
        // acceptedConnections: [],
        searchLoading: true,
        searchData: [],
      },
      async () => {
        await this.getAcceptedConnections();
        await this.getPendingConnections();
      }
    );
  }

  handleSubmit = (data: any) => {
    // console.log('send message', data);
    let msgList: any = [...this.state.MessageList];
    let newMsg: any = {
      _sender:
        { userId: this.state?.userProfile.attributes?.profile?.data?.attributes?.account_id },
      messageType: 'user',
      message: data,
      createdAt: new Date()
    }
    msgList.push(newMsg);
    // console.log('send message asfter', msgList);
    return this.sendMessage(data);
    // return this.setState({ MessageList: msgList, newOwnMessage: "" });
  }

  formatAMPM = (date: any) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  // create channnel
  async createChannel(userId: any, friendRes: any) {
    // console.log(friendRes, 'friendRes')
    // await sb.connect(userId.toString(), function (user, error) {
    //   if (error) {
    //     return;
    //   }
    // });
    var params = new sb.GroupChannelParams();
    params.isPublic = false;
    params.isEphemeral = false;
    params.isDistinct = true;
    params.isSuper = false;
    // params. = false;
    params.addUserIds([userId.toString(), friendRes.user_id]);
    params.operatorUserIds = [userId.toString(), friendRes.user_id];   // Or .operators(Array<User>)
    // params.name = NAME;
    // params.channelUrl = UNIQUE_CHANNEL_URL; // In a group channel, you can create a new channel by specifying its unique channel URL in a 'GroupChannelParams' object.
    // params.coverImage = FILE;       // Or .coverUrl = COVER_URL;
    // params.data = DATA;
    // params.customType = CUSTOM_TYPE;

    sb.GroupChannel.createChannel(params, (groupChannel, error) => {
      // console.log("GroupChannel.createChannel", groupChannel, error);
      // console.log("GroupChannel.createChannel",groupChannel, error, groupChannel.getUndeliveredMemberCount())
      if (error) {
        // Handle error.
        // console.log(error)
      }

      // groupChannel.members;
      this.setState({ GroupChannel: groupChannel, unreadMessages: groupChannel.unreadMessageCount })
      // const channelUrl = groupChannel?.url;
      // sb.markAsReadAll((mess, err) => {
      //   console.log("markAsReadAll mess, err", mess, err)
      // })

      // sb.addFriends(['1685'], (friends, error)=>{
      //   console.log("friends, error",friends, error)
      // })
      // var channelHandler = new sb.ChannelHandler();
      this.getMessages(groupChannel)
    });
  }

  // getMesssages
  async getMessages(GroupChannel: SendBird.GroupChannel) {
    const listQuery = GroupChannel.createPreviousMessageListQuery();
    // listQuery.replyType = REPLY_TYPE;
    listQuery.includeThreadInfo = true;
    listQuery.includeParentMessageInfo = true;
    // console.log(listQuery, 'list query')
    this.setState({ messageCollection: listQuery })
    // const query = GroupChannel.createPreviousMessageListQuery();
    const messages = await listQuery.load(10, true);
    if (messages) {
      console.log(messages, 'messages')
      this.setState({ MessageList: messages.reverse() })
    }
  }

  handleScroll = (e: any) => {
    let element = e.target;
    if (element.scrollTop === 0) {
      // console.log(element.scrollTop, 'element.scrollTop', element.scrollHeight, 'element.scrollHeight', element.scrollHeight - element.scrollTop)
      this.LoadMoreMessageData();
    }
  }

  LoadMoreMessageData = () => {
    return this.state.messageCollection.load(10, true, (messages: any, error: any) => {
      if (error) {
        return;
      }
      // console.log(error,'load more')
      let data = messages.reverse();
      this.setState({ MessageList: data.concat(this.state.MessageList), loadMessages: false, scrollChat: false })
    });
  }

  // send messages
  async sendMessage(data: any) {
    let value = data.trim();
    // console.log("Is connected?", value, state.isConnected ,this.state.GroupChannel);

    // NetInfo.fetch().then(async state => {
    // console.log("Is connected?", value, state.isConnected ,this.state.GroupChannel);
    // if (state.isConnected) {
    //   console.log('send messages from online')
    let offmessage = this.state.offlineMessageList;
    offmessage.push({ type: 'text', message: value, isMessage: "offline" })

    const params = new sb.UserMessageParams();
    params.message = value;
    params.mentionType = 'users';
    params.translationTargetLanguages = ['en', 'de'];
    params.pushNotificationDeliveryOption = 'default';


    this.state.GroupChannel.sendUserMessage(params, async (userMessage: any, error: any) => {
      // console.log("linklinklinklink", userMessage, error)
      if (error) {
        return;
      }
      let msgList: any = [...this.state.MessageList];
      msgList.push(userMessage);
      this.setState({ MessageList: msgList, value: '', offlineMessageList: [], unreadMessages: 0, isEmojiPickerEnable: false, newOwnMessage: "" })
      // this.setState({MessageList: [userMessage, ...this.state.MessageList], value:'', offlineMessageList:[], unreadMessages:0})  
      //       if(!this.state.userIsOnline){
      //   const link = await dynamicLinks().buildShortLink({
      //     link: config.weburl+'/chat?id='+this.state.SenderData.account.data.attributes.account_id+'&name='+this.state.headerTitle+'&subTitle='+this.state.subTitle,
      //     domainUriPrefix: 'https://appliedapp.page.link',
      //   });
      //   console.log("linklinklinklink",link) 
      //   this.sendMail(link)
      // }
    });
    // }
    // else {
    //   console.log('send messages from offline')
    //   // let previousData=await getStorageData("messageList")
    //   // previousData = JSON.parse(previousData)
    //   // let userid=this.props.route.params.id
    //   // let data = previousData || {}
    //   // data[userid] = {messages:[]}
    //   // data[userid]={groupChannel: this.state.GroupChannel.url, messages:data[userid].messages.concat([{type: 'text', message:this.state.value}]) }
    //   // console.log("datadatadatadata",data)
    //   // setStorageData("messageList",JSON.stringify(data))
    //   // let offmessage = this.state.offlineMessageList
    //   // offmessage.push({type:'text',message: this.state.value, isMessage: "offline"})
    //   // this.setState({offlineMessageList:offmessage, value:'', unreadMessages:0})
    // }
    // });
  }

  debounceFn = (data: any) => {
    return _debounce(() => this.callDefaultApis, 700);

    // this.setState({searchLoading:true})
    // setTimeout(()=>{
    // this.setState({searchLoading:false})
    // },3000)
  }

  muteTheUser = async () => {
    let response = await this.state.GroupChannel.setMyPushTriggerOption(this.state.GroupChannel.myPushTriggerOption == 'off' ? 'default' : 'off')
    // console.log("this.state.GroupChannel", response)
    // if(response)
    // {
    //   this.setState({
    //       showSuccessAlertModal: true
    //     });
    // }
  }

  markAsUnreadTheUserChat = async () => {

    sb?.markAsReadAll((mess, err) => {
      if (err) {
        // console.log(err)
      }
      // console.log("markAsReadAll mess, err", mess, err)
      // this.setState({
      //     showSuccessAlertModal: true
      //   });
      // *********
      setTimeout(() => {
        this.setState(
          {
            isLoadingReport: false,
            customchatHandler: false,
            showSuccessCustomChatHandler: true,
            modalContent: {
              title: "Report has been submitted",
              content: "",
            },
          });
      }, 1000)
    })
  }

  messageUpdates(id: any) {
    var channelHandler = new sb.ChannelHandler();

    channelHandler.onChannelChanged = async (channel: any) => {
      channel.markAsDelivered()
      let index = this.state.List.findIndex((e: any) => e.url === channel.url)
      // console.log(index, 'index handler')
      if (index >= 0) {
        this.state.List.splice(index, 1)
      }
      let data = [channel, ...this.state.List]
      // console.log("channel, message chat screen", index, data, this.state.List);
      this.setState({ List: data }, () => {
        // console.log(this.state.List, 'onChannelChanged');
      })
    };

    channelHandler.onTypingStatusUpdated = async channel => {
      var members = channel.getTypingMembers();
      // console.log("groupChannel.getTypingMembers", members, channel)
      let index = this.state.List.findIndex((e: any) => e.url === channel.url)
      if (index >= 0) {
        this.state.List[index].typing = members.length > 0 ? true : false
      }
      this.setState({ List: this.state.List }, () => {
        // console.log(this.state.List, 'onTypingStatusUpdated')
      })
      // Refresh typing status of members within channel.
    }

    channelHandler.onMessageReceived = (channel, message) => {
      // console.log("onMessageReceived chat details", channel, message);
      if (channel.url === this.state.GroupChannel.url) {
        // channel.markAsDelivered()
        let data = this.state.MessageList;
        data.push(message);
        this.setState({
          MessageList: data,
          // MessageList: [message, ...this.state.MessageList],
          unreadMessages: 0
        })
      }
    };

    channelHandler.onMessageDeleted = (channel, message) => {
      if (channel.url === this.state.GroupChannel.url) {
        let index = this.state.MessageList.findIndex((e: any) => e.messageId === message)
        this.state.MessageList.splice(index, 1)
        this.setState({ MessageList: this.state.MessageList })
      }
    };
    // onDeliveryReceiptUpdated()

    this.state.GroupChannel.onDeliveryReceiptUpdated = async (channel: any) => {
      // console.log("onDeliveryReceiptUpdated", channel);
      channel.markAsDelivered()
    }

    channelHandler.onReadReceiptUpdated = async channel => {
      // console.log("onReadReceiptUpdated", channel);
      this.setState({})
    }

    //   channelHandler.onTypingStatusUpdated = async channel => {
    //     var members = channel.getTypingMembers();
    //     console.log("groupChannel.getTypingMembers",members)
    //     // this.setState({typingIndicator: members.length>0 ? true : false })
    //     // Refresh typing status of members within channel.
    // };

    sb.addChannelHandler(`${id}`, channelHandler);
  }

  async sendfileMessage() {
    // let offmessage = this.state.offlineMessageList
    // offmessage.push({type:'file',message: response, isMessage: "offline"})
    // console.log("offmessage",offmessage)
    // this.setState({offlineMessageList:offmessage})
    // NetInfo.fetch().then(async state => {
    // console.log("Is connected?", state.isConnected ,this.state.GroupChannel);
    // if(state.isConnected){
    // ****
    let msgList: any = [...this.state.MessageList];
    let newMsg: any = {
      _sender:
        { userId: this.state?.userProfile.attributes?.profile?.data?.attributes?.account_id },
      messageType: 'file',
      url: this.state.AllMedia[0].filePath,
      type: this.state.AllMedia[0].type
    }
    msgList.push(newMsg);
    // console.log('send message asfter', msgList);

    // **************
    let filedata = {
      filePath: this.state.AllMedia[0]?.filePath,
      uri: this.state.AllMedia[0]?.uri,
      type: this.state.AllMedia[0]?.type ? this.state.AllMedia[0]?.type : this.state.AllMedia[0]?.mime ? this.state.AllMedia[0]?.mime : "image/jpeg",
      name: this.state.AllMedia[0]?.fileName ? this.state.AllMedia[0]?.fileName : "Test",
      data: this.state.AllMedia[0]?.data,
    }
    // console.log(filedata, 'params 1')
    const params = new sb.FileMessageParams();
    params.file = filedata.data;             // Or .fileUrl  = FILE_URL (You can also send a file message with a file URL.)
    // params.fileName = this.state.AllMedia[0]?.fileName ? this.state.AllMedia[0]?.fileName : "Test";
    params.fileSize = 10;
    params.thumbnailSizes = [{ maxWidth: 100, maxHeight: 100 }, { maxWidth: 200, maxHeight: 200 }]; // Add the maximum sizes of thumbnail images (allowed number of thumbnail images: 3).
    // params.mimeType = this.state.AllMedia[0]?.type ? this.state.AllMedia[0]?.type : this.state.AllMedia[0]?.mime ? this.state.AllMedia[0]?.mime : "image/jpeg";
    params.pushNotificationDeliveryOption = 'default';  // Either 'default' or 'suppress'

    // console.log(params,'params 2')

    this.state.GroupChannel?.sendFileMessage(params, async (userMessage: any, error: any) => {
      if (error) {
        // console.log(error, 'error in sendFileMessage')
        // Handle error.
        return;
      }
      // console.log(userMessage, 'sendFileMessage');
      let getUndeliveredMessageCount = await this.state.GroupChannel.getUndeliveredMemberCount(userMessage)
      // console.log("getUndeliveredMessageCount", getUndeliveredMessageCount)

      // console.log(this.state.AllMedia[0],'files');
      this.setState({ MessageList: [userMessage, ...this.state.MessageList], offlineMessageList: [], value: '', unreadMessages: 0 })
      // if(!this.state.userIsOnline){
      //   const link = await dynamicLinks().buildShortLink({
      //     link: config.weburl+'/chat/'+this.state.SenderData.account.data.attributes.account_id,
      //     domainUriPrefix: 'https://appliedapp.page.link',
      //   });
      //   this.sendMail(link)
      // }
      this.setState({
        sendFileLoad: false,
        MessageList: msgList, newOwnMessage: "",
        popupSendFile: false,
        AllMedia: [], chatImages: [], videoArrayList: [],
        offlineMessageList: [], value: '', unreadMessages: 0
      });
    });
    // **************
    // }
    // else{
    // let previousData=await getStorageData("messageList")
    // previousData = JSON.parse(previousData)
    // let userid=this.props.route.params.id
    // let data = previousData || {}
    // data[userid] = {messages:[]}
    // data[userid]={groupChannel: this.state.GroupChannel.url, messages:data[userid].messages.concat([{type: 'file', message:response}]) }
    // await setStorageData("messageList",JSON.stringify(data))
    // let ddd = await getStorageData("messageList")
    // let offmessage = this.state.offlineMessageList
    // offmessage.push({type:'file',message: response, isMessage: "offline"})
    // this.setState({offlineMessageList:offmessage})
    // console.log("datadatadatadata",ddd)
    // }
    // });

  }

  deleteHandler(message: any, index: any) {
    this.state.GroupChannel.deleteMessage(message, (response: any, error: any) => {
      if (error) {
        // Handle error.
        return;
      }
      this.setState({ deleteLoad: false, deleteModalVisibility: false });
      // this.state.MessageList.splice(index, 1)
      // this.setState({MessageList: this.state.MessageList})
      // The message is successfully deleted from the channel.
    });
  }

  //@ts-ignore
  onEmojiClick = (event, emojiObject) => {
    const ref = this.contentRef.current
    ref.focus()
    const start = this.state.newOwnMessage.substring(0, ref.selectionStart)
    const end = this.state.newOwnMessage.substring(ref.selectionStart)
    const content = start + emojiObject.emoji;
    this.setState({ newOwnMessage: content })
    ref.selectionEnd = start.length + emojiObject.emoji.length
  };

  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  beforeUpload(file: any) {
    console.log("filetype", file);
    const isJpgOrPng =
      file.type.slice(0, 5) == "image" ||
      file.type.slice(0, 5) == "video";

    let isLt2M = false;
    if (file.type.slice(0, 5) == "image") {
      isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error('Image must smaller than 5MB!');
      }
    } else if (file.type.slice(0, 5) == "video") {
      isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('Video must smaller than 10MB!');
      }
    }

    this.getBase64(file, (imageUrl: string) => {
      let ImageArrayList = [...this.state.chatImages];
      let VideoArray = [...this.state.videoArrayList];
      let AllMedia = [...this.state.AllMedia]
      if (isLt2M && (file.type.slice(0, 5) == "video" || file.type.slice(0, 5) == "image")) {

        AllMedia.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      }
      if (file.type.slice(0, 5) == "video" && isLt2M) {
        VideoArray.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      } else if (file.type.slice(0, 5) == "image" && isLt2M) {
        ImageArrayList.push({
          filePath: imageUrl,
          // filePath: "data:image/jpeg;base64," + info.file.originFileObj,
          uri: imageUrl,
          type: file.type,
          name: file.name,
          data: file,
        });
      } else {
        message.error("invalid file type!!!");
      }

      this.setState({
        chatImages: ImageArrayList,
        videoArrayList: VideoArray,
        AllMedia,
        loadingChatImg: false,
      });

      //  this.state.ImageArrayList.push
      // this.setState({ImageArrayList:'data:image/jpeg;base64,' + response.data})
      // console.log("arrays" + JSON.stringify(this.state.chatImages));
      // console.log("arrays" + JSON.stringify(this.state.videoArrayList));

      // this.setState({
      //   createPostImages: [...this.state.createPostImages, imageUrl],
      //   loading: false,
      // });
    });
    // if (!isJpgOrPng) {
    //   message.error("You can only upload JPG/PNG file!");
    // }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   message.error("Image must smaller than 2MB!");
    // }
    // return isJpgOrPng && isLt2M;
    return true;
  }

  handleImageChange = (info: any) => {
    // console.log("info--===>", info.file.originFileObj.type);
    if (info.file.originFileObj?.type?.slice(0, 5) == "video" || info.file.originFileObj?.type?.slice(0, 5) == "image") {
      this.setState({ popupSendFile: true })
      if (info.file.status === "done") {

      } else if (info.file.status === "error") {
        // console.log("FILE INFO", info, info.file);
        // message.error(`${info.file.name} file upload failed.`);
      } else {
        // message.error("failed");
      }
    }
  };

  onChangeValue = (e: any) => {
    // console.log("radio checked", e.target.value);
    this.setState({ radioValues: e.target.value });
  };

  async reportAccount(context: any) {

    const header = {
      "Content-Type": configJSON.APPLICATIONJSONContentType,
      token: await localStorage.getItem('token'),
    };

    const httpBody = {
      data: {
        attributes: {
          context: this.state.radioValues,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reportAccountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountEndpoint + `${context}/report`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getMainSites = (urls: any) => {
    this.getSites(urls).processSites()
      .then((sites: any) => {
        for (let i in sites) {
          let site = sites[i];
          let data = this.siteData(site);
          data.getMetaTags();
          data.cleanTags();
          this.generateCard(data.preview);
        }
      });
  }

  getSites = (urls: any) => {
    let state = { urls, websites: [] };
    return Object.assign(
      state,
      this.processSites(state),
      this.getSiteAsString(),
      this.getBaseUrlMeta()
    );
  };

  getBaseUrlMeta = () => ({
    getBaseUrlMeta: (url: any) => `${url.split("/")[0]}//${url.split("/")[2]}`
  });

  getSiteAsString = () => ({
    getSiteAsString: (previewUrl: any) =>
      new Promise((resolve, reject) => {
        const url = `https://script.google.com/macros/s/AKfycbwoHNb0E51Rw7E1C20w1AE-3xUAWoJkzERTapvTcZp-ozvElMA/exec?url=${previewUrl}`;
        axios
          .get(url)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => reject(err));
      })
  });

  processSites = (state: any) => ({
    processSites: () => {
      return new Promise((resolve, reject) => {
        const promises: any = [];
        const baseUrls: any = [];
        state.urls.forEach((url: any) => {
          const baseUrl = state.getBaseUrl(url);
          baseUrls.push(baseUrl);
          promises.push(state.getSiteAsString(url));
        });
        Promise.all(promises).then(values => {
          const obj = values.reduce((arc: any, string: any, index: any) => {
            arc[index] = { string, url: baseUrls[index] };
            return arc;
          }, {});
          resolve((state.websites = obj));
        });
      });
    }
  });

  createDiv = (className = "null", id = "null") => {
    const div = document.createElement("div");
    div.className = className;
    div.id = id;
    return div;
  };

  loader = (visible: any) => {
    if (visible) {
      const target: any = document.getElementById("cards");

      target.innerHTML = "";
      const loaderParent: any = this.createDiv("lds-ripple");
      const loaderChild1: any = this.createDiv();
      loaderParent.append(loaderChild1);
      const loaderChild2 = this.createDiv();
      loaderParent.append(loaderChild2);
      target.append(loaderParent);
    } else if (visible === false) {
      const element: any = document.querySelector(".lds-ripple");
      element.parentNode.removeChild(element);
    }
  };

  generateCard = (data: any) => {
    const target = document.getElementById("cards");
    // target.innerHTML = "";

    const card = document.createElement("div");
    card.setAttribute("class", "card");

    const img = document.createElement("img");
    img.setAttribute("class", "cardImage");
    img.setAttribute("src", data.image);
    img.setAttribute(
      "onerror",
      "this.onerror=null;this.src='http://www.cams-it.com/wp-content/uploads/2015/05/default-placeholder-200x200.png';"
    );

    const title = document.createElement("div");
    title.setAttribute("class", "title");
    title.innerHTML = data.title || data["site_name"];
    const description = document.createElement("div");
    description.setAttribute("class", "description");
    description.innerHTML = data.description || "";

    img.addEventListener("load", () => {
      // loader(false);
      target?.appendChild(card);
      card.appendChild(img);
      card.appendChild(title);
      card.appendChild(description);
    });
  };

  getTitleTag = (state: any) => ({
    getTitleTag: () => {
      const title = /<title>(.*?)<\/title>/gi;
      const result = state.site.string;
      const titleText = result.match(title);
      if (titleText) {
        return titleText[0].replace("<title>", "").replace("</title>", "");
      }
      return "";
    }
  });

  getBaseUrl = (state: any) => ({
    getBaseUrl: () => `${state.url.split("/")[0]}//${state.url.split("/")[2]}`
  });

  getAlternativeImageFromWebsite = (state: any) => ({
    getAlternativeImageFromWebsite: () => {
      let imageUrls = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|ico)/gi;
      let images = state.site.string.match(imageUrls);
      // Check for image urls such as png.
      if (images === null) {
        imageUrls = /([/|.|\w|\s|-])*\.(?:jpg|gif|png|ico)/gi;
        images = state.site.string.match(imageUrls);
      }
      let logos = [];
      if (images) {
        logos = images.filter((image: any) => {
          return (
            image.indexOf("logo") >= 0 ||
            image.indexOf("icon") >= 0 ||
            image.indexOf("apple-touch") >= 0
          );
        });
        if (logos.length === 0) {
          logos = images.filter((image: any) => {
            return (
              // image.indexOf(".ico") >= 0 ||
              image.indexOf(".jpg") >= 0 || image.indexOf(".png") >= 0
            );
          });
        }
        if (logos.length) {
          if (logos[0].indexOf("http") >= 0) {
            return logos[0];
          }
          if (logos[0].substring(0, 2) === "//") {
            return `https:${logos[0]}`;
          }
          if (logos[0].indexOf("http") === -1) {
            return `${state.site.url}${logos[0]}`;
          }

          return logos[0];
        }

        // Look for icon tags help in links tags.
      }

      const title = /<link(.*?)>/gi;
      const result = state.site.string;
      const linkTags = result.match(title);
      const resultTags: any = [];
      linkTags.forEach((link: any) => {
        if (link.indexOf("icon") >= 0) {
          let imageUrls = /(http(s?):)([/|.|\w|\s|-])*/gi;
          let foundLink = link.match(imageUrls);
          if (foundLink) {
            resultTags.push(foundLink[0]);
          }
        }
      });
      if (resultTags.length) {
        return resultTags[0];
      }

      // finally get the fabicon.

      return undefined;
    }
  });

  getPossibleImageFromMetaTags = (state: any) => ({
    getPossibleImageFromMetaTags: (element: any) => {
      if (
        element.indexOf(".png") >= 0 ||
        element.indexOf(".jpg") >= 0 ||
        element.indexOf(".ico") >= 0
      ) {
        const url = `${state.url}${element.replace('content="', "").trim()}`;
        return url;
      }
    }
  });

  //Extract meta tahs
  getMetaTags = (state: any) => ({
    getMetaTags: () => {
      const urlRegex = /<meta(?: [^>]+)?>/gi;
      let metaTags = state.site.string.match(urlRegex);
      if (metaTags) {
        const tags = metaTags.map((tag: any) => {
          return tag
            .replace(/\/>/g, "")
            .replace(/[\n\r]/g, "")
            .replace(/<meta /, "");
        });
        return (state.tags = tags);
      }
      return (state.tags = []);
    }
  });

  getTagProperty = (data: any) => ({
    getTagProperty: (element: any) => {
      const tags = ["description", "title", "image", "site_name"];
      let propName = element
        .replace('property="og:', "")
        .replace("property='og:", "")
        .replace('name="', "")
        .replace("name='", "");
      if (tags.indexOf(propName) >= 0) return propName;
    }
  });

  cleanTags = (state: any) => ({
    cleanTags: () => {
      let preview: any = {};
      let prop: any;
      let content: any;
      let image = undefined;
      state.tags.forEach((tag: any, index: any) => {
        const contents = state.getTagContents(tag);
        if (contents) {
          contents.forEach((element: any) => {
            element.indexOf("content=") === -1
              ? (prop = state.getTagProperty(element))
              : (content = state.getTagContent(element));
            image = state.getPossibleImageFromMetaTags(element);
          });
          if (prop) preview[prop] = content;
        }
      });
      if (!preview.image) {
        preview.image = state.getAlternativeImageFromWebsite();
      }
      if (preview.title === undefined || preview.site_name === undefined) {
        preview.title = state.getTitleTag();
      }
      if (preview.title === undefined || preview.title === "") {
        preview.title = "Cool Website";
      }
      return (state.preview = preview);
    }
  });

  getTagContent = (data: any) => ({
    getTagContent: (element: any) =>
      element.replace('content="', "").replace("content='", "")
  });

  getTagContents = (state: any) => ({
    getTagContents: (tag: any) => {
      const tagNames = /(name=".+?(?="))|(property=".+?(?="))|(content=".+?(?="))|(name='.+?(?='))|(property='.+?(?='))|(content='.+?(?='))/g;
      return tag.match(tagNames);
    }
  });

  siteData = (site: any) => {
    let state = {
      site,
      tags: [],
      preview: {}
    };
    return Object.assign(
      state,
      this.getMetaTags(state),
      this.cleanTags(state),
      this.getTagContents(state),
      this.getTagProperty(state),
      this.getTagContent(state),
      this.getPossibleImageFromMetaTags(state),
      this.getAlternativeImageFromWebsite(state),
      this.getBaseUrl(state),
      this.getTitleTag(state)
    );
  };
  // Customizable Area End
}
