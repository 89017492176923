import React, { Component } from "react";
// Customizable Area Start
import {
  PageHeader,
  Card,
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Spin,
  DatePicker,
  Steps,
  Popover,
  Space,
  Select,
  Avatar,
  Upload,
  message,
  Modal
} from "antd";
const { Title, Text } = Typography;
import { logo, } from "../assets";
const style = require(`../../../social-media-account/src/login.module.css`);
import "../profile.css";
import CreateEducationController from "./addEducationController.web";
const { TextArea } = Input;
const { Step } = Steps;
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
// Customizable Area End
export default class CreateEducation extends CreateEducationController {
  // Customizable Area Start
  renderCloseOutlined = () => {
    return <CloseOutlined translate={{ }} rev={{}} />
  }
  isNumberKey = (evt: any) => {
    var charCode = (evt?.which) ? evt?.which : evt?.keyCode
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      this.setState({
        typeNumber: false
      })
      return false;
    }
    this.setState({
      typeNumber: true
    })
    return true;
  }

  renderCloseModal = () => {
    return (
      <Modal
        className="delete_popup"
        title="Personal Profile"
        visible={this.state.isModalVisible}
        onOk={() => {
          this.deleteData(this.state.modalIndex);
        }}
        footer={null}
        onCancel={() =>
          this.setState({
            isModalVisible: false
          })
        }
      >
        <p>
          Want to Delete {this.state.modalString} {this.state.modalIndex + 1} ?
        </p>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "0.5rem",
            paddingBottom: "1rem"
          }}
        >
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              className={style.btn_colors2}
              type="primary"
              shape="round"
              size={"large"}
              style={{ width: "200px" }}
              onClick={() => {
                this.setState({
                  isModalVisible: false
                });
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button
              className={style.btn_danger}
              type="primary"
              shape="round"
              size={"large"}
              danger
              style={{ width: "200px" }}
              onClick={() => {
                this.deleteData(this.state.modalIndex);
              }}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { all_data } = this.state;
    return (
      <div
        style={{
          backgroundColor: "#eff8fe",
          height: "100%"
        }}
      >
        {this.renderCloseModal()}
        {this.redirectUser()}
        <PageHeader
          className="site-page-header"
          title={
            <span style={{ color: "white" }}>
              <img style={{ height: "32px", marginRight: "8px" }} src={logo} />
              applied
            </span>
          }
          style={{
            backgroundImage: "linear-gradient(#005574, #7aacd6)",
            color: "white"
          }}
        />
        <Row
          style={{
            // minHeight: "90vh",
            alignItems: "center",
            padding: "60px 0",
            backgroundColor: "#eff8fe"
          }}
        >
          <Col span={7} xs={2} sm={2} md={3} lg={7} xl={7} xxl={7} />
          <Col span={10} xs={20} sm={20} md={18} lg={10} xl={10} xxl={10}>
            <Title level={2} style={{ textAlign: "center" }}>
              Setup Your Personal Profile
            </Title>
            <Steps
              current={this.state.employment_type == "Student" ? 1 : 2}
              style={{ padding: "15px" }}
            >
              <Step />
              <Step />
              <Step />
              <Step />
              {/* <Step /> */}
              {this.state.employment_type != "Student" && <Step />}
            </Steps>
          </Col>
          <Col span={7} xs={2} sm={2} md={3} lg={7} xl={7} xxl={7} />
          <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
          <Col
            span={12}
            xs={20}
            sm={20}
            md={18}
            lg={18}
            xl={12}
            xxl={12}
            style={{ height: "100%" }}
            className="basic_profile_card"
          >
            {/* <Row className="experince-card-heading">Post Graduation 1</Row> */}
            <Card className="card-body-certificate">
              {all_data.post && all_data.post.length > 0
                ? all_data.post.map((item: any, index: any) => {
                  return (
                    <div key={index}>
                      {/* <Row className="experince-card-heading"> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#e0eaf4",
                          minHeight: "35px"
                        }}
                      >
                        <Title
                          level={4}
                          style={{
                            marginLeft: "15px",
                            marginTop: "10px",
                            color: "steelblue",
                            height: "100%",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          Post Graduation {index + 1}
                        </Title>
                        <div
                          style={{
                            display: index === 0 ? "none" : "block",
                            cursor: "pointer",
                            padding: "1rem"
                          }}
                          onClick={() => this.showModal(index, "Post Graduation")}
                        >
                          <CloseOutlined 
                          translate={{}} rev={{}} />
                        </div>
                      </div>
                      <Form
                        className="form-with-padding"
                        initialValues={{
                          degree: null,
                          university: null,
                          year: null,
                          score: null
                        }}
                        onFinish={this.handlePostGraduation}
                        ref={this.post_formref}
                      >
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" }
                              ]}
                            // name="degree"
                            >
                              <Input
                                className={style.input_fields}
                                name="degree"
                                placeholder="Degree"
                                style={{ borderWidth: 1, color: "#a9bac8" }}
                                size="large"
                                value={item.degree}
                                onChange={e =>
                                  this.handleInputChange(e, index, "Post")
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" }
                              ]}
                              style={{ borderWidth: 1 }}
                            // name='university'
                            >
                              <Select
                                id={"select"}
                                style={{
                                  padding: "0 !important",
                                  borderRadius: "25px",
                                  border: '1px solid #a9bac8',
                                  color: "#a9bac8",
                                  overflow: "hidden"
                                }}
                                size="large"
                                placeholder="University"
                                bordered={true}
                                value={
                                  item.university ? item.university : null
                                }
                                dropdownStyle={{
                                  borderRadius: "15px",
                                  border: "1px solid #a9bac8",
                                  paddingTop: "0px",
                                  paddingBottom: "0px"
                                }}
                                notFoundContent={
                                  this.state.loading ? (
                                    <Spin size="small" />
                                  ) : (
                                    "No Data Found "
                                  )
                                }
                                onChange={e =>
                                  this.handleUniversity(e, index, "Post")
                                }
                              >
                                {this.state.category_data &&
                                  this.state.category_data?.map(
                                    (items: any, index: number) => {
                                      // console.log("items32232332====", items);
                                      return (
                                        <option
                                          style={{
                                            borderBottom: "1px solid #a9bac8",
                                            padding: "10px 20px 10px 20px"
                                          }}
                                          value={items.name}
                                          key={index}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              width: "100%"
                                              // paddingTop: "0.7rem",
                                            }}
                                          >
                                            <div style={{ color: "#a9bac8" }}>{items.name}</div>
                                          </div>
                                        </option>
                                      );
                                    }
                                  )}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Required field"
                                }
                              ]}
                              style={{ borderWidth: 1 }}
                            // name="year"
                            >
                              <Select
                                id={"select"}
                                style={{
                                  padding: "0 !important",
                                  borderRadius: "25px",
                                  border: '1px solid #a9bac8',
                                  color: "#a9bac8",
                                  overflow: "hidden"
                                }}
                                size="large"
                                placeholder="Year"
                                bordered={true}
                                dropdownStyle={{
                                  borderRadius: "15px",
                                  border: "1px solid #a9bac8",
                                  paddingTop: "0px",
                                  paddingBottom: "0px"
                                }}
                                value={item.year ? item.year : null}
                                onChange={e =>
                                  this.handleYearChange(e, index, "Post")
                                }
                              >
                                {this.state.yearList.map((item: number) => {
                                  return (
                                    <option
                                      style={{
                                        borderBottom: "1px solid #a9bac8",
                                        padding: "10px 20px 10px 20px"
                                      }}
                                      value={item}
                                      key={item}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%"
                                        }}
                                      >
                                        <div style={{ color: "#a9bac8" }}>{item}</div>
                                      </div>
                                    </option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" }
                              ]}
                            // name="score"
                            >
                              <Input
                                className={style.input_fields}
                                name="score"
                                placeholder="Score"
                                style={{ borderWidth: 1, color: "#a9bac8" }}
                                size="large"
                                onKeyPress={(event: any) => {
                                  this.isNumberKey(event)
                                }}
                                value={item.score ? item.score : ''}
                                onChange={(e: any) => {
                                  // if(e.target.value.trim().length>0){
                                  let pattern = /^\d+(\.)?(\d{1,2})?$/
                                  let result = e.target.value.trim().length === 0 ? true : pattern.test(e.target.value)
                                  console.log(e.target.value, result)
                                  //  console.log()

                                  // }
                                  if (result && this.state.typeNumber) {
                                    this.handleInputChange(e, index, "Post")

                                  }
                                  this.setState({
                                    typeNumber: true
                                  })
                                }
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Button
                              className={style.experience_add_btn}
                              type="primary"
                              htmlType="submit"
                              shape="round"
                              size={"large"}
                              style={{
                                display:
                                  index === all_data.post.length - 1
                                    ? "block"
                                    : "none",
                                width: "100%",
                                backgroundColor: "white",
                                borderColor: "#d9d9d9"
                              }}
                            >
                              Add Post Graduation
                            </Button>
                          </Col>
                          <Col
                            span={12}
                            xs={0}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          />
                        </Row>
                      </Form>
                    </div>
                  );
                })
                : ""}
              {all_data.graduation && all_data.graduation.length > 0
                ? all_data.graduation.map((item: any, index: any) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#e0eaf4",
                          minHeight: "35px"
                        }}
                      >
                        <Title
                          level={4}
                          style={{
                            marginLeft: "15px",
                            marginTop: "10px",
                            color: "steelblue",
                            height: "100%",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          Graduation {index + 1}
                        </Title>
                        <div
                          style={{
                            display: index === 0 ? "none" : "block",
                            cursor: "pointer",
                            padding: "1rem"
                          }}
                          onClick={() => this.showModal(index, "Graduation")}
                        >
                          {this.renderCloseOutlined()}
                        </div>
                      </div>
                      <Form
                        className="form-with-padding"
                        initialValues={{
                          degree: null,
                          university: null,
                          year: null,
                          score: null
                        }}
                        onFinish={this.handleGraduation}
                        ref={this.graduation_formref}
                      >
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" }
                              ]}
                            // name="degree"
                            >
                              <Input
                                className={style.input_fields}
                                name="degree"
                                placeholder="Degree"
                                style={{ borderWidth: 1, color: "#a9bac8" }}
                                size="large"
                                value={item.degree}
                                onChange={e =>
                                  this.handleInputChange(
                                    e,
                                    index,
                                    "Graduation"
                                  )
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" }
                              ]}
                              style={{ borderWidth: 1 }}
                            // name='university'
                            >
                              <Select
                                id={"select"}
                                style={{
                                  padding: "0 !important",
                                  borderRadius: "25px",
                                  border: '1px solid #a9bac8',
                                  color: "#a9bac8",
                                  overflow: "hidden"
                                }}
                                size="large"
                                placeholder="University"
                                bordered={true}
                                value={
                                  item.university ? item.university : null
                                }
                                dropdownStyle={{
                                  borderRadius: "15px",
                                  border: "1px solid #a9bac8",
                                  paddingTop: "0px",
                                  paddingBottom: "0px"
                                }}
                                notFoundContent={
                                  this.state.loading ? (
                                    <Spin size="small" />
                                  ) : (
                                    "No Data Found "
                                  )
                                }
                                onChange={e =>
                                  this.handleUniversity(
                                    e,
                                    index,
                                    "Graduation"
                                  )
                                }
                              >
                                {this.state.category_data &&
                                  this.state.category_data?.map(
                                    (items: any, index: number) => {
                                      // console.log("items32232332====", items);
                                      return (
                                        <option
                                          style={{
                                            borderBottom: "1px solid #a9bac8",
                                            padding: "10px 20px 10px 20px"
                                          }}
                                          value={items.name}
                                          key={index}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              width: "100%"
                                              // paddingTop: "0.7rem",
                                            }}
                                          >
                                            <div style={{ color: "#a9bac8" }}>{items.name}</div>
                                          </div>
                                        </option>
                                      );
                                    }
                                  )}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Required field"
                                }
                              ]}
                              style={{ borderWidth: 1 }}
                            // name="year"
                            >
                              <Select
                                id={"select"}
                                style={{
                                  padding: "0 !important",
                                  borderRadius: "25px",
                                  border: '1px solid #a9bac8',
                                  color: "#a9bac8",
                                  overflow: "hidden"
                                }}
                                size="large"
                                placeholder="Year"
                                bordered={true}
                                dropdownStyle={{
                                  borderRadius: "15px",
                                  border: "1px solid #a9bac8",
                                  paddingTop: "0px",
                                  paddingBottom: "0px"
                                }}
                                value={item.year ? item.year : null}
                                onChange={e =>
                                  this.handleYearChange(
                                    e,
                                    index,
                                    "Graduation"
                                  )
                                }

                              >
                                {this.state.yearList.map((item: number) => {
                                  return (
                                    <option
                                      style={{
                                        borderBottom: "1px solid #a9bac8",
                                        padding: "10px 20px 10px 20px"
                                      }}
                                      value={item}
                                      key={item}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%"
                                        }}
                                      >
                                        <div style={{ color: "#a9bac8" }}>{item}</div>
                                      </div>
                                    </option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" }
                              ]}
                            // name="score"
                            >
                              <Input
                                className={style.input_fields}
                                name="score"
                                placeholder="Score"
                                style={{ borderWidth: 1, color: "#a9bac8" }}
                                size="large"
                                value={item.score ? item.score : ''}
                                onKeyPress={(event: any) => {
                                  this.isNumberKey(event)
                                }}
                                onChange={e => {
                                  let pattern = /^\d+(\.)?(\d{1,2})?$/
                                  let result = e.target.value.trim().length === 0 ? true : pattern.test(e.target.value)
                                  if (result && this.state.typeNumber) {
                                    this.handleInputChange(
                                      e,
                                      index,
                                      "Graduation"
                                    )

                                  }
                                  this.setState({
                                    typeNumber: true
                                  })
                                }
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Button
                              className={style.experience_add_btn}
                              type="primary"
                              htmlType="submit"
                              shape="round"
                              size={"large"}
                              style={{
                                display:
                                  index === all_data.graduation.length - 1
                                    ? "block"
                                    : "none",
                                width: "100%",
                                backgroundColor: "white",
                                borderColor: "#d9d9d9"
                              }}
                            >
                              Add Graduation
                            </Button>
                          </Col>
                          <Col
                            span={12}
                            xs={0}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          />
                        </Row>
                      </Form>
                    </div>
                  );
                })
                : ""}

              {all_data.srSecondary && all_data.srSecondary.length > 0
                ? all_data.srSecondary.map((item: any, index: any) => {
                  return (
                    <div key={index}>
                      {/* <Row className="experince-card-heading"> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#e0eaf4",
                          minHeight: "35px"
                        }}
                      >
                        <Title
                          level={4}
                          style={{
                            marginLeft: "15px",
                            marginTop: "10px",
                            color: "steelblue",
                            height: "100%",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          Sr Secondary {index + 1}
                        </Title>
                        <div
                          style={{
                            display: index === 0 ? "none" : "block",
                            cursor: "pointer",
                            padding: "1rem"
                          }}
                          onClick={() => this.showModal(index, "Sr Secondary")}
                        >
                          <CloseOutlined 
                          translate={{}} rev={{}} />
                        </div>
                      </div>
                      <Form
                        className="form-with-padding"
                        initialValues={{
                          stream: null,
                          board: null,
                          year: null,
                          score: null
                        }}
                        onFinish={this.handleSrSecondary}
                        ref={this.secondary_formref}
                      >
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" }
                              ]}
                            // name="stream"
                            >
                              <Input
                                className={style.input_fields}
                                name="stream"
                                placeholder="Stream"
                                style={{ borderWidth: 1, color: "#a9bac8" }}
                                size="large"
                                value={item.stream}
                                onChange={e =>
                                  this.handleInputChange(
                                    e,
                                    index,
                                    "Secondary"
                                  )
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" }
                              ]}
                              style={{ borderWidth: 1 }}
                            // name='university'
                            >
                              <Select
                                id={"select"}
                                style={{
                                  padding: "0 !important",
                                  borderRadius: "25px",
                                  border: '1px solid #a9bac8',
                                  color: "#a9bac8",
                                  overflow: "hidden"
                                }}
                                size="large"
                                placeholder="Board"
                                bordered={true}
                                value={item.board ? item.board : null}
                                dropdownStyle={{
                                  borderRadius: "15px",
                                  border: "1px solid #a9bac8",
                                  paddingTop: "0px",
                                  paddingBottom: "0px"
                                }}
                                notFoundContent={
                                  this.state.loading ? (
                                    <Spin size="small" />
                                  ) : (
                                    "No Data Found "
                                  )
                                }
                                onChange={e =>
                                  this.handleUniversity(e, index, "Secondary")
                                }
                              >
                                {this.state.category_data &&
                                  this.state.category_data?.map(
                                    (items: any, index: number) => {
                                      // console.log("items32232332====", items);
                                      return (
                                        <option
                                          style={{
                                            borderBottom: "1px solid #a9bac8",
                                            padding: "10px 20px 10px 20px"
                                          }}
                                          value={items.name}
                                          key={index}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              width: "100%"
                                              // paddingTop: "0.7rem",
                                            }}
                                          >
                                            <div style={{ color: "#a9bac8" }}>{items.name}</div>
                                            {/* <div>
                                                <Checkbox
                                          name="checkbox"
                                          value={item.name}
                                          onChange={this.onChange}
                                        />
                                              </div> */}
                                          </div>
                                        </option>
                                      );
                                    }
                                  )}
                              </Select>
                            </Form.Item>
                            {/* <Form.Item
                                rules={[
                                  { required: true, message: 'Required field' },
                                ]}
                                // name="board"
                              >
                                <Input
                                  className={style.input_fields}
                                  name='board'
                                  placeholder='board'
                                  style={{ borderWidth: 1 }}
                                  value={item.board}
                                  onChange={(e) =>
                                    this.handleInputChange(
                                      e,
                                      index,
                                      'Secondary'
                                    )
                                  }
                                />
                              </Form.Item> */}
                          </Col>
                        </Row>
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Required field"
                                }
                              ]}
                              style={{ borderWidth: 1 }}
                            // name="year"
                            >
                              <Select
                                id={"select"}
                                style={{
                                  padding: "0 !important",
                                  borderRadius: "25px",
                                  border: '1px solid #a9bac8',
                                  color: "#a9bac8",
                                  overflow: "hidden"
                                }}
                                size="large"
                                placeholder="Year"
                                bordered={true}
                                dropdownStyle={{
                                  borderRadius: "15px",
                                  border: "1px solid #a9bac8",
                                  paddingTop: "0px",
                                  paddingBottom: "0px"
                                }}
                                value={item.year ? item.year : null}
                                onChange={e =>
                                  this.handleYearChange(
                                    e,
                                    index,
                                    "Secondary"
                                  )
                                }
                              >
                                {this.state.yearList.map((item: number) => {
                                  return (
                                    <option
                                      style={{
                                        borderBottom: "1px solid #a9bac8",
                                        padding: "10px 20px 10px 20px"
                                      }}
                                      value={item}
                                      key={item}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%"
                                        }}
                                      >
                                        <div style={{ color: "#a9bac8" }}>{item}</div>
                                      </div>
                                    </option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Form.Item
                              rules={[
                                { required: true, message: "Required field" }
                              ]}
                            // name="score"
                            >
                              <Input
                                className={style.input_fields}
                                name="score"
                                placeholder="Score"
                                style={{ borderWidth: 1, color: "#a9bac8" }}
                                size="large"
                                value={item.score ? item.score : ''}
                                onKeyPress={(event: any) => {
                                  this.isNumberKey(event)
                                }}
                                onChange={e => {

                                  let pattern = /^\d+(\.)?(\d{1,2})?$/
                                  let result = e.target.value.trim().length === 0 ? true : pattern.test(e.target.value)
                                  if (result && this.state.typeNumber) {
                                    this.handleInputChange(
                                      e,
                                      index,
                                      "Secondary"
                                    )

                                  }
                                  this.setState({
                                    typeNumber: true
                                  })
                                }
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={15}>
                          <Col
                            span={12}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          >
                            <Button
                              className={style.experience_add_btn}
                              type="primary"
                              htmlType="submit"
                              shape="round"
                              size={"large"}
                              style={{
                                display:
                                  index === all_data.srSecondary.length - 1
                                    ? "block"
                                    : "none",
                                width: "100%",
                                backgroundColor: "white",
                                borderColor: "#d9d9d9"
                              }}
                            >
                              Add Sr Secondary
                            </Button>
                          </Col>
                          <Col
                            span={12}
                            xs={0}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                          />
                        </Row>
                      </Form>
                    </div>
                  );
                })
                : ""}
            </Card>
            <Row style={{ background: "#f8f8f8" }}>
              <Col lg={12} xl={12} md={12} sm={12} xs={24}
                className={style.responsive_bottom_action_button} style={{ padding: "15px", }}>
                <Button
                  onClick={this.skipProfile}
                  className={style.skip_add_btn}
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  size={"large"}
                  style={{ width: "100%" }}
                >
                  Skip Profile Setup
                </Button>
              </Col>
              <Col
                lg={12} xl={12} md={12} sm={12} xs={24}
                className={style.responsive_bottom_action_button}
                style={{
                  padding: "15px 0px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end"
                }}
              >
                <Button
                  className={style.previous_btn}
                  type="primary"
                  shape="round"
                  onClick={() => this.handlePrevious()}
                  size={"large"}
                >
                  Previous
                </Button>
                <Button
                  className={style.btn_colors}
                  type="primary"
                  onClick={this.handleNext}
                  shape="round"
                  size={"large"}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={6} xs={2} sm={2} md={3} lg={3} xl={6} xxl={6} />
        </Row>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
