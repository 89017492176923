import React, { Component } from "react";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import confirm from "../../../alert/src/alert.web";
import AlertError from "../../../alert/src/alertError.web";
import { Redirect } from "react-router-dom";
import { Select, message } from "antd";
import { RouterProps } from "react-router";
// Customizable Area Start
import axios from "axios";
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';
// Customizable Area End
export const configJSON = require("../config");
const Option = Select;
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: any;
  token: string | null;
  visible: boolean;
  invitation_id: string;
  connection_type_id: string;
  connection_type: any[];
  all_data: any[];
  children: any[];
  profileUrl: string;
  profilePictureFile: any;
  changeImage: boolean;
  pendingConnectionList: any;
  acceptedConnectionList: any;
  suggestedList: any;
  acceptRequestmodal: any;
  redirect: boolean;
  pageLoader: boolean;
  businessConnection: any;
  personalConnection: any;
  netWorkDetailsData: any;
  relationinDropdown: any;
  pageNumber: number;
  nextPage: string;
  nextPageLoader: boolean;
  cardid: string;
  sendRequestLoadData: boolean;
  showLoadMoreVisible: boolean
}
interface SS {
  id: any;
}

export default class MyNetworkController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCategoryApiCallId: any;
  // pendingConnectionApiCallId: any;
  // acceptConnectionApiCallId: any;
  formref = React.createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CategoryAPIResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: true,
      isRegistration: true,
      token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
      data: this.props.history ? this.props.history.location.state : "",
      visible: false,
      invitation_id: "",
      connection_type_id: "",
      connection_type: [],
      all_data: [],
      children: [],
      profileUrl: "",
      profilePictureFile: {},
      changeImage: false,
      pendingConnectionList: [],
      acceptedConnectionList: [],
      suggestedList: [],
      acceptRequestmodal: { show: false },
      redirect: false,
      pageLoader: true,
      businessConnection: [],
      personalConnection: [],
      netWorkDetailsData: [],
      relationinDropdown: '',
      pageNumber: 1,
      nextPage: "",
      nextPageLoader: false,
      cardid: "",
      sendRequestLoadData: false,
      showLoadMoreVisible: true
    };
  }
  async componentDidMount() {
    let token = await localStorage.getItem("token");
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: false, token: token });
      // this.handlependingNetworkData();
      // this.handleAcceptedNetworkData();
      const location = window.location.href.split("/")
      const endPathName = location[location.length - 1]
      // console.log(endPathName, "window.location")
      if (endPathName === "myNetwork") {
        this.getMyNetworkData()
      } else {
        // await this.getNetworkDetails();
      }

      // this.getRelations();
    } else {
      this.setState({ redirect: true });
    }

    // this.handleConnectionCategory();
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.pageNumber !== prevState.pageNumber) {
      this.setState({ nextPageLoader: true })
      await this.getNetworkDetails();
    }
    // if (this.state.suggestedList !== prevState.suggestedList) {
    //   await this.getMyNetworkData();
    // }
  }
  // ============@@@ modal Fuction=============

  showAcceptRequestModal = async (data: any) => {
    let reqModalTitle, selectDrop: any = [], bodyLoad = false, connectedas = '';
    if (data.type == "accept") {
      let name = data?.data?.attributes?.role === "business" ? data?.data?.attributes?.profile?.data?.attributes?.company_name :
        data?.data?.attributes?.full_name
      // console.log(data);
      // reqModalTitle = data?.data?.attributes?.request_message;
      reqModalTitle = name + " wants to connect with you as" + " " + data?.data?.attributes?.sender_relation;
      connectedas = data?.data?.attributes?.connected_as;
      await this.getRelations("accept", data?.data.attributes.account_id)
    } else if (data.type == "send") {
      reqModalTitle = `Select Relationship with ${data?.data?.attributes?.role === "business" ? data?.data?.attributes?.profile?.data?.attributes?.company_name : data?.data?.attributes?.full_name}`;
      await this.getRelations("send", data?.data.id)
    } else if (data.type == "people") {
      reqModalTitle = `Select Relationship with ${data?.data?.attributes?.role === "business" ? data?.data?.attributes?.profile?.data?.attributes?.company_name : data?.data?.attributes?.full_name}`;
      await this.getRelations("send", data?.data.id)
    }
    this.setState({
      acceptRequestmodal: {
        ...this.state.acceptRequestmodal,
        show: true,
        title: reqModalTitle,
        // selectDrop: selectDrop.sort(),
        bodyLoad: bodyLoad,
        account_id: data?.data.id,
        connectedas: connectedas,
        type: data.type,
        data: data?.data
      },
      cardid: data.data.id
    });
  };

  closeAcceptRequestModal = () => {
    this.setState({ relationinDropdown: '', acceptRequestmodal: { show: false } });
  };

  showEstablishModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideEstablishModal = () => {
    this.setState({
      visible: false,
    });
  };

  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  }
  //=============@@@ Upload Profile Image==========

  getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  async beforeUpload(file: any) {
    console.log("file==>", file);
    const isJpgOrPng = (await file.type.slice(0, 5)) == "image";
    if (!isJpgOrPng) {
      message.error("You can only upload Image file!");
    } else {
      console.log("isJpgOrPng===>", isJpgOrPng);
      this.getBase64(file, (imageUrl: string) => {
        console.log("imageUrl===>", imageUrl);
        this.setState(
          {
            //   profilePictureFile: file,
            profileUrl: imageUrl,
            //   changeImage: true,
            loading: false,
          },
          () => { }
        );
        console.log("111111imageUrl===>", isJpgOrPng);
      });
    }
    return isJpgOrPng;
  }

  handleImageChange = (info: any) => {
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      // message.success("uploading");
      return;
    }
    if (info.file.status === "done") {
      console.log("originFileObj", info);
      message.success("uploaded");
      //   Get this url from response in real world.
      //   this.getBase64(info.file.originFileObj, (imageUrl: string) =>
      //     this.setState({
      //       profileUrl: imageUrl,
      //       loading: false,
      //     })
      //   );
    } else if (info.file.status === "error") {
      console.log("FILE INFO", info, info.file);
      message.error(`${info.file.name} file upload failed.`);
    } else {
      message.error("failed");
    }
  };

  onViewAllInvitations = () => {
    this.props.history.push({
      pathname: "/AllView/Invitation",
      // state: newData,
    });
  };
  onViewAllNetwork = () => {
    this.props.history.push({
      pathname: "/AllView/Network",
      // state: newData,
    });
  };
  onViewAllInvite = () => {
    this.props.history.push({
      pathname: "/AllView/InvitePeople",
      // state: newData,
    });
  };

  viewProfile = () => {
    this.props.history.push({
      pathname: "/MyBusiness/Profile",
      // state: newData,
    });
  };

  //===========@@@ receive Function=============================

  async receive(from: string, message: Message) {
    console.log("@@@ API Response ==========", message);
    // if (
    //   getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    //   this.pendingConnectionApiCallId !== null &&
    //   this.pendingConnectionApiCallId ===
    //   message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    // ) {
    //   // this.apiGetUserOtpCallId = null;
    //   var responseJson = message.getData(
    //     getName(MessageEnum.RestAPIResponceSuccessMessage)
    //   );
    //   console.log("get all pending data", responseJson);
    //   var errorReponse = message.getData(
    //     getName(MessageEnum.RestAPIResponceErrorMessage)
    //   );
    //   console.log("get all pending data", errorReponse);
    //   if (responseJson) {
    //     this.pendingConnectionSuccessCallBack(responseJson);
    //   } else {
    //     this.pendingConnectionFailureCallBack(responseJson);
    //   }
    //   this.parseApiCatchErrorResponse(errorReponse);
    // } else if (
    //   getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    //   this.acceptConnectionApiCallId !== null &&
    //   this.acceptConnectionApiCallId ===
    //   message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    // ) {
    //   console.log("accepted data")
    //   // this.apiGetUserOtpCallId = null;
    //   var responseJson = message.getData(
    //     getName(MessageEnum.RestAPIResponceSuccessMessage)
    //   );
    //   console.log("get all accept data", responseJson);
    //   var errorReponse = message.getData(
    //     getName(MessageEnum.RestAPIResponceErrorMessage)
    //   );
    //   console.log("get all accept data", errorReponse);
    //   if (responseJson) {
    //     this.acceptedConnectionSuccessCallBack(responseJson);
    //   } else {
    //     this.acceptedConnectionFailureCallBack(responseJson);
    //   }
    //   this.parseApiCatchErrorResponse(errorReponse);
    // }
  }

  // @@@@@@@@@ Failure Success And Failure Call Back ======================

  pendingConnectionSuccessCallBack = (responseJson: any) => {
    console.log(
      "@@@ Pending Connection Success Call Back============",
      responseJson
    );
    let pendingConnectionList = responseJson.data;

    this.setState({ pendingConnectionList: pendingConnectionList });
    console.log(
      "@@@ data pendingConnectionList ============",
      this.state.pendingConnectionList
    );
  };

  pendingConnectionFailureCallBack = (responseJson: any) => {
    console.log(
      "@@@ Pending Connection Failure call Back ========",
      responseJson
    );
  };

  // @@@@@@@@@ Accepted Success And Failure Call Back ======================

  acceptedConnectionSuccessCallBack = (responseJson: any) => {
    console.log(
      "@@@ Accepted Connection Success Call Back============",
      responseJson
    );
    let acceptedConnectionList = responseJson.data;

    this.setState({ acceptedConnectionList: acceptedConnectionList });
    console.log(
      "@@@ data accepted Connection List ============",
      this.state.acceptedConnectionList
    );
  };

  acceptedConnectionFailureCallBack = (responseJson: any) => {
    console.log(
      "@@@ Accepted Connection Failure call Back ========",
      responseJson
    );
  };

  //======== Get Api for Pending connection==================
  // handlependingNetworkData = () => {
  //   const header = {
  //     "Content-Type": "application/json",
  //     "token": this.state.token,
  //   };
  //   const httpBody = {
  //     "status": "pending",
  //     "search": "User",
  //     "search_for": "tag_friend",
  //     "page": "1",
  //     "per_page": "10"
  //   };;
  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );
  //   this.pendingConnectionApiCallId = requestMessage.messageId;

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     "bx_block_request_management/requests"
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     "GET"
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     JSON.stringify(httpBody)
  //   );
  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  //   return true;
  // };

  //======== Get Api for Accepted connection==================

  // handleAcceptedNetworkData = () => {
  //   const header = {
  //     "Content-Type": "application/json",
  //     token: this.state.token
  //   };
  //   const httpBody = {
  //     "status": "accepted",
  //     "search": "User",
  //     "search_for": "tag_friend",
  //     "page": "1",
  //     "per_page": "10"
  //   };
  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );
  //   this.acceptConnectionApiCallId = requestMessage.messageId;
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     "bx_block_request_management/requests"
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     header
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     "GET"
  //   );
  //   console.log(
  //     "@@@ accepted Request ===========",
  //     requestMessage,
  //     this.acceptConnectionApiCallId
  //   );
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     JSON.stringify(httpBody)
  //   );
  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  //   return true;
  // };

  changeRelation = (relation: any) => this.setState({ relationinDropdown: relation });

  getMyNetworkData = async () => {
    let pendingAxios, acceptedAxios, suggestedAxios
    let pendingAxiosRes = false, acceptedAxiosRes = false, suggestedAxiosRes = false
    const endpoint = []
    try {
      pendingAxios = await axios.get(`${baseURL}/bx_block_request_management/requests?status=pending&page=1&per_page=6`, {
        'headers': {
          'token': this.state.token,
          'Content-Type': 'application/json',
        }
      });
      if (pendingAxios?.data) {
        endpoint.push(pendingAxios)
        pendingAxiosRes = true
      }
    } catch (error) {

    }
    try {
      acceptedAxios = await axios.get(`${baseURL}/bx_block_request_management/requests?status=accepted&page=1&per_page=6`, {
        'headers': {
          'token': this.state.token,
          'Content-Type': 'application/json',
        }
      });
      console.log(acceptedAxios)
      if (acceptedAxios?.data) {
        endpoint.push(acceptedAxios)
        acceptedAxiosRes = true
      }

    } catch (error) {

    }
    try {
      suggestedAxios = await axios.get(`${baseURL}/bx_block_request_management/requests/suggestions?page=1&per_page=6`, {
        'headers': {
          'token': this.state.token,
          'Content-Type': 'application/json',
        }
      });
      if (suggestedAxios?.data) {
        endpoint.push(suggestedAxios)
        suggestedAxiosRes = true
      }
    } catch (error) {

    }

    axios.all(endpoint).then(axios.spread((...responses) => {
      let i = 0;
      const pendingReq = pendingAxiosRes && responses[i++]?.data,
        acceptedReq = acceptedAxiosRes && responses[i++]?.data,
        suggestedReq = suggestedAxiosRes && responses[i++]?.data.accounts;
      console.log(responses, suggestedReq);
      this.setState({
        pageLoader: false,
        pendingConnectionList: pendingReq?.data ? pendingReq.data : [],
        acceptedConnectionList: acceptedReq?.data ? acceptedReq.data : [],
        suggestedList: suggestedReq?.data ? suggestedReq.data : [],
      })
      // use/access the results 
      console.log(responses, suggestedReq);
    })).catch(errors => {
      console.log(errors)
    })
  }

  loadNetworkApi = (loadDataName: any) => {
    // console.log(loadDataName);
    let getDetailsAxios;
    if (loadDataName == "invitation") {
      // this.setState({ pendingConnectionList: [] });
      getDetailsAxios = axios.get(`${baseURL}/bx_block_request_management/requests?status=pending&page=1&per_page=6`, {
        'headers': {
          'token': this.state.token,
          'Content-Type': 'application/json',
        }
      });
    } else if (loadDataName == "network") {
      this.setState({ acceptedConnectionList: [] });
      getDetailsAxios = axios.get(`${baseURL}/bx_block_request_management/requests?status=accepted&page=1&per_page=6`, {
        'headers': {
          'token': this.state.token,
          'Content-Type': 'application/json',
        }
      });
    } else {
      getDetailsAxios = axios.get(`${baseURL}/bx_block_request_management/requests/suggestions?page=1&per_page=6`, {
        'headers': {
          'token': this.state.token,
          'Content-Type': 'application/json',
        }
      });
    }

    axios.all([getDetailsAxios]).then(axios.spread((...responses) => {
      const pendingReq = responses[0].data;

      if (loadDataName == "invitation") {
        this.setState({
          pendingConnectionList: pendingReq.data ? pendingReq.data : [],
          netWorkDetailsData: pendingReq.data ? pendingReq.data : []
        });
      } else if (loadDataName == "network") {
        this.setState({ acceptedConnectionList: pendingReq.data ? pendingReq.data : [] });
      } else {
        this.setState({ suggestedList: pendingReq.accounts.data ? pendingReq.accounts.data : [] });
      }

    })).catch(errors => {
      console.log(errors)
    })
  }

  getNetworkDetails = async () => {
    const pageName = this.props.history.location.pathname.split("/")[2];
    let getDetailsAxios;

    // console.log(pageName, pageName == "invitation", "pppp")
    if (pageName == "invitation") {
      getDetailsAxios = axios.get(`${baseURL}/bx_block_request_management/requests?status=pending&page=${this.state.pageNumber}&per_page=12`, {
        'headers': {
          'token': this.state.token,
          'Content-Type': 'application/json',
        }
      });
    } else if (pageName == "network") {
      getDetailsAxios = axios.get(`${baseURL}/bx_block_request_management/requests?status=accepted&page=${this.state.pageNumber}&per_page=12`, {
        'headers': {
          'token': this.state.token,
          'Content-Type': 'application/json',
        }
      });
    } else {
      getDetailsAxios = axios.get(`${baseURL}/bx_block_request_management/requests/suggestions?page=${this.state.pageNumber}&per_page=12`, {
        'headers': {
          'token': this.state.token,
          'Content-Type': 'application/json',
        }
      });
    }

    if (pageName == "you_may_know") {
      axios.all([getDetailsAxios]).then(axios.spread((...responses) => {
        console.log(responses[0].data.accounts.data, "skdjgalsdgjlasdgalkjsgd--2")
        this.setState({
          netWorkDetailsData: [...this.state.netWorkDetailsData, ...responses[0].data.accounts.data],
          pageLoader: false,
          nextPage: responses[0]?.data?.meta?.next_page,
          nextPageLoader: false
        })
        if (responses[0].data.data.length < 12) {
          this.setState({
            showLoadMoreVisible: false
          })
        }
      })).catch(errors => {
        console.log(errors)
      })
    } else {
      axios.all([getDetailsAxios]).then(axios.spread((...responses) => {
        // const pendingReq = responses[0].data;
        console.log(responses[0].data.data, "skdjgalsdgjlasdgalkjsgdl")
        if (responses[0].data.data.length < 12) {
          this.setState({
            showLoadMoreVisible: false
          })
        }
        this.setState({
          netWorkDetailsData:
            [...this.state.netWorkDetailsData, ...responses[0].data.data ? responses[0].data.data : responses[0].data.accounts.data],
          pageLoader: false,
          nextPage: responses[0]?.data?.meta?.next_page,
          nextPageLoader: false
        })
      })).catch(errors => {
        console.log(errors)
      })
    }

  }

  getRelations = async (type: any, account_id: any) => {
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/relations?account_id=${account_id}&action_type=${type}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        }
      })
      console.log(response)
      if (response && response?.data) {
        const acceptRequestmodal = { ...this.state.acceptRequestmodal };
        acceptRequestmodal.show = true;

        let selectDrop: any = [];

        response?.data.relations.sort().map((item: any, index: Number) => {
          selectDrop.push(
            <Option key={`id${account_id}${index}`}
              style={{
                borderBottom: "1px solid #7aacd6",
                padding: "10px 20px 10px 20px"
              }}
              value={item}
            >
              <div className="capital_options">{item}</div>
            </Option>
          )
        });
        acceptRequestmodal.selectDrop = selectDrop;
        acceptRequestmodal.bodyLoad = false;
        this.setState({ acceptRequestmodal: acceptRequestmodal });
        // this.acceptRequest(account_id, "accepted");
        // let dropDownDetail = response && response.data;
        // let checkBusinessConnc = dropDownDetail.data.filter((item: any) => item.attributes.name === "Business Connection");
        // let checkPersonalConnc = dropDownDetail.data.filter((item: any) => item.attributes.name === "Personal Connection");
        // this.setState({
        //   businessConnection: checkBusinessConnc[0].attributes.sub_categories,
        //   personalConnection: checkPersonalConnc[0].attributes.sub_categories,
        // });
      }
    } catch (error) {
      console.error(error);
    }
  }

  acceptRequest = async (dataObj: any) => {
    console.log(dataObj)
    console.log(this.state.relationinDropdown);
    const acceptRequestmodal = { ...this.state.acceptRequestmodal };
    const account_id = acceptRequestmodal?.account_id || '';
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests/${dataObj?.data?.id}`,
        method: 'put',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: {
          data: {
            "sender_relation": this.state.relationinDropdown,
            "status": dataObj.type
          }
        }

      })
      if (response && response?.data) {
        this.setState({ relationinDropdown: '', acceptRequestmodal: { ...acceptRequestmodal, show: false }, pendingConnectionList: [] });
        this.loadNetworkApi('invitation');
        this.loadNetworkApi('network');
      }
    } catch (error) {
      console.error(error);
    }
  }

  sendRequest = async () => {
    const acceptRequestmodal = { ...this.state.acceptRequestmodal };
    const account_id = acceptRequestmodal?.account_id || '';
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_request_management/requests`,
        method: 'post',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },
        data: {
          data: {
            "sender_relation": this.state.relationinDropdown,
            "account_id": account_id
          }
        }

      })
      if (response && response?.data) {
        this.setState({
          relationinDropdown: '',
          acceptRequestmodal: { show: false },
          // sendRequestLoadData: false
        });
        this.loadNetworkApi('suggestedList');
        this.getAccountData(response?.data?.data?.attributes?.account_id)
      }
    } catch (error) {
      console.error(error);
    }
  }

  getAccountData = async (acc_Id?: any) => {
    this.setState({ sendRequestLoadData: true })
    const acceptRequestmodal = { ...this.state.acceptRequestmodal };
    const account_id = acceptRequestmodal?.account_id || '';
    try {
      const response = await axios({
        url: `${baseURL}/account_block/accounts/${acc_Id}`,
        method: 'get',
        headers: {
          'token': this.state.token,
          'Content-Type': 'application/json'
        },

      })
      if (response && response?.data) {
        this.setState({
          sendRequestLoadData: false
        });
        this.loadNetworkApi('suggestedList');
        console.log(response?.data.data, "sdladfklagkad kladslka", this.state.netWorkDetailsData)
        const loadArray = this.state.netWorkDetailsData.map((item: any) => {
          if (item.attributes.account_id === response?.data?.data?.attributes?.account?.data?.attributes?.account_id) {
            return response?.data?.data?.attributes?.account?.data
          }
          return item
        })
        this.setState({
          netWorkDetailsData: loadArray
        })

        // this.getNetworkDetails();
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleCategory = (data: any) => {
    let all_data = [...this.state.all_data];
    const children = [...this.state.connection_type];
    data.map((data: any) => {
      if (data.attributes.name === "Business Connection") {
        all_data = data.attributes.sub_categories;
      }
    });
    console.log("Network conection=====", data);
    all_data.forEach((data: any, index) => {
      children.push(
        <Option value={data.name} key={index}>
          {data.name}
        </Option>
      );
    });
    this.setState({
      children,
    });
  };

  //==============@@@ Get Api for People You May Know==============

  handleConnectionCategory = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // this.getCategoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({
      connection_type_id: requestMessage.id,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
