import React from "react";
import MyPersonalProfileController, {
  Props,
} from "./MyPersonalProfileController.web";
import {
  imgPasswordInVisible,
  user,
  workStation,
  tag,
  postsImgsIcon,
  connectionsIcon,
  followersIcon,
  followingIcon,
  ratingsReceivedIcon,
  ratingSentIcon,
} from "./../assets";
import CommonListItem from "./../commonListItem.web";
import {
  EnvironmentOutlined,
  CloseOutlined,
  PaperClipOutlined,
  PictureOutlined,
  SendOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import "./../userFeed.css";
import moment from "moment";
import {
  Card,
  Row,
  Col,
  List,
  Input,
  Typography,
  Avatar,
  Button,
  Upload,
  Image,
  Badge,
  Radio,
  Tabs,
  Space,
  Modal,
  Drawer,
} from "antd";
import {
  SearchOutlined,
  LinkedinOutlined,
  WhatsAppOutlined,
  FacebookOutlined,
  MailOutlined,
} from "@ant-design/icons";
import CustomPost from "./../CustomPost.web";
import Picker from "emoji-picker-react";
import ReactPlayer from "react-player";
import { Dimensions, PixelRatio } from "react-native";
import { Divider } from "react-native-elements/dist/divider/Divider";
// import CustomHeader from "../CustomHeader.web";
import SliderView from "./SliderView.web";
import { ExclamationCircleOutlined } from "@ant-design/icons";
//
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Search } = Input;

let screenWidth = Dimensions.get("window").width;
let screenHeight = Dimensions.get("window").height;
//Artboard Dimension
let artBoardHeightOrg = 1024;
let artBoardWidthOrg = 1440;
//Re calculated Artboard Dimension
let artBoardWidth = isSameRatio() ? artBoardWidthOrg : screenWidth;
let artBoardHeight = isSameRatio() ? artBoardHeightOrg : screenHeight;
// To check if Artboard and Device screen has same ratio
function isSameRatio(): boolean {
  return (
    artBoardWidthOrg / artBoardHeightOrg < 1 && screenWidth / screenHeight < 1
  );
}

//Top or Bottom nav spaces or any extra space occupied by os e.g Status bar, Notch
let extraSpace = 0;


const addRatingList = [
  {
    id: 1,
    value: "Attitude",
    color: "green",
  },
  {
    id: 2,
    value: "Performance",
    color: "#b53f3e",
  },
  {
    id: 3,
    value: "Productivity",
    color: "#b53f3e",
  },
  {
    id: 4,
    value: "Leadership",
    color: "#87ceeb",
  },
  {
    id: 5,
    value: "Integrity",
    color: "#87ceeb",
  },
  {
    id: 6,
    value: "Expertise",
    color: "#87ceeb",
  },
  {
    id: 7,
    value: "Diversity",
    color: "#87ceeb",
  },
];

const addReviewList = [
  {
    id: 1,
    value: "Product Quality & Reliability",
    color: "green",
  },
  {
    id: 2,
    value: "Product Delivery & Commitment",
    color: "#b53f3e",
  },
  {
    id: 3,
    value: "Server Quality & Reliability",
    color: "#b53f3e",
  },
  {
    id: 4,
    value: "Service Devlivery & Reliability",
    color: "#87ceeb",
  },
  {
    id: 5,
    value: "Customer Support",
    color: "green",
  },
  {
    id: 6,
    value: "Helath,Safety & Environment",
    color: "#87ceeb",
  },
  {
    id: 7,
    value: "Ease of Doing Business",
    color: "#87ceeb",
  },
];
export default class RatingSent extends MyPersonalProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const { } = this.state;
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const { navigation } = this.props;
    return (
      <div className="background" style={{ backgroundColor: "#f6f6f6" }}>
        {/* {this.redirectUser()} */}
        {/* <CustomHeader {...this.props} dotVisible={false} /> */}
        <div style={{ paddingTop: "15px", display: "flex" }}>
          <p
            className="textlabel_subheadingText"
          >
            My Profile / Rating / Rating sent /
          </p>
          <p
            style={{
              color: "#5D8EC1",
              fontWeight: "bold",
            }}
          >
            Jason{" "}
          </p>
        </div>
        <div className="container" style={{ margin: "20px", width: "98%" }}>
          <p style={{ fontSize: "20px" }}>Jason Prices</p>
          <p style={{ fontSize: "14px" }}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi
            ipsum sint veritatis, facere assumenda nesciunt recusandae maxime
            odit molestiae obcaecati ad voluptatibus veniam, cum quia laudantium
            eligendi provident fugiat accusantium nulla. Impedit nihil aperiam
            deleniti autem culpa? Adipisci molestias perferendis velit veniam
            architecto quo. Laudantium, exercitationem maxime excepturi atque.
          </p>
        </div>
        <div
          className="container"
          style={{ margin: "20px", width: "98%", backgroundColor: "#f6f6f6" }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                margin: "20px",
                width: "500px",
                height: "600px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }}
              >
                <Text
                  style={{
                    color: "#414141",
                    fontWeight: 500,
                  }}
                >
                  Applied View Rating
                </Text>
                <Text
                  style={{
                    color: "#414141",
                    fontWeight: 500,
                  }}
                >
                  Applied Score
                </Text>
              </div>
              <div
                style={{
                  padding: "20px",
                  width: "500px",
                  height: "500px",
                  backgroundColor: "white",
                }}
              >
                <ModalAddAppliedViewRating open={true} data={addRatingList} />
              </div>
            </div>
            <div
              style={{
                margin: "20px",
                width: "500px",
                height: "600px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }}
              >
                <Text
                  style={{
                    color: "#414141",
                    fontWeight: 500,
                  }}
                >
                  Applied View Rating
                </Text>
                <Text
                  style={{
                    color: "#414141",
                    fontWeight: 500,
                  }}
                >
                  Applied Score
                </Text>
              </div>
              <div
                style={{
                  padding: "20px",
                  width: "500px",
                  height: "500px",
                  backgroundColor: "white",
                }}
              >
                <ModalAddAppliedViewRating open={true} data={addReviewList} />
              </div>
            </div>
          </div>
        </div>
        <Row style={{ backgroundColor: "#f6f6f6" }} />
      </div>
    );
  }
}
// Customizable Area Start

interface AddRatingAppliedViewProps {
  open?: boolean;
  data: any;
  // handleCancel: () => void;
  // handleSave: () => void;
}
class ModalAddAppliedViewRating extends React.Component<
  AddRatingAppliedViewProps
> {
  // document.body.style.overflow = 'hidden';
  render() {
    return (
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {this.props.data.map((item: any) => {
            return (
              <Space
                direction="vertical"
                // style={{ width: "100%", margin: "15px 0px" }}
                key={item.id}
              >
                <Text
                  style={{
                    fontSize: "11px",
                    color: "#414141",
                    fontWeight: 500,
                  }}
                >
                  {item.value}
                  <ExclamationCircleOutlined
                    style={{
                      marginLeft: "0.3rem",
                      cursor: "pointer",
                      color: "gray",
                    }}
                    translate={undefined}
                    rev={{}}
                  />
                </Text>
                <SliderView color={item.color} />
              </Space>
            );
          })}
        </div>
      </div>
    );
  }
}

// Customizable Area End
