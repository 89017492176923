import React, { Component } from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import confirm from "../../alert/src/alert.web";
import AlertError from "../../alert/src/alertError.web";
export const configJSON = require("./config");
import { Redirect } from "react-router-dom";
import { RouterProps } from "react-router";
import {
  removeStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
export interface Props extends RouterProps {
  navigation: any;
  id: string;
}
interface S {
  loading: boolean;
  isRegistration: boolean;
  bussiness_profile: boolean;
  data: {};
  button: string;
  countryPlaceholder: any | string;
  countryCode: any | string;
  isErrorModalShow: boolean;
  errorContent: any;
  redirect: boolean;
  landingPageImageId: string | null;
  landingPageImage: string;
}
interface SS {
  id: any;
}

export default class CreateAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountApiCallId: any;
  getLandingPageImageApiCallId = '';
  formref = React.createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
    this.state = {
      bussiness_profile: true,
      loading: false,
      isRegistration: true,
      data: {},
      button: "business",
      countryPlaceholder: "🇮🇳 | +91",
      countryCode: "+91",
      isErrorModalShow: false,
      errorContent: {},
      redirect: false,
      landingPageImageId: '',
      landingPageImage: ''
    };
  }
  async componentDidMount() {
    let token = await localStorage.getItem('token');
    if (token && token !== null && token !== undefined) {
      this.setState({ redirect: true })
    } else {
      this.setState({ redirect: false })
    }
    this.getLandingPageImage();
  }
  redirectUser() {
    if (this.state.redirect) {
      return <Redirect to='/home' />;
    } else {
      return <Redirect to='/signup' />;
    }
  };
  handleProfile = () => {
    this.setState({
      bussiness_profile: !this.state.bussiness_profile,
    });
  };
  changeButton = (data: string) => {
    // console.log(data, "DATA");
    this.setState({
      button: data,
    });
  };
  handleSignup = (data: object | any) => {
    console.log("sign up data", this.state.countryCode, data);
    setStorageData("signUpUser", data);
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
    };

    Object.assign(data, {
      full_phone_number: this.state.countryCode + data.full_phone_number,
      role_id: this.state.button === "business" ? 2 : 1,
    });
    const json_data = {
      data: data,
    };
    const httpBody = json_data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getLandingPageImage = () => {
    const header = {
      'Content-Type': 'application/json',
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLandingPageImageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandingPageImageAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.setState({
      landingPageImageId: requestMessage.id,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createAccountApiCallMethod = async (createAccountResponseData: any) => {
    this.setState({ loading: false });
    removeStorageData("isFromLogin");
    await setStorageData("isFromLogin", false);
    if (createAccountResponseData.email_otp) {
      console.log("email_otp", createAccountResponseData);
      setStorageData("signUpUserResponse", createAccountResponseData);
      this.props.history.push({
        pathname: "/email/varification",
        state: { responseJson: createAccountResponseData, isFromLogin: false },
      });
    } else if (createAccountResponseData.errors) {
      if (createAccountResponseData.errors[0].account) {
        this.setState({
          isErrorModalShow: true,
          errorContent: {
            title: "Error",
            isError: true,
            body: createAccountResponseData.errors[0].account,
          },
        });
      } else if (createAccountResponseData.errors[0].password) {
        this.setState({
          isErrorModalShow: true,
          errorContent: {
            title: "Error",
            isError: true,
            body: createAccountResponseData.errors[0].password,
          },
        });
      }
    } else if (createAccountResponseData && createAccountResponseData?.data && createAccountResponseData.data.type === "error" && createAccountResponseData?.data?.attributes) {
      if (createAccountResponseData?.data?.attributes?.errors && createAccountResponseData?.data?.attributes.errors?.email) {
        this.setState({
          isErrorModalShow: true,
          errorContent: {
            title: "Error",
            isError: true,
            body: "Invalid Email",
          },
        });
      } else if (createAccountResponseData?.data?.attributes.errors?.first_name) {
        console.log("createAccountResponseData?.data?.attributes.errors?.first_name", createAccountResponseData?.data?.attributes.errors?.first_name[0])
        this.setState({
          isErrorModalShow: true,
          errorContent: {
            title: "Error",
            isError: true,
            body: "Invalid Name",
          },
        });
      }
      else {
        this.setState({
          isErrorModalShow: true,
          errorContent: {
            title: "Error",
            isError: true,
            body: "something went wrong"
          },
        });
        console.log("something went wrong");
      }
      console.log(createAccountResponseData?.data?.attributes.errors?.email);
    } else {
      this.setState({
        isErrorModalShow: true,
        errorContent: {
          title: "Error",
          isError: true,
          body: "something went wrong"
        },
      });
      console.log("something went wrong");
    }
  };

  getLandingPageImageApiCallMethod = (landingPageImageResponseData: any) => {
    if (landingPageImageResponseData.data) {
      this.setState({
        landingPageImage: landingPageImageResponseData.data[0].attributes.image,
      });
    } else {
      this.setState({
        isErrorModalShow: true,
        errorContent: {
          title: "Error",
          isError: true,
          body: "Unable to load banner image!",
        },
      });
    }
  };

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId == this.createAccountApiCallId) {
          await this.createAccountApiCallMethod(responseJson);
        } else if (apiRequestCallId == this.getLandingPageImageApiCallId) {
          this.getLandingPageImageApiCallMethod(responseJson);
        }
      }
      else {
        // console.log("hello");
      }
    }
    if (message.id === getName(MessageEnum.CountryCodeMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
      if (apiRequestCallId.isValue) {
        this.setState({
          countryPlaceholder: `${apiRequestCallId.result.flag}   | +${apiRequestCallId.result.value
            }`,
          countryCode: `+${apiRequestCallId.result.value}`,
        });
      }
    }
  }
  handlePress = () => {
    console.log(this.props);
    // this.props.navigation.getParam({"data":444})
    let data = {};
    this.props.navigation.navigate("LoginWeb", {}, (data = { data: 444 }));
  };
}
