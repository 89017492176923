import React from 'react';
import { Modal, Form, Avatar, Upload, Input, Button, Row } from 'antd';
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { editCommunity, addCommunity, createProfile } from "../../CommunityForum/src/assets";
import CreateAndEditCommunityController from "./CreateAndEditCommunityController";

const { TextArea } = Input;


export default class CreateAndEditCommunity extends CreateAndEditCommunityController {
    
    renderCreateCommunity = () => this.props.isCreateCommunityVisible ? "Create Community" : null;

    renderEditCommunity = () => this.props.isEditCommunityVisible ? "Edit Community" : null;

    renderLoadingProfilePic = (loading: any) => {
        return loading.profile_pic ? <LoadingOutlined translate={{}} rev={{}} /> : createProfile
    };

    render() {
        const { loading, inputValue } = this.state
        const { isCreateCommunityVisible,
            isEditCommunityVisible,
            data,
            handleCancel,
            handleOk
        } = this.props;
        return (
            <Modal
                width={500}
                centered
                data-testid="modaltest"
                bodyStyle={{
                    maxHeight: "80vh",
                    borderRadius: "5px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    padding: "2px 24px 24px 24px"
                }}
                title={
                    <div
                        style={{
                            width: "100%",
                            cursor: "move",
                            fontWeight: "bold",
                            borderWidth: "0px"
                        }}
                    >
                        {this.renderCreateCommunity()}
                        {this.renderEditCommunity()}
                    </div>
                }
                footer={null}
                visible={isCreateCommunityVisible || isEditCommunityVisible}
                onOk={this.createEditOkModal}
                onCancel={this.createEditCancel}
            >
                <Form
                    className="community_form_style"
                    initialValues={{
                        profile_pic: isCreateCommunityVisible ? "" : data?.attributes?.profile_pic,
                        cover_pic: isCreateCommunityVisible ? "" : data?.attributes?.cover_pic,
                        name: isCreateCommunityVisible ? "" : data?.attributes?.name,
                        topics: isCreateCommunityVisible ? "" : data?.attributes?.topics,
                        desc: isCreateCommunityVisible ? "" : data?.attributes?.description
                    }}
                    ref={this.formRef}
                    onFinish={this.createAndEditCommunity}
                >
                    <Form.Item
                        rules={[{ required: true, message: "Required field" },]}
                        name="profile_pic"
                    >
                        <Upload
                            // name="file"
                            name="profile_pic"
                            showUploadList={false}
                            multiple={false}
                            data-testid="uploadprofilepicfile"
                            style={{ marginTop: 15 }}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            onChange={this.handleAvatarChange}
                            beforeUpload={this.beforeUpload}
                            disabled={loading.loadCommunity}
                        >
                            <div>
                                <Avatar
                                    size={70}
                                    src={inputValue.profile_pic ? inputValue.profile_pic : this.renderLoadingProfilePic(loading)}
                                />
                                <img
                                    src={isCreateCommunityVisible ? addCommunity : editCommunity}
                                    width={18}
                                    style={{
                                        position: "absolute",
                                        top: "51px",
                                        left: "49px",
                                        backgroundColor: "white",
                                        borderRadius: "100%"
                                    }}
                                />
                            </div>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: "Required field" }]}
                        name="cover_pic"
                    >
                        <Upload
                            style={{ marginBottom: 5, marginTop: "14px !important" }}
                            name="cover_pic"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            data-testid="uploadcoverpicfile"
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            onChange={this.handleCoverPicChange}
                            beforeUpload={this.beforeUpload}
                            disabled={loading.loadCommunity}
                        >
                            {inputValue.cover_pic ? (
                                <img
                                    src={inputValue.cover_pic}
                                    alt="avatar"
                                    className="cover_img"
                                    style={{ width: '100%', height: '100%', }}
                                />
                            ) : (
                                <Row style={{ alignItems: "center" }}>
                                    {loading.cover_pic ? (
                                        <LoadingOutlined translate={{}} rev={{}} />
                                    ) : (
                                        <PlusOutlined translate={{}} rev={{}} style={{ fontSize: "20px", color: "#a9bac8" }} />
                                    )}
                                    <div style={{ marginLeft: "10px", fontSize: "16px", color: "#a9bac8" }}>
                                        Add Cover Image
                                    </div>
                                </Row>
                            )}
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        rules={[{ required: true, message: "Required field" }]}
                        name="name"
                    >
                        <Input
                            autoComplete='off'
                            value={inputValue.name}
                            name="name"
                            type="text"
                            size="large"
                            onChange={(event) => {
                                this.setState({
                                    inputValue: { ...inputValue, name: event.target?.value }
                                })
                            }
                            }
                            disabled={loading.loadCommunity}
                            style={{ borderWidth: 1, marginTop: "15px", borderRadius: 20 }}
                            placeholder="Community Name"
                            data-testid="CommunityNameInput"
                        />
                    </Form.Item>

                    <Form.Item
                        rules={[{ required: true, message: "Required field" }]}
                        name="topics"
                    >
                        <Input
                            name="topics"
                            style={{ borderWidth: 1, marginTop: "15px", borderRadius: 20 }}
                            placeholder="Topic for Group Discussion"
                            onChange={(e) => {
                                this.setState({ inputValue: { ...inputValue, topics: e.target?.value } })
                            }
                            }
                            value={inputValue.topics}
                            type="text"
                            autoComplete='off'
                            size="large"
                            disabled={loading.loadCommunity}
                            data-testid="CommunityTopicInput"
                        />
                    </Form.Item>

                    <Form.Item
                        rules={[{ required: true, message: "Required field" }]}
                        name="desc"
                    >
                        <TextArea
                            className="Community_textArea"
                            name="desc"
                            value={inputValue.desc}
                            data-testid="CommunityDescInput"
                            disabled={loading.loadCommunity}
                            placeholder="About your Community"
                            onChange={(e) => {
                                this.setState({
                                    inputValue: { ...inputValue, desc: e.target?.value }
                                })
                            }}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            style={{ borderWidth: 1, borderRadius: "6px", fontSize: "16px", borderColor: "#a9bac8" }}
                        />
                    </Form.Item>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            className='btn_colors'
                            type="primary"
                            data-testid="submitbutton"
                            loading={loading.loadCommunity}
                            htmlType="submit"
                            shape="round"
                            size={"large"}
                            style={{ width: "100%", marginTop: "15px" }}
                        >
                            {isCreateCommunityVisible ? "Create Community" : "Save Community"}
                        </Button>
                    </div>
                </Form>
            </Modal >
        )
    }
}
