import React from "react";
// Customizable Area Start
import {
  Row,
  Col,
  Image,
  Form,
  Input,
  Typography,
  Button,
  Checkbox,
} from "antd";
import "antd/dist/antd.css";
import {
  bannerImage,
  iconImage,
  style,
  AppImage,
  AndroidImage,
  iosImage,
} from "./assets";
import { CheckCircleFilled } from "@ant-design/icons";
import OtpInput from "react-otp-input";
import SuccessController from "./SuccessController.web";
const { Title, Text, Link } = Typography;
// Customizable Area End

export default class VerificationSuccess extends SuccessController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        <Row>
          <Col span={14} xs={0} sm={12} md={12} lg={12} xl={14} xxl={14}>
            <Image
              preview={false}
              src={bannerImage}
              wrapperStyle={{ height: "100%", width: "100%" }}
              style={{ height: "100%", width: "100%" }}
            />
          </Col>
          <Col
            span={3}
            xs={24}
            sm={0}
            md={0}
            lg={0}
            xl={0}
            xxl={0}
            className={style.top_padding}
          />
          <Col
            span={10}
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={10}
            xxl={10}
            // className={style.top_padding}
            style={{ alignSelf: "center" }}
          >
            <Row>
              <Col span={4} />
              <Col span={16}>
                <Row className={style.loginText}>
                  <br />
                  <br />
                  <CheckCircleFilled translate={{}}
                    style={{
                      fontSize: "50px",
                      marginBottom: "16px",
                      color: "#52c41a",
                    }}
                    rev={{}}
                  />
                  <br />
                  <br />
                  <Title className={style.w_100_center} level={3} style={{ marginBottom: '0.4rem' }}>
                    Verification Success
                  </Title>
                </Row>
                {/* <br /> */}
                <Row className={style.loginText}>
                  <Text
                    type="secondary"
                    style={{ textAlign: "center", marginBottom: "16px", fontWeight: 500, }}
                  >
                    Your phone number and email address have been verified
                    successfully.
                  </Text>

                  <Button
                    className={style.btn_colors}
                    type="primary"
                    onClick={() => this.onContinueClick()}
                    shape="round"
                    size={"large"}
                    style={{ width: "100%" }}
                  >
                    Continue
                  </Button>
                </Row>
              </Col>
              <Col span={4} />
            </Row>
          </Col>
        </Row>
        <Row style={{ backgroundColor: "#f5f5f5" }}>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={14} xxl={14}>
            <Row
              className={style.app_alignCenter}
              style={{ justifyContent: "flex-end", width: "100%" }}
            >
              <Image
                preview={false}
                src={AppImage}
                style={{ objectFit: "fill" }}
              // wrapperStyle={{ width: "100%" }}
              />
            </Row>
          </Col>
          <Col
            span={12}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={10}
            xxl={10}
          // className={style.app_store}
          >
            <Row
              // className={style.app_alignCenter}
              style={{
                height: "100%",
                alignItems: "center",
                paddingRight: "40px",
                paddingLeft: "40px",
              }}
            >
              <Col span={6} xs={0} sm={6} md={3} lg={3} xl={3} xxl={6} />
              <Col span={10} xs={24} sm={24} md={18} lg={18} xl={18} xxl={10}>
                <Row>
                  <Title level={3}>Available for Android and IOS</Title>
                  <Text
                    type="secondary"
                    className={style.w_100}
                    style={{
                      fontSize: "1.1rem",
                      lineHeight: "1.3rem",
                      paddingBottom: "20px",
                    }}
                  >
                    Download applied app from your Play Store or App Store
                  </Text>
                  <Col span={11}>
                    <Image preview={false} src={AndroidImage} />
                  </Col>
                  <Col span={2} />
                  <Col span={11}>
                    <Image preview={false} src={iosImage} />
                  </Col>
                </Row>
              </Col>
              <Col span={8} xs={8} sm={8} md={3} lg={3} xl={3} xxl={8} />
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            justifyContent: "center",
            paddingTop: "10px",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          <p>©2021 applied. All rights reserved.</p>
          <a href="/privacy-policy">&nbsp;&nbsp;Privacy Policy&nbsp;&nbsp;</a>
          <a href="/terms-and-conditions">&nbsp;&nbsp;Terms of use</a>
        </Row>
      </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End